import { Injectable } from '@angular/core';
import { signal, computed } from '@angular/core';
import { ReportsService } from './reports.service'; 
import { GeoService } from './geo.service'; 
import { AmplitudeService } from './amplitude.service';

const OPEN_REPORT_STR = "Em Aberto";
const HAS_RISK_STR = "Apresenta Risco";
const TRIGGERINGS_STR = "Acionamentos"

@Injectable({ providedIn: 'root' })
export class FilterService {
  public filterStates = {
    filterHasRisk: signal(false),
    filterInProgress: signal(false),
    filterHasTriggerings: signal(false)
  };

  public filterActived = computed(() => {
    return Object.values(this.filterStates).some(filter => filter());
  });

  constructor(
    public reports: ReportsService,
    public geo: GeoService,
    private amplitude: AmplitudeService
  ) {
    reports.reportsUpdated.subscribe((updated) => {
      if (updated) {
        this.updateFilters();
      }
    });
  }

  getFilterState(filterKey: string): boolean {
    return this.filterStates[filterKey]();
  }

  translateAndMapFilterKey(filterKey: string) {
    let translatedKey: string;

    switch(filterKey) {
      case 'filterInProgress':
        translatedKey = OPEN_REPORT_STR;
        break;
      case 'filterHasRisk':
        translatedKey = HAS_RISK_STR;
        break;
      case 'filterHasTriggerings':
        translatedKey = TRIGGERINGS_STR;
        break;
      default:
        translatedKey = undefined;
    }

    return translatedKey;
  }

  updateFilterState(filterKey: string, state: boolean): void {
    if (this.filterStates.hasOwnProperty(filterKey)) {
      this.filterStates[filterKey].set(state);

      const translatedKey = this.translateAndMapFilterKey(filterKey);

      if(state){
        const eventProperties = {
          "Alteração": translatedKey,
          "Valor Alteração": state,
          "Configuração": {
            "Em Aberto": this.filterStates.filterInProgress(),
            "Apresenta Risco": this.filterStates.filterHasRisk(),
            "Acionamentos": this.filterStates.filterHasTriggerings()
          }
        }
        this.amplitude.sendEvent('Filtrou Relatórios', eventProperties);
      }
    }
  }

  
  async updateFilters() {
    await this.reports.updateReports();
    this.geo.drawFireReports(this.reports.reports_list);

    const filterHasRisk = this.getFilterState('filterHasRisk');
    const filterInProgress = this.getFilterState('filterInProgress');
    const filterHasTriggerings = this.getFilterState('filterHasTriggerings');
    const filteredReports = this.reports.filterReportsList.getValue().filter(report => {
      const matchesHasRisk = filterHasRisk ? !report.sem_risco : true;
      const matchesFinished = filterInProgress ? !report.datetime_finalizado : true;
      const matchesHasTriggerings = filterHasTriggerings ? report.acionamentos.length > 0 : true;
      return matchesHasRisk && matchesFinished && matchesHasTriggerings;
    });
    this.geo.drawFireReports(filteredReports);
  
  }
}
