import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA , MatDialogRef} from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";


@Component({
  selector: 'add-text-dialog',
  templateUrl: 'add-text-dialog.html',
})
export class addTextDialog implements OnInit {
  commentField: UntypedFormGroup;
  characterCount: number = 0;
  maxCharacters: number = 3000;

  constructor(
    public dialogRef: MatDialogRef<addTextDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder
  ) {
    this.commentField = this.fb.group({
      comentarios: [this.data.comentarios, [Validators.required, Validators.maxLength(this.maxCharacters)]]
    });

    this.commentField.get('comentarios').valueChanges.subscribe(value => {
      if (value.length > this.maxCharacters) {
        this.commentField.get('comentarios').setValue(value.substring(0, this.maxCharacters), { emitEvent: false });
        this.characterCount = this.maxCharacters;
      } else {
        this.characterCount = value.length;
      }
    });
  }

  ngOnInit(): void {
    this.characterCount = this.commentField.get('comentarios').value.length;
  }

  onSubmit() {
    if (this.commentField.valid) {
      const comentarios = this.commentField.value.comentarios;
      this.dialogRef.close(comentarios);
    }
  }
}


@Component({
  selector: 'select-perdas-dialog',
  templateUrl: 'select-perdas-dialog.html',
})
export class SelectPerdasDialog {

  tiposPerdas: string[];
  unidades: {name:string, unit: string}[];
  
  lossForm:UntypedFormGroup; 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: UntypedFormBuilder) { 

    this.tiposPerdas = data.tiposPerdas;
    this.unidades = data.unidades;

    this.lossForm = this.fb.group({
      type:['',[Validators.required]],
      unit:['',[Validators.required]],
      value:['',[Validators.required, Validators.min(0.01)]]
    })
  }

}


@Component({
  selector: 'finish-report-dialog',
  templateUrl: 'finish-report-dialog.html',
})
export class FinishReportDialog {
  
  r:any;
  error_msg:string;
  info_msgs:string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.r = data.report

    let last_combate = this.r.dados_combate[this.r.dados_combate.length-1] || null;
    let last_acionamento = this.r.dados_acionamento[this.r.dados_acionamento.length-1] || null;

    if (last_combate && !last_combate?.datetime_fim){
      this.error_msg = 'Favor registrar a hora de final de combate'
    }

    if (last_acionamento && !last_acionamento?.datetime_chegada){
      this.error_msg = 'Favor registrar a hora de chegada da equipe'
    }

    if(!this.r.dados_acionamento.length){
      this.info_msgs.push('Não há registro de acionamento de brigada')
    }

    if(!this.r.dados_combate.length){
      this.info_msgs.push('Não há registro de combate')
    }

    if(!this.r.dados_perdas.length){
      this.info_msgs.push('Não há registro perdas')
    }

  }
}

@Component({
  selector: 'lat-lon-dialog',
  templateUrl: 'lat-lon-dialog.html',
})
export class LatLonDialog {

  public latlngForm : UntypedFormGroup;
  public latlng = {
    lat:null,
    lng:null
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: UntypedFormBuilder,
  ) {

    this.latlngForm = this.fb.group({
      lat:['',[
        Validators.required,
        Validators.min(data.bounds.min_y_talhao),
        Validators.max(data.bounds.max_y_talhao)
      ]],
      lng:['',[
        Validators.required,
        Validators.min(data.bounds.min_x_talhao),
        Validators.max(data.bounds.max_x_talhao)
      ]],
    })
    console.log('LimitesPlanta', data.bounds);

  }
}

@Component({
  selector: 'propagation-dialog',
  templateUrl: 'propagation-dialog.html',
})
export class PropagationDialog {

  public lat:number;
  public lon:number;
  public wind_speed:number;
  public wind_deg: number;
  public t_sim_min: number;

  constructor() {}
}