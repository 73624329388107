import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from "socket.io-client"
import { environment } from 'src/environments/environment';
import { UserDataService } from './user-data.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})

export class SocketService {
  private socketStream: Socket;
  private socketSat: Socket;

  constructor(
    public user: UserDataService,
    public logging: LoggingService
  ) {

  }

  public socketDownValue = false;
  public reconnectAttempts = 0
  public maxReconnectAttempts = 10

  onFrame(camId: number): Observable<ArrayBuffer> {
    // stream pantanal ativado
    this.socketStream = io(environment.streamingApiUrl);
    return new Observable((observer) => {
      this.socketStream.on(`frame_${camId}`, (image) => {
        observer.next(image);
      });
    });
  }


  // SAT ATT SOCKET CENTRAL
  // CONNECTION socketSat
  joinIdPlantRoom() {
    this.reconnectAttempts = 0
    this.socketSat = io(environment.socketCentralUrl + '/sat_update', { 
      reconnection: true,
      reconnectionAttempts: this.maxReconnectAttempts,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 7000,
      auth: { token: this.user.getSocketCentralToken()},
      transports: ["websocket"]
    },);
    this.socketSat.on("send_room", (attempt) => {
      this.reconnectAttempts = 0;
      this.socketSat.emit('join', this.user.getIdPlanta() );
      this.logging.logINFO(`${this.socketSat.id}: Usuário conectado ao socket central.`);
    });
  }

  // HANDLERS socketSat
  onNewSatData() {
    return new Observable<Boolean>(observer => {
      this.socketSat.on('sat_update', msg => {
        this.logging.logINFO(`${this.socketSat.id}: Solicitação de atualização de dados satelital recebida.`);
        observer.next(msg);
      });
    });
  }

  onSatSocketDisconnect() {
    return new Observable<String>(observer => {
      this.socketSat.on('disconnect', msg => {
        observer.next(msg);
      });
    });
  }

  onSatSocketDown() {
    return new Observable<String>(observer => {
      this.socketSat.on('connect_error', (error: any) => {
        this.reconnectAttempts++;
        if (this.reconnectAttempts == this.maxReconnectAttempts) {
          this.logging.logERROR(`socket:onSatSocketDown ${error}`,error);
          observer.next('true');
        }
      });
    });
  }

  // EMITERS socketSat
  sendAttSatConfirmation() {
    this.socketSat.emit('sat_att_confirmed',  true );
    this.logging.logINFO(`${this.socketSat.id}: Atualização dados satelital realizada.`);
  }


  // EMITERS socketSat
  sendAtualizarDados() {
    this.socketSat.emit('atualizar_dados_planta',  this.user.getIdPlanta() );
    this.logging.logINFO(`${this.socketSat.id}: Atualizados dados do cliente.`);
  }
  
  disconnectFromSockCent() {
    try {
      this.logging.logINFO(`${this.socketSat.id}: Usuário se desconectou do socket central.`);
      this.socketSat.disconnect();
    } catch (error) {
      this.logging.logERROR(`socket:disconnectFromSockCent ${error}`,error);
    }
  }
}