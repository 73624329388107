import { Injectable, computed, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as L from 'leaflet';
import * as gf from "./geo.format";
import { GeoMarkersService, iconDirectory, tipoLocal2Icon } from './marker.service';

type GeoJSONOptions = {
  pointToLayer: (feature: any, latlng: any) => any;
  onEachFeature?: (feature: any, layer: any) => void;
};
export interface CamCoordsbj {
    camId: number,
    lat: number,
    lng: number
}

let toolTipOpts = {
  direction: 'bottom',
  offset: [0, 15],
  opacity: 0.9
}

@Injectable({
  providedIn: 'root'
})
export class GeoFactoryService {
  
  public selectedCamerasObjectsToControlFromMap = signal<CamCoordsbj[]>([])
  public selectedCamerasIdsToControlFromMap = computed(() => {
    let updatedArray = []
    this.selectedCamerasObjectsToControlFromMap().forEach((cameraObj: CamCoordsbj) => {
      updatedArray.push(cameraObj.camId)
    })
    return updatedArray
  })

  constructor(
    public geoMarkersService: GeoMarkersService
  ) {}

  createPlantaLocaisGeoJSON(locais_planta, all_instalacoes, filter_id_tipo_local=[2], isUgm=false, isOperatorP2=false, translate: TranslateService, fullInfoLayer=true) {
    let options: GeoJSONOptions = {
      pointToLayer: (feature, latlng) => {
        if (!filter_id_tipo_local.includes(feature.properties.id_tipo_local)) return;
  
        let marker = L.marker(latlng, { icon: this.getLocalIcon(feature.properties.id_tipo_local) });
  
        if ([2, 3].includes(feature.properties.id_tipo_local)) {
          var displayName;
          var tooltipClass;
          if (isUgm) {
            let solar = feature.properties.energia_concessionaria ? "" : " ☀️";
            displayName = feature.properties['nome_ugm'] + solar;
            tooltipClass = feature.properties.internet ? "marker-label internet" : "marker-label";
          } else {
            displayName = feature.properties['nome_local'];
            tooltipClass = "marker-label";
          }
          marker.bindTooltip(displayName, {
            permanent: true,
            className: tooltipClass,
            ...toolTipOpts
          });
        }
  
        if (fullInfoLayer && isUgm){
          let instalacoes = all_instalacoes.filter(i => i.id_local == feature.properties.id_local)[0] || {};
            marker.bindPopup(gf.formatUgmTowerInfo(feature, instalacoes, translate));
            gf.addActivatedPopup({
              tipo: 'Tower',
              marker: marker,
              feature: feature,
              instalacoes_object: instalacoes
            });
          } else if (!fullInfoLayer || !isOperatorP2) {
            marker.bindPopup(gf.formatClientTowerInfo(feature, translate));
          }
  
        this.geoMarkersService.addMarkerToList(marker)
        return marker;
      }
    };
    if (fullInfoLayer && isOperatorP2) {
      options.onEachFeature = (_, layer) => {
        layer.on('click', e => {
          if (this.selectedCamerasIdsToControlFromMap().includes(layer.feature.properties.id_camera)) {
            this.unselectTowerToControlFromMapRoutine(layer)
            return
          }
          this.selectTowerToControlFromMapRoutine(layer)
        });
      };
    }
  
    let geoJsonLayer = L.geoJSON(locais_planta, options);
  
    return geoJsonLayer;
  }
  
  
  createAllTowersLayer(allLocais){
    return L.geoJSON(allLocais, {
      pointToLayer: (feature, latlng) => {
        if (feature.properties.id_tipo_local != 2) return;

        let marker = L.marker(latlng, {icon: this.getLocalIcon(feature.properties.id_tipo_local)})
        marker.bindTooltip(feature.properties.nome_ugm, toolTipOpts);
        return marker;
      }
    });
  }

  getLocalIcon(id_tipo_local: any, iconSize:number = 32){
    let icon_type = tipoLocal2Icon[id_tipo_local]
  
    return L.icon({
      iconSize: [iconSize, iconSize],
      iconUrl: iconDirectory + icon_type
    });
  }

  addSelectedCamerasObjectsToControlFromMap(cameraObj: CamCoordsbj) {
    this.selectedCamerasObjectsToControlFromMap.update(value => [...value, cameraObj])
  }

  removeSelectedCameraObjectToControlFromMap(cameraObj: CamCoordsbj) {
    const filteredArray = this.selectedCamerasObjectsToControlFromMap().filter((camera) => {
      return camera.camId != cameraObj.camId
    })
    this.selectedCamerasObjectsToControlFromMap.set(filteredArray)
  }
  
  selectTowerToControlFromMapRoutine(layer: L.Marker) {
    this.addSelectedCamerasObjectsToControlFromMap({
      camId: layer.feature.properties.id_camera,
      lat: layer.feature.properties.lat,
      lng: layer.feature.properties.lon
    })
    this.geoMarkersService.highlightTowerMarker(layer);
  }

  unselectTowerToControlFromMapRoutine(layer: L.Marker) {
    const cameraId = layer.feature.properties.id_camera
    const cameraLat = layer.feature.properties.lat
    const cameraLng = layer.feature.properties.lng
    
    this.removeSelectedCameraObjectToControlFromMap({
      camId: cameraId,
      lat: cameraLat,
      lng: cameraLng
    })
    this.geoMarkersService.resetTowerMarkerToDefault(layer);
  }
}