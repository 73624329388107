@if (!loaded) {
  <app-loader></app-loader>
}

<main class="main-map">

@if (loaded) {
  <app-map-ctrl id='layers-btn'></app-map-ctrl>
}

  <div class="canvas-wrapper">
    <section class="map-container" [hidden]="!loaded">
      <div id="map">
        @if (geoService.waitingClickOnMapToSetFireLocation()) {
          <span class='click-on-map-tip'>{{ 'MAP.CLICK_ON_MAP_TO_GEOLOC' | translate }}</span>
        }
        @if (geo.clickedLatLng) {
          <div class="lat-lon-container">
            <div>
              lat:  {{geo.clickedLatLng.lat | number:'.5-5'}}
            </div>
            <div>
              lon:  {{geo.clickedLatLng.lng | number:'.5-5'}}
            </div>
        </div>
      }
    </div>

  </section>

  <div id="search-container">
    <button mat-mini-fab
      id="search-button"
      (click)="toggleSearchLocation($event)" (dblclick)="$event.stopPropagation()"
      matTooltip="{{'MAP.SEARCH_LOCATION' | translate}}" matTooltipPosition="above">
      <mat-icon class="search-icon">search</mat-icon>
    </button>
    @if(geo.showSearchBox()){
      <div class="talhao-search-box">
        <!-- os stopPropagation sao pra nao ativar o map onClick e o zoom com dblclick -->
        <form class="talhao-search-box-form" (click)="$event.stopPropagation()" (dblclick)="$event.stopPropagation()">
          <mat-form-field class="example-full-width">
            <mat-label>{{ 'MAP.SEARCH_PLACE' | translate }}</mat-label>
            <input type="text"
              placeholder="{{'MAP.SEARCH_PLACE_EXAMPLE' | translate}}"
              matInput
              [formControl]="buscaLocalFormControl"
              [matAutocomplete]="auto"
              (keydown)="onSearchLocationKeydown($event)">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="searchLocationDisplayName">
              @if (!geo.searchLocationIsCoordinate()){
                @for (talhao of filteredLocais | async; track talhao.nome_geom) {
                  <mat-option [value]="talhao">
                    {{talhao.nome_geom}}
                  </mat-option>
                }
              } @else {
                  <mat-option (click)="searchLatLon()">
                    {{ buscaLocalFormControl.value }}
                    <span class="go-to-coordinates">({{ 'MAP.GO_TO_COORDINATES' | translate }})</span>
                  </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
    }
  </div>


    @if (user.showPropagationWidget) {
      <app-propagation-widget style="width: 0;"></app-propagation-widget>
    }

    @if (loaded){
      @switch (panel){
        @case ("reports") {
          <app-fire-data class="report-panel" (reportTabSelected)="isReportTabSelected($event)"></app-fire-data>
        }
        @case ("cams") {
          <section class="cams-panel">
            <app-land-tabs></app-land-tabs>
          </section>
        }
        @case ("brigades") {
          <app-brigades class="resources-panel" ></app-brigades>
        }
        @case ("sat") {
          <app-satellite class="satellite-panel" ></app-satellite>
        }
        @case ("settings") {
          <app-settings class="report-panel" ></app-settings>
        }
        @case ("weather") {
          <app-weather class="report-panel" ></app-weather>
        }
        @case ("historical-data") {
          <app-historical-data class="historical-data-panel" ></app-historical-data>
        }
        @case ("deforestation") {
          <app-deforestation class="historical-data-panel" ></app-deforestation>
        }
        @case ("bio") {
          <app-bio class="report-panel" ></app-bio>
        }
      }
    }
    
    @if (loaded && !user.isExternal()) {
      <section class='map-sidenav fx-column-start-center' id='features-nav'>
        <div class="feature-panel-btn-wrapper">
          <button [ngClass]="panel !== 'reports' ? 'unselected-panel-btn' : 'selected-panel-btn'" mat-icon-button id='ocorrencias-btn' (click)='toggleShow("reports")' matTooltip="{{'REP.REPORTS' | translate}}">
            <mat-icon
              [matBadge]='reports.recentReportsAmount()'
              matBadgeColor="warn"
              [matBadgeHidden]='!reports.recentReportsAmount()'
              matBadgeSize="small"
            >
              local_fire_department
            </mat-icon>
          </button>
        </div>
        <div class="feature-panel-btn-wrapper">
          <button mat-icon-button
            id='cams-btn'
            [disabled]='!user.hasCamDetection()'
            [ngClass]="panel !== 'cams' ? 'unselected-panel-btn' : 'selected-panel-btn'"
            (click)='toggleShow("cams")'
            matTooltip="{{'MAP.CAMERAS' | translate}}" >
            <mat-icon>videocam</mat-icon>
          </button>
        </div>

        <div class="feature-panel-btn-wrapper">
          <button mat-icon-button
          id='satellites-btn'
          [disabled]='!user.hasSatellite()'
          [ngClass]="panel !== 'sat' ? 'unselected-panel-btn' : 'selected-panel-btn'"
          (click)='toggleShow("sat")'
          matTooltip="{{'SAT.MENU_TOOLTIP' | translate}}">
          <mat-icon
            [matBadge]='sat.clusterCount()'
            matBadgeColor="warn"
            [matBadgeHidden]='!user.hasSatellite() || !sat.clusterCount()'
            matBadgeSize="small"
            >satellite_alt</mat-icon>
          </button>
        </div>
        <div class="feature-panel-btn-wrapper">
          <button mat-icon-button
            id='brigades-btn'
            [disabled]='!user.hasFireManagement()'
            [ngClass]="panel !== 'brigades' ? 'unselected-panel-btn' : 'selected-panel-btn'"
            (click)='toggleShow("brigades")'
            matTooltip="{{'MAP.RESOURCES' | translate}}">
            <mat-icon>fire_truck</mat-icon>
          </button>
        </div>
        <div class="feature-panel-btn-wrapper">
          <button mat-icon-button
            id='weather-btn'
            [disabled]='!user.hasRiskMap()'
            [ngClass]="panel !== 'weather' ? 'unselected-panel-btn' : 'selected-panel-btn'"
            (click)='toggleShow("weather")'
            matTooltip="{{'MAP.WEATHER' | translate}}">
            <mat-icon>thermostat</mat-icon>
          </button>
        </div>
        <div class="feature-panel-btn-wrapper">
          <button mat-icon-button
            id='historical-data-btn'
            [ngClass]="panel !== 'historical-data' ? 'unselected-panel-btn' : 'selected-panel-btn'"
            [disabled]='!user.hasScars()'
            (click)='toggleShow("historical-data")'
            matTooltip="{{'MAP.HISTORICAL_DATA' | translate}}">
            <mat-icon>timeline</mat-icon>
          </button>
        </div>
        @if (user.hasDeforestation()){
          <div class="feature-panel-btn-wrapper">
            <button mat-icon-button
              id='deforestation-btn'
              [ngClass]="panel !== 'deforestation' ? 'unselected-panel-btn' : 'selected-panel-btn'"
              (click)='toggleShow("deforestation")'
              matTooltip="{{'DEF.DEFORESTATION' | translate}}">
              <mat-icon>forest</mat-icon>
            </button>
          </div>
        }
        @if (user.hasBio()) {
          <div class="feature-panel-btn-wrapper">
            <button mat-icon-button
              id='bio-btn'
              (click)='toggleShow("bio")'
              matTooltip="{{'MAP.BIODIVERSITY' | translate}}"
              [ngClass]="panel !== 'bio' ? 'unselected-panel-btn' : 'selected-panel-btn'"
            >
              <mat-icon>pets</mat-icon>
            </button>
          </div>
        }
        <div class="fx-column-end-center"
          style='margin-bottom: 10px; margin-top: auto;'>
          <button mat-icon-button
            matTooltipPosition = 'above'
            matTooltip="{{'MAP.MY_ACCOUNT' | translate}}"
            (click)='toggleShow("settings")'
            [ngClass]="panel !== 'settings' ? 'unselected-panel-btn' : 'selected-panel-btn'"
            >
            <mat-icon>account_circle</mat-icon>
          </button>
          
          <button mat-icon-button
              id='help-btn'
              [matMenuTriggerFor]="helpMenu"
              matTooltip="{{'INTERFACE.ONBOARDING.HELP' | translate}}" matTooltipPosition="above"
              class="unselected-panel-btn">
            <mat-icon>help</mat-icon>
          </button>
          <mat-menu #helpMenu="matMenu">
            <button mat-menu-item (click)="onboarding.startMapTour()">
              <mat-icon>explore</mat-icon>
              <span style="margin-left: .1vw; align-self: center;">Tour</span>
            </button>
            <button mat-menu-item (click)="goToSupport()">
              <mat-icon>build_circle</mat-icon>
              <span style="margin-left: .1vw; align-self: center;">{{ 'INTERFACE.ONBOARDING.SUPPORT' | translate }}</span>
            </button>
          </mat-menu>
        </div>
      </section>
    }
  </div>

</main>
