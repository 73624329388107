import { Injectable, Injector, signal } from '@angular/core';
import { Subject } from 'rxjs';

import { HttpService } from './http.service';
import { UserDataService } from './user-data.service';
import { SatelliteService } from './satellite.service';
import { GeoLayersService } from './geo.layers';
import { ScriptService } from './scripts.service';
import { ReportsService } from './reports.service';
import { AmplitudeService } from '../services/amplitude.service';
import { LoggingService } from './logging.service';
import { TranslateService } from '@ngx-translate/core';
import { TalhaoService } from './talhao.service';
import { CamCoordsbj, GeoFactoryService } from './geoFactory.service';
import { getCamAngularViewDeg, zoom2DistanceFov } from '../services/cam.store'

import * as L from 'leaflet';
import '../../assets/scripts/leaflet-side-by-side-modified';
import * as gf from "./geo.format";
import * as gleg from "./geo.legends";
import { createBaseLayersList, createMeteoLayerGroup, LayerObj, LayerGroupObj, leafletRuler } from "./geo.store";
import { createPresetPolygon } from "./cam.store";
import { PROPAGATION_COLORS } from './propagation.service';
import { brigadeIconMap } from './brigades.service';
import { bearing as turfBearing, destination, point, ellipse as turfEllipse, booleanPointInPolygon, explode, nearestPoint } from '@turf/turf';

import { environment } from '../../environments/environment';
import { BroadcastService } from '../interface/services/broadcast.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '../shared/dialogs/confirmation-dialog/confirmation-dialog';
import { InfoDialog } from '../shared/dialogs/info-dialog/info-dialog';

const geoServerBaseUrl = environment.geoserverUrl;

const CENTER_MAP_LAT = -15.77972;
const CENTER_MAP_LON = -47.92972;
const UGM_MAP_LAT = -11.69527;
const UGM_MAP_LON = -52.55859;
const TALHAO_PANE = 'talhao';

interface PropagationResponse {
  geojson: string,
  area_ha: number,
}

interface ICenterOnObjectOptions {
  setMapZoom?: boolean;
  mapZoom?: number;
  xOffsetPctg?: number;
}

interface IObjectCoordsToCenterOn {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  constructor(
    private http: HttpService,
    private layer: GeoLayersService,
    private sat: SatelliteService,
    private user: UserDataService,
    private script: ScriptService,
    private amplitude: AmplitudeService,
    public logging: LoggingService,
    private translate: TranslateService,
    private talhao: TalhaoService,
    private broadcastService: BroadcastService,
    private dialog: MatDialog,
    private reports: ReportsService,
    private geoFactoryService: GeoFactoryService
  ) { }

  public mapPinIcon = L.icon({
    iconUrl: `assets/icons/map-pin-red.png`,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  public fireLocationMarker = signal<L.Marker | null>(null)
  public waitingClickOnMapToSetFireLocation = signal<boolean>(false)
  public updatingLocationMarkerOnMap = signal<boolean>(false)
  public camerasLogsReplicationState = signal<any>(null)
  public detectionIdToSendSearchOnMapAmplitudeEvent = signal<any>(null)

  public searchedLatLonMarker = signal<L.Marker | null>(null)

  public map;

  public baseLayersList:LayerObj[] = [];
  public layerGroupList:LayerGroupObj[] = [];
  private baseMap;

  private fire_markers:any[] = [];
  private fireLayerGroup;
  private mapDrawings:any[] = [];
  
  public satLastUpdated = new Date();
  public updateSatActiveFireDataSubject = new Subject<void>();
  updateSatActiveFireData$ = this.updateSatActiveFireDataSubject.asObservable();

  private propLayerGroup;
  private searchedGeomLayerGroup;
  private autoGeolocEllipseLayerGroup;

  public clickedLatLng;

  public searchLocationIsCoordinate = signal<boolean>(false)

  private camerasList: any[];

  private talhaoZonaLegend;
  private talhaoLinhaEstradaLegend;
  private clusterTimelineLegend;
  private hotspotRoiLegend;
  private goesLegend;
  private firmsLegend;
  private eumetsatLegend;
  private historicalDataLegend;
  private fuelLegend;
  private riskLegend;
  private historicalRiskLegend;
  private TIlegend;
  private UClegend;
  private ppSectorLegend;
  private ppTypeLegend;
  private precipitationLegend;
  private deforestationLegendDeter;
  private deforestationLegendProdes;  
  private deforestationGfwLegend;

  public showSearchBox = signal(true); // aparece quando busca esta habilitada

  public activeRiskLayerList: Array<any> = [];
  public activeFuelLayerList: Array<any> = [];
  public activeHistoricalRiskLayerList: Array<any> = [];
  public activePrecipitationLayerList: Array<any> = [];

  private updateCamsDirectionInterval;

  public panteraReady = signal<boolean>(false)

  async createMap(id){
    console.log('🗺️ Creating map ...');
    this.resetMap();

    // CREATE
    this.map = L.map(id, {
      center: [CENTER_MAP_LAT, CENTER_MAP_LON],
      zoom: 6,
      minZoom:2,
      maxZoom: 17,
      attributionControl: false,
    });

    this.camerasList = this.user.getDadosPantera('cameras')

    // BOUNDS
    let bounds = await this.fitClientBounds()    

    this.addControls();
    this.addBaseOverlayMaps(bounds);

    //Map event listeners
    this.map.on('click', async (event) => await this.mapOnClick(event));
    
    // LAYER GROUPS
    this.propLayerGroup = new L.LayerGroup()
    this.propLayerGroup.addTo(this.map)

    this.searchedGeomLayerGroup = new L.LayerGroup()
    this.searchedGeomLayerGroup.addTo(this.map)

    this.autoGeolocEllipseLayerGroup = new L.LayerGroup()
    this.autoGeolocEllipseLayerGroup.addTo(this.map)

    //paineis para layer swap camadas das imagens de satélite de relatórios 
    this.map.createPane("left-report-sat-image");
    this.map.createPane("right-report-sat-image");

  }

  async resetMap(){
    if (this.map){
      this.map.off();
      this.map.remove();
      //reset legend
      this.activeRiskLayerList = [];
      this.activeFuelLayerList =[];
      this.activeHistoricalRiskLayerList = [];
      this.activePrecipitationLayerList=[]
      this.sat.imageSwiperControl = null;
    }
    return true
  }

  async fitClientBounds(){

    try{    
      let bounds = await this.http.centralGet('bbox_planta', [`${this.user.getIdPlanta()}`]);

      if (this.user.hasCamDetection()){
        this.map.fitBounds([
          [bounds.min_y_locais, bounds.min_x_locais],
          [bounds.max_y_locais, bounds.max_x_locais],
        ]);
      } else {
        this.map.fitBounds([
          [bounds.min_y_talhao, bounds.min_x_talhao],
          [bounds.max_y_talhao, bounds.max_x_talhao],
        ]);
      }

      if(this.user.is999()){
          this.map.setView(new L.LatLng(UGM_MAP_LAT, UGM_MAP_LON), 5);   
        }      
      return bounds

    } catch (error){
      this.logging.logERROR(`fitClientBounds ${error}`,error);
      console.log('Cannot fit client bounds:', error);
    }
  }
  
  async addBaseOverlayMaps(bounds){
    // Retrieve Data
    let all_instalacoes = await this.http.centralGet('get_instalacoes_planta', [`${this.user.getIdPlanta()}`]) || [];
    let isUgmOrExternal = this.user.isUgm() || this.user.isExternal();
    
    let locaisPlanta = this.user.getDadosPantera('locais');
    let stations = this.user.getDadosPantera('stations');
    let locaisMonitoramento = (locaisPlanta.features || []).filter((l) => l.properties.id_tipo_local == 2);
    
    // Create Layers
    this.baseLayersList = createBaseLayersList(this.user.isUgm());
    //ativa a primeira camada de base do array
    this.baseLayersList[0].active = true;
    this.baseLayersList[0].layer.addTo(this.map);
    
    this.layerGroupList = new Array();

    if (this.user.isUgm()){
      let ugmLayerGroup = await this.layer.createUgmLayerGroup();
      let humanInfraLayerGroup = this.layer.createInfraHumanaLayerGroup();
      let preservationLayerGroup = this.layer.createPreservationLayerGroup();
      this.addLayerGroup(ugmLayerGroup);
      this.addLayerGroup(humanInfraLayerGroup);
      this.addLayerGroup(preservationLayerGroup);
      
    }

    this.addLayerGroup(this.fireLayerGroup);

    if (this.user.isOperatorP2() || this.user.isSupportP2()){
      this.createTalhaoGeojsonPane(TALHAO_PANE)
    }
    let talhaoLayerGroup = await this.talhao.createTalhaoGroup(TALHAO_PANE);
    this.addLayerGroup(talhaoLayerGroup);

    if (this.user.hasTalhaoLinhaEstrada() || this.user.hasTalhaoLinhaHidrografia() || this.user.hasTalhaoLinhaEnergia()){
      let talhaoLinhaLayerGroup = await this.talhao.createTalhaoLineGroup();
      this.addLayerGroup(talhaoLinhaLayerGroup);
    }

    if (this.user.hasTerraIndigena() || this.user.hasUnidadeConservacao()){
      let protectedAreaOverlayGroup = await this.layer.createProtectedAreaLayerGroup();
      if (protectedAreaOverlayGroup) {
        this.addLayerGroup(protectedAreaOverlayGroup);
      }
    }

    let otherOverlayGroup = await this.layer.createOtherLayerGroup();
    this.addLayerGroup(otherOverlayGroup);
    
    let infraOverlayGroup = await this.layer.createInfraLayerGroup(locaisPlanta, all_instalacoes, stations, isUgmOrExternal, (this.user.isOperatorP2() || this.user.isSupportP2()));
    if(infraOverlayGroup){
      this.addLayerGroup(infraOverlayGroup);
      if (this.user.isOperatorP2() || this.user.isSupportP2()){
        try {
          clearInterval(this.updateCamsDirectionInterval);
        } catch {};
        this.updateCamsDirectionInterval = setInterval(() => {
          try {
            this.updateCamsDirection(infraOverlayGroup.layersList.find((layer) => layer.legend === 'MAP.MONITORING')?.layer);
          } catch {};
        }, 2000);
      }
    }

    if(this.user.hasSatellite()){

      await this.updateSatGroups();

      let orbitTrackingLayerGroup = await this.sat.createOrbitTrackingGroup();
      this.addLayerGroup(orbitTrackingLayerGroup);
      
      if(this.user.isUgm()){
        let hotspotWorldLayerGroup = await this.sat.createHotspotWorldGroup();
        this.addLayerGroup(hotspotWorldLayerGroup.overlayGroup);
      }
    }

    if(this.user.hasFireManagement()) {

      await this.updateFleetTrackingGroup();
      const TIMEOUT_MIN = 5;
      setInterval(async () => await this.updateFleetTrackingGroup(), TIMEOUT_MIN*60*1000);

      await this.updatePoiGroup();
    }    

    if (this.user.hasRiskMap()){
      let riskLayerGroup = await this.layer.createRiskLayerGroup()
      this.addLayerGroup(riskLayerGroup)
    }


    if(this.user.isDev()){
      let randomRiskLayerGroup = this.layer.createGwisRiskLayerGroup()
      this.addLayerGroup(randomRiskLayerGroup)
    }

    this.addLayerGroup(createMeteoLayerGroup())

    
    let meteoStationOverlay = await this.layer.createMeteoStationLayerGroup()
    if(meteoStationOverlay){
      this.addLayerGroup(meteoStationOverlay)
    }


    let cameraTrapOverlay = await this.layer.createCamTrapLayerGroup()
    if(cameraTrapOverlay){
      this.addLayerGroup(cameraTrapOverlay)
    }

    if (locaisMonitoramento.length){
      this.addLayerGroup(this.layer.createViewshedLayerGroup(locaisMonitoramento))
    }
    
    let bioOverlay = await this.layer.createBioOverlay()
    if(bioOverlay){
      this.addLayerGroup(bioOverlay)
    }

    let historicalHotspotsDataLayerGroup = await this.layer.createHistoricalHotspotsLayerGroup()
    if (historicalHotspotsDataLayerGroup) this.addLayerGroup(historicalHotspotsDataLayerGroup)

    this.createCicatrizesPane();
    let cicatrizesLayerGroup = await this.layer.createCicatrizesLayerGroup()
    if (cicatrizesLayerGroup) this.addLayerGroup(cicatrizesLayerGroup)

    let deforestationLayerGroup = await this.layer.createDeforestationOverlays()
    if (deforestationLayerGroup) this.addLayerGroup(deforestationLayerGroup)

    for (const layerGroup of this.layerGroupList) {
      for (const layerObj of layerGroup.layersList){
        if (layerObj.active){
          layerObj.layer.addTo(this.map)
        }
      }
    }
    this.setPanteraReady(true)
    
  }

  setPanteraReady(value: boolean) {
    this.panteraReady.set(value);
  }

  setShowSearchBox(value: boolean){
    this.showSearchBox.set(value)
    if (!value){
      this.setSearchLocationIsCoordinate(false)
    }
  }

  setSearchLocationIsCoordinate(value: boolean){
    this.searchLocationIsCoordinate.set(value)
  }

  async mapOnClick(event){
    this.clickedLatLng = event.latlng;
    this.setShowSearchBox(false);

    const mapClickEvent = new CustomEvent('mapClick', {
      detail: {
        lat: this.clickedLatLng.lat,
        lng: this.clickedLatLng.lng
      }
    });
    window.dispatchEvent(mapClickEvent);

    if (this.waitingClickOnMapToSetFireLocation()) {
      this.amplitude.sendEvent("Indicou Localização Mapa", { "ID Detecção": this.detectionIdToSendSearchOnMapAmplitudeEvent() })
      this.updateDetectionIdToSendSearchOnMapAmplitudeEvent(null);
      this.drawFireLocationMarkerOnMap(this.clickedLatLng.lat, this.clickedLatLng.lng, 2)

      if (this.updatingLocationMarkerOnMap()) {
        const dialogRef = this.dialog.open(ConfirmationDialog, {
          data: { text: this.translate.instant("MAP.CHANGE_REPORT_LOCATION") }
        })

        dialogRef.afterClosed().subscribe(async (confirmation) => {
          this.removeFireLocationMarkerFromMap()
          this.setUpdatingLocationMarkerOnMap(false)
          this.reports.restoreCursor();

          if (!confirmation) {
            this.updateWaitingClickOnMapToSetFireLocationStatus(false)
            return
          }
  
          let data = {
            'id_report':this.reports.selectedR.id_report,
            'columns':['dados_localizacao', 'id_planta', 'dados_meteo'],
            'values': [[{ lat: this.clickedLatLng.lat, lon: this.clickedLatLng.lng}], this.reports.selectedR['id_planta'], this.reports.selectedR['dados_meteo']],
          }

          this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Localização" })
          
          try {
            let response = await this.http.maestroPost('update_report_loc', data)
            this.reports.selectedR.dados_localizacao[0] = response.values[1][0]
            this.reports.selectedR.dados_meteo[0] = response.values[2][0]
          }
          catch (err) {
            this.dialog.open(InfoDialog, {
              data: { text: this.translate.instant("MAP.UNABLE_UPDATE") }
            })
          }
          finally {
            this.updateWaitingClickOnMapToSetFireLocationStatus(false)
            await this.updateDrawReports();
          }
        })
      }
    }

    if (this.user.isOperatorP2() || this.user.isSupportP2()){
      this.geoFactoryService.selectedCamerasObjectsToControlFromMap().forEach((cameraObj: CamCoordsbj) => {
        this.mapOnClickCamPan(this.clickedLatLng, cameraObj)
      })
    }

    this.searchedGeomLayerGroup.clearLayers();
  };

  mapOnClickCamPan(latlng, camObj: CamCoordsbj){
    const pan = this.camPanToPoint(latlng, camObj);
    this.broadcastService.publishMessage({ event: "MapOnClickCamPan", camId: camObj.camId, pan: pan })
  }

  camPanToPoint(clickedLatLng: any, camObj: CamCoordsbj) {
    var point1 = point([camObj.lng, camObj.lat]);
    var point2 = point([clickedLatLng.lng, clickedLatLng.lat]);
    var bearing = turfBearing(point1, point2);

    if (bearing < 0) {
      bearing = (bearing + 360) % 360
    }
    console.log(`cam ${camObj.camId} pan to ${bearing.toFixed(0)}`)
    return bearing;
  }

  async updateDrawReports(){
    await this.reports.updateReports();
    this.drawFireReports(this.reports.reports_list);
  }

  setUpdatingLocationMarkerOnMap(value: boolean) {
    this.updatingLocationMarkerOnMap.set(value)
  }

  showLabels(layerObject) {
    let visibleBounds = this.map.getBounds();
    layerObject.eachLayer((layer) => {
      let layerBounds = layer.getBounds();
      if (visibleBounds.intersects(layerBounds) && layer.feature.properties.area_ha >= 10) {
        layer.closeTooltip();
        layer.unbindTooltip();

        layer.bindTooltip(layer.feature.properties.nome_geom, {
          className: "talhao-label",
          permanent: true,
          direction: "center"
        }).openTooltip();
      } else {
        layer.closeTooltip();
        layer.unbindTooltip();
      }
    });
  };

  removeLabels(layerObject) {
    layerObject.eachLayer((layer) => {
      layer.closeTooltip();
      layer.unbindTooltip();
    });
  };

  async updateReportImageGroup(id_report) {
    if (this.sat.imageSwiperControl) {
      this.map.removeControl(this.sat.imageSwiperControl);
    }
    let reportImageLayerGroup = await this.sat.createReportImageGroup(id_report);
    this.updateLayerGroup(reportImageLayerGroup.overlayGroup, 24);
    console.log(`🛰️🖼️ Satellite imagery was updated!`);
  };
  
  async updateSatGroups() {
    await this.updateSatActiveFireGroup();
    await this.updateSatDetectionHistoryGroup();
    if(this.user.hasTalhaoRoi()){
      await this.updateSatHotspotRoiGroup();
    }
  };

  async updateSatActiveFireGroup(){
    let activeFireGroup = await this.sat.createActiveFireGroup(this.user.getIdPlanta());
    this.updateLayerGroup(activeFireGroup.overlayGroup, 4);
    this.updateSatActiveFireDataSubject.next();
    this.satLastUpdated = new Date();
    console.log('🛰️🔥 Active fire data was updated!');
  }

  async updateSatDetectionHistoryGroup() {
    let detectionHistoryGroup = await this.sat.createDetectionHistoryGroup();
    this.updateLayerGroup(detectionHistoryGroup.overlayGroup, 10);
    console.log('🛰️🔍 Detection history data was updated!');
  };

  async updateSatHotspotRoiGroup() {
    let hotspotRoiLayerGroup = await this.sat.createHotspotRoiGroup();
    this.updateLayerGroup(hotspotRoiLayerGroup.overlayGroup, 18);
    console.log('🛰️🔳 Hotspot ROI data was updated!');
  };

  async updateFleetTrackingGroup() {
    let fleetTrackingGroup = await this.layer.createFleetTrackingGroup();
    this.updateLayerGroup(fleetTrackingGroup, 8); 
    console.log('🚒 Fleet data was updated!');
  }  

  async updatePoiGroup() {
    let poiLayerGroup = await this.layer.createPoiLayerGroup();
    this.updateLayerGroup(poiLayerGroup, 11);
    console.log('📌 POI data was updated!');
  };

  updateLayerGroup(refreshedLayerGroup, groupId) {  
    // O grupo continua
    if (refreshedLayerGroup) {
      let layerStatusDict = {};

      for (const layerGroup of this.layerGroupList) {
        if (layerGroup.groupId === groupId) {
          for (const oldLayerObj of layerGroup.layersList) {
            for (const newLayerObj of refreshedLayerGroup.layersList) {  
              // A camada 'x' continua
              if (newLayerObj.legend === oldLayerObj.legend) {
  
                layerStatusDict[newLayerObj.legend] = oldLayerObj.active;
  
                if (oldLayerObj.active) {
                  this.map.removeLayer(oldLayerObj.layer);
                  this.map.addLayer(newLayerObj.layer);

                  // swiper das camadas do grupo Imagens (de relatórios)
                  if (groupId == 24) {
                    if (this.sat.imageSwiperControl) {
                      this.sat.imageSwiperControl.addTo(this.map);
                    }
                  }
                }
              }
            }
            // A camada 'x' não continua
            if (oldLayerObj.active) {
              this.map.removeLayer(oldLayerObj.layer);
            }
          }
        }
      }
      this.removeLayerGroup(groupId);
  
      if (Object.keys(layerStatusDict).length) {
        for (const newLayerObj of refreshedLayerGroup.layersList) {
          newLayerObj.active = layerStatusDict[newLayerObj.legend] || false;
        }
      }
      this.addLayerGroup(refreshedLayerGroup);
    }    
    // O grupo não continua
    else {
      for (const layerGroup of this.layerGroupList) {
        if (layerGroup.groupId === groupId) {
          for (const oldLayerObj of layerGroup.layersList) {
            if (oldLayerObj.active) {
              this.map.removeLayer(oldLayerObj.layer);
            }
          }
        }
      }
      this.removeLayerGroup(groupId);
    }
  }

  activateLayer(groupIndex, layerIndex) {
    this.map.removeLayer(this.layerGroupList[groupIndex].layersList[layerIndex].layer);
    this.layerGroupList[groupIndex].layersList[layerIndex].active = true
    this.map.addLayer(this.layerGroupList[groupIndex].layersList[layerIndex].layer);
  }

  async addControls(){

    L.Control.Watermark = L.Control.extend({
      onAdd: function(map) {
        let img = L.DomUtil.create('img');
        img.src = '../../assets/logos/pantera_head_negative.svg';
        img.style.width = '50px';
        return img;
      },
      onRemove: function(map) {}
    });
  
    L.control.watermark = function(opts) {
      return new L.Control.Watermark(opts);
    }
    
    let tagCtrl = L.control.attribution().setPrefix('<a>Pantera</a> | <a>Leaflet</a>');
    this.map.addControl(tagCtrl);

    this.addSearchControl()

    this.addMeasurementLeafletRulerControl()
    
    L.control.scale({imperial: false, maxWidth: 300}).addTo(this.map);
    L.control.watermark({ position: 'bottomleft' }).addTo(this.map);

    this.talhaoLinhaEstradaLegend = gleg.createTalhaoLinhaEstradaLegend();
    this.clusterTimelineLegend = gleg.createClusterTimelineLegend(this.translate);
    this.hotspotRoiLegend = gleg.createHotspotRoiLegend();
    this.goesLegend = gleg.createGoesLegend();
    this.firmsLegend = gleg.createFirmsLegend();
    this.eumetsatLegend = gleg.createEumetsatLegend();
    this.historicalDataLegend = gleg.createhistoricalDataLegend();
    this.fuelLegend = gleg.createFuelClassLegend();
    this.riskLegend = gleg.createRiskLegend();
    this.historicalRiskLegend = gleg.createHistoricalRiskLegend();
    this.TIlegend = gleg.createTILegend();
    this.UClegend = gleg.createUCLegend();
    this.ppSectorLegend = gleg.createPpSectorLegend();
    this.ppTypeLegend = gleg.createPpTypeLegend();
    this.precipitationLegend = gleg.createPrecipitationLegend();
    this.deforestationLegendDeter = gleg.createDeterLegend();
    this.deforestationLegendProdes = gleg.createProdesLegend();
    this.deforestationGfwLegend = gleg.createDeforestationGfwLegend();
    this.talhaoZonaLegend = gleg.createTalhaoZonaLegend(this.user.getTalhaoZonaLegend(), this.translate);
  }

  setLayer(layer, show=true){
    console.log('setLayer', layer);
    
    //Camadas
    if(show){
      this.map.addLayer(layer)
    } else {      
      this.map.removeLayer(layer)
    }
    
    //Controls (legends; swiper; etc)
    this.layerGroupList.forEach((lgroup) => {

      lgroup.layersList.forEach((candidateLayer)=>{
        if (candidateLayer.layer["_leaflet_id"] === layer["_leaflet_id"]){
          
          switch (candidateLayer.legend) {
            case "SAT.TIMELINE_LAYER":
              if(show){
                this.clusterTimelineLegend.addTo(this.map)
              } else {
                this.map.removeControl(this.clusterTimelineLegend)
              }
              break;            
            case "SAT.HOTSPOT_ROI_LAYER":
              if(show){
                this.hotspotRoiLegend.addTo(this.map)
              } else {
                this.map.removeControl(this.hotspotRoiLegend)
              }
              break;
            case "SAT.GOES_LAYER":
              if(show){
                this.goesLegend.addTo(this.map)
              } else {
                this.map.removeControl(this.goesLegend)
              }
              break;
            case "SAT.FIRMS_LAYER":
              if(show){
                this.firmsLegend.addTo(this.map)
              } else {
                this.map.removeControl(this.firmsLegend)
              }
              break;
            case "SAT.EUMETSAT_LAYER":
              if(show){
                this.eumetsatLegend.addTo(this.map)
              } else {
                this.map.removeControl(this.eumetsatLegend)
              }
              break;
            case "LAYERS.danger_d0":
            case "LAYERS.danger_d1":
            case "LAYERS.danger_d2":
            case "LAYERS.danger_d3":
            case "LAYERS.danger_d4":
            case "LAYERS.danger_d5":
            case "LAYERS.danger_d6":
              if (show) {
                this.activeRiskLayerList.push(candidateLayer);
              } else {
                const index = this.activeRiskLayerList.findIndex(
                  (activeLayer) => activeLayer.legend === candidateLayer.legend
                );
                if (index !== -1) {
                  this.activeRiskLayerList.splice(index, 1); // Remove the layer from the list
                }
              }
              break;
            case "LAYERS.heat":
              if (show){
                this.activeHistoricalRiskLayerList.push(candidateLayer)
              } else {
                const index = this.activeHistoricalRiskLayerList.findIndex(
                  (activelayer) => activelayer.legend === candidateLayer.legend
                );
                if (index !== -1){
                  this.activeHistoricalRiskLayerList.splice(index, 1);
                }
              }
              break;                                                                    
            case "DETER":
              if(show){
                this.deforestationLegendDeter.addTo(this.map)
              } else {
                this.map.removeControl(this.deforestationLegendDeter)
              }
              break;                       
            case "LAYERS.fuelclass":
              if(show){
                this.activeFuelLayerList.push(candidateLayer);
              }else{
                const index = this.activeFuelLayerList.findIndex(
                  (activelayer) =>activelayer.legend === candidateLayer.legend
                );
                if (index !== -1){
                  this.activeFuelLayerList.splice(index, 1);
                }
              }
              break;
            case "MAP.TI":
              if(show && this.translate.currentLang === 'pt-br'){
                this.TIlegend.addTo(this.map)
              } else {
                this.map.removeControl(this.TIlegend)
              }
              break;
            case "MAP.UC":
              if(show && this.translate.currentLang === 'pt-br'){
                this.UClegend.addTo(this.map)
              } else {
                this.map.removeControl(this.UClegend)
              }
            break;
            case "MAP.PRECIPITATION_NOW":
            case "MAP.PRECIPITATION_TOMORROW":
              if(show){
                this.activePrecipitationLayerList.push(candidateLayer);
              } else {
                const index = this.activePrecipitationLayerList.findIndex(
                  (activeLayer) => activeLayer.legend === candidateLayer.legend
                );
                if (index !== -1) {
                  this.activePrecipitationLayerList.splice(index, 1); // Remove the layer from the list
                }
              }
              break; 
            case "PRODES":
              if (show){
                this.deforestationLegendProdes.addTo(this.map)
              } else {
                this.map.removeControl(this.deforestationLegendProdes);
              };
            break;
            case "DEF.FAST_ALERTS":
              if (show){
                this.deforestationGfwLegend.addTo(this.map);
              } else {
                this.map.removeControl(this.deforestationGfwLegend);
              }
            break;
            case "MAP.ROAD":
              if (show){
                this.talhaoLinhaEstradaLegend.addTo(this.map)
              } else {
                this.map.removeControl(this.talhaoLinhaEstradaLegend);
              };
            break;
            case "Plantas - por setor":
              if(show){
                this.ppSectorLegend.addTo(this.map)
              } else {
                this.map.removeControl(this.ppSectorLegend)
              }
            break;
            case "Plantas - por contrato":
              if(show){
                this.ppTypeLegend.addTo(this.map)
              } else {
                this.map.removeControl(this.ppTypeLegend)
              }
              break;
            case "MAP.PROPERTY_ZONES":
              if(show){
                this.talhaoZonaLegend.addTo(this.map)
              } else {
                this.map.removeControl(this.talhaoZonaLegend)
              }
            break;
            case "SAT.REPORT_IMAGE_TRUE_COLOR_LAYER":
              if(show){
                if (this.sat.imageSwiperControl) {
                  this.sat.imageSwiperControl.addTo(this.map);
                }
              } else {
                if (this.sat.imageSwiperControl) {
                  this.map.removeControl(this.sat.imageSwiperControl);
                }
              }
            break;           
          }

          //Amplitude
          if (show) {
            if (this.translate.currentLang !== 'pt-br') {
              let currentLang = this.translate.currentLang;
              this.translate.use('pt-br');   
              this.amplitude.sendLayerStatusEvent('Ativou Camada', lgroup, candidateLayer);
              this.translate.use(currentLang);
            }
            else {
              this.amplitude.sendLayerStatusEvent('Ativou Camada', lgroup, candidateLayer);
            }
          } 
          else {
            if (this.translate.currentLang !== 'pt-br') {
              let currentLang = this.translate.currentLang;
              this.translate.use('pt-br');   
              this.amplitude.sendLayerStatusEvent('Desativou Camada', lgroup, candidateLayer);
              this.translate.use(currentLang);
            }
            else {
              this.amplitude.sendLayerStatusEvent('Desativou Camada', lgroup, candidateLayer);
            }
          };

        }
      })
    })

    // Check any RISK layer in the list and add the legend accordingly
    if (this.activeRiskLayerList.length > 0) {
      this.riskLegend.addTo(this.map);
    } else {
      this.map.removeControl(this.riskLegend);
    }

    // Check any FUEL layer in the list and add the legend accordingly
    if (this.activeFuelLayerList.length > 0) {
      this.fuelLegend.addTo(this.map);
    } else {
      this.map.removeControl(this.fuelLegend);
    }

    // Check any HEAT (HISTORICAL RISK) layer in the list and add the legend accordingly
    if (this.activeHistoricalRiskLayerList.length > 0) {
      this.historicalRiskLegend.addTo(this.map);
    } else {
      this.map.removeControl(this.historicalRiskLegend);
    }

    // Check any PRECIPITATION layer in the list and add the legend accordingly
    if (this.activePrecipitationLayerList.length > 0) {
      this.precipitationLegend.addTo(this.map);
    } else {
      this.map.removeControl(this.precipitationLegend);
    }

    // Cicatrizes dados históricos - TO DO clean code
    try{
      let hotspotsHistoricosGroupIndex = this.layerGroupList.findIndex(l => l.groupId == 9);    
      let hotspotsHistoricosLayerList = this.layerGroupList[hotspotsHistoricosGroupIndex]["layersList"];

      let cicatrizesGroupIndex: number;    
      let cicatrizesLayerList = [];

      let historicalDataLayerList;
      let historicalDataYears: string[];
      if (this.user.hasScars()){
        cicatrizesGroupIndex = this.layerGroupList.findIndex(l => l.groupId == 15);    
        cicatrizesLayerList = this.layerGroupList[cicatrizesGroupIndex]["layersList"];
        historicalDataLayerList = hotspotsHistoricosLayerList.concat(cicatrizesLayerList);
        historicalDataYears = ['2018', '2019', '2020', '2021', '2022', '2023', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022']
      } else {
        historicalDataLayerList = hotspotsHistoricosLayerList;
        historicalDataYears = ['2018', '2019', '2020', '2021', '2022'];
      };

      let range = (n:number) => [...Array(n).keys()]
      let indexes = range(historicalDataYears.length)
      let activeLayers = []
      historicalDataLayerList.forEach((element) => {
        activeLayers.push(element.active)
      })
      const historicalDataLayerEnabled = (index: number) => activeLayers[index];

      for (let year of historicalDataYears){
        let hotspotsHistoricosLayerIndex = hotspotsHistoricosLayerList.findIndex(l => l.legend == year);
        let cicatrizesLayerIndex = cicatrizesLayerList.findIndex(l => l.legend == year);
        if(show){
          if(layer["_leaflet_id"] === cicatrizesLayerList[cicatrizesLayerIndex]?.layer?._leaflet_id || layer["_leaflet_id"] === hotspotsHistoricosLayerList[hotspotsHistoricosLayerIndex]?.layer?._leaflet_id ) {
            this.historicalDataLegend.addTo(this.map)
          }
        } else{
          if(!indexes.some(historicalDataLayerEnabled)) {
          this.map.removeControl(this.historicalDataLegend)}    
        }
      }
    } catch(e){
      this.logging.logERROR(`hotspotsHistoricosLayerList ${e}`,e);
    }

  }

  updateLegendLanguage(language: string) {

    let satGroupIndex = this.layerGroupList.findIndex(l => l.groupId === 4);
    let clusterTimelineLayerIndex = this.layerGroupList[satGroupIndex].layersList.findIndex(l => l.legend === 'SAT.TIMELINE_LAYER');
    if (clusterTimelineLayerIndex !== -1) {
      let clusterTimelineLayer = this.layerGroupList[satGroupIndex].layersList[clusterTimelineLayerIndex];
      if (clusterTimelineLayer.active && this.clusterTimelineLegend && this.map) {
          this.map.removeControl(this.clusterTimelineLegend);
          this.clusterTimelineLegend = gleg.createClusterTimelineLegend(this.translate);
          this.clusterTimelineLegend.addTo(this.map);
      }
  } 

    if (this.riskLegend && this.map) {
      this.map.removeControl(this.riskLegend);
    }
    if (this.fuelLegend && this.map) {
      this.map.removeControl(this.fuelLegend);
    }
    if (this.historicalRiskLegend && this.map){
      this.map.removeControl(this.historicalRiskLegend)
    }
    this.riskLegend = language === 'en' ? gleg.createRiskLegendEnglish() : gleg.createRiskLegend();
    this.historicalRiskLegend = language === 'en' ? gleg.createHistoricalRiskLegendEnglish() : gleg.createHistoricalRiskLegend();
    //Remover a linha abaixo caso o trial da Maki Planet não avance
    if (this.user.getIdPlanta()===166){
      this.fuelLegend = gleg.createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&WIDTH=15&HEIGHT=15&LAYER=risk:166_fuelclass_0")
    }else{
      this.fuelLegend = language === 'en' ? gleg.createfuelClassLegendEnglish() : gleg.createFuelClassLegend();

    }

    if (this.activeRiskLayerList.length > 0) {
      this.riskLegend.addTo(this.map);
    }
    if (this.activeFuelLayerList.length > 0) {
      this.fuelLegend.addTo(this.map);
    }
    if (this.activeHistoricalRiskLayerList.length > 0) {
      this.historicalRiskLegend.addTo(this.map);
    }

    if (this.map && this.UClegend && language === 'en') {
      this.map.removeControl(this.UClegend);
      this.UClegend = gleg.createUCLegend();
    }

    if (this.map && this.TIlegend && language === 'en') {
      this.map.removeControl(this.TIlegend);
      this.TIlegend = gleg.createTILegend();
    }
    
  }

  setBaseMap(layer){
    this.map.addLayer(layer)
    try{
      this.map.removeLayer(this.baseMap)
    } catch(e) {
      this.logging.logERROR(`setBaseMap:removeLayer ${e}`,e);
    }
    this.baseMap = layer

    // desativa todas e depois ativa a nova camada no controle do mapa
    let newArr = this.baseLayersList.map(this.setInactive);
    for (let base of newArr) {
      if (base.layer == layer) {
        base.active = true
      }
    }
    this.baseLayersList = newArr; 
  }

  setInactive(layer) {
    layer.active = false
    return layer
  } 

  addLayerGroup(layerGroup){
    this.layerGroupList.push(layerGroup);
  }

  removeLayerGroup(groupId){
    this.layerGroupList = this.layerGroupList.filter(l => l?.groupId != groupId);
  }
  
  // Cameras

  updateCamsDirection(torresMonitoramentoLayer){
    const camsLogs = this.camerasLogsReplicationState()
    if (camsLogs) {
      const firstCamId = localStorage.getItem('MAIN_CAMERA_ID') || null;
      const secondCamId = localStorage.getItem('AUX_CAMERA_ID') || null;
  
      torresMonitoramentoLayer.eachLayer(layer => {
        const idCam = layer?.feature?.properties.id_camera;
        const camObj = this.camerasList.find(camera => camera.id_camera = idCam)
        const camLog = camsLogs.find(cam => cam.id_camera === idCam);
        if (camLog?.ptz?.pan){
          let color = 'black';
          if(camLog.id_camera == firstCamId) {
            color = 'red';
          } else if(camLog.id_camera == secondCamId){
            color = 'orange';
          };
          let distance = 30
          this.geoFactoryService.selectedCamerasObjectsToControlFromMap().forEach((cameraObj: CamCoordsbj) => {
            if (cameraObj.camId === idCam) {
              distance = 70
            }
          })
          this.drawFov(layer, camLog.ptz, color, camObj?.min_focal_distance_mm, camObj?.sensor_width_mm)
          this.drawCamDirection(layer, camLog.ptz.pan, color, distance);
        };
      });
    }
  }

  // ---------------------------- Draw FOV and cams direction ------------------------------

  drawFov(layer, camPTZ, color: string, minFocalDistance: number, sensorWidth: number) {
    const zoom = Math.max(1, camPTZ.zoom);
    const camAngularView = getCamAngularViewDeg(zoom, minFocalDistance, sensorWidth);
    const { start: distanceStart, end: distanceEnd } = zoom2DistanceFov(zoom, minFocalDistance, sensorWidth);
  
    this.drawFovTurf(layer, camPTZ.pan, camAngularView, color, distanceEnd, distanceStart);
  }
  
  drawFovTurf(layer, bearing, angle, color = 'white', distanceEnd = 15, distanceStart = 0, weight = 2, opacity = 0.7) {
    const origin = point([layer._latlng.lng, layer._latlng.lat]);
    const points = this.calculateFovPoints(origin, bearing, angle, distanceEnd, distanceStart);
  
    if (!layer.fov) {
      layer.fov = L.polygon(points, { color, weight, opacity });
      layer.fov.addTo(this.map);
    } else {
      layer.fov.setLatLngs(points);
      layer.fov.setStyle({ color, stroke: true, weight });
    }
  }
  
  calculateFovPoints(origin, bearing, angle, distanceEnd, distanceStart) {
    const destination1 = destination(origin, distanceEnd, bearing - angle / 2);
    const destination2 = destination(origin, distanceEnd, bearing + angle / 2);
  
    let fovPoints = [];
  
    if (distanceStart > 0) {
      const origin1 = destination(origin, distanceStart, bearing - angle / 2);
      const origin2 = destination(origin, distanceStart, bearing + angle / 2);
      fovPoints = [
        origin2.geometry.coordinates.reverse(),
        origin1.geometry.coordinates.reverse(),
      ];
    } else {
      fovPoints.push(origin.geometry.coordinates.reverse());
    }
  
    fovPoints.push(
      destination1.geometry.coordinates.reverse(),
      destination2.geometry.coordinates.reverse()
    );
  
    return fovPoints;
  }
  

  drawCamDirection(layer, ang = 0, color = 'black', distance = 30) {
    let origin = point([layer._latlng.lng, layer._latlng.lat]);
    let dest = destination(origin, distance, ang);
    let pts = [origin.geometry.coordinates.reverse(), dest.geometry.coordinates.reverse()];

    if (layer.baseLine){
      layer.baseLine.setLatLngs(pts);
      layer.centerLine.setLatLngs(pts);
      layer.centerLine.setStyle({color: color});
      return
    };
    layer.baseLine = L.polyline(pts, {color: 'white', weight: 5, opacity: 0.5});
    layer.centerLine = L.polyline(pts, {color: 'black', weight: 2});
    layer.baseLine.addTo(this.map);
    layer.centerLine.addTo(this.map);
  }
  
  // ---------------------  Presets -----------------------------------

  drawAllPresets(cam, presetsList){
    this.clearDrawings()
    for (const preset of presetsList) {
      this.drawPreset(cam, preset);
    }
  }

  drawPreset(cam, preset){
    let polygon = createPresetPolygon(cam, preset);
    polygon.addTo(this.map)
    this.mapDrawings.push(polygon)
  }
  
  clearDrawings(){
    this.mapDrawings.forEach(p => p.remove());
  }

  drawCircle(lat, lon, radius_m=15000, color='gray', opacity=0.3){
    let circle = L.circle([lat, lon], {
      color: color,
      fillColor: color,
      fillOpacity: opacity,
      radius: radius_m
    }).addTo(this.map);
    this.mapDrawings.push(circle)
  }

  // ------------------------------ Propagation -----------------------------------

  drawFirefront(propagationResponse: PropagationResponse, i: number){
    if (i === 0){
      this.propLayerGroup.clearLayers()
    }

    let propGeoJSON = L.geoJSON(propagationResponse.geojson, {
      onEachFeature: (feature, layer) => {
        let style = {
          "weight": 2,
          "opacity": 1,
          "color": PROPAGATION_COLORS[i],
          "fillOpacity": 0.3  
        };
        layer.setStyle(style)
        layer.bindPopup(gf.formatPropagationPopUp(propagationResponse.area_ha))
      }
    });

    this.propLayerGroup.addLayer(propGeoJSON)
    propGeoJSON.bringToBack()
  }
  
  clearFirefronts() {
    if (this.propLayerGroup) {
      this.propLayerGroup.clearLayers();  
    }
  }

  // ------------------------------ PopUp ------------------------------------
  closePopup() {
    this.map.closePopup();
  }
  
  openClusterPopUp(id, fromClusterParam=false){
    let layerList = Object.values(this.sat.clusterLayer._layers);
    let layer:L.Layer = layerList.find(c => c['unique_id'] == id)
    if (layer) {
      layer.openPopup();
      if (fromClusterParam) {
        this.centerOnObject({ lat: layer.feature.properties.latitude, lng: layer.feature.properties.longitude }, { setMapZoom: true, mapZoom: 12, xOffsetPctg: 0 })
      } else {
        this.centerOnObject({ lat: layer.feature.properties.latitude, lng: layer.feature.properties.longitude })
      }
    }    
  }

  //Hotspot - active fire
  openHotspotMarker(id_hotspot) {
    let marker = this.sat.hotspotMarkers.filter(m => m.feature.properties.id_hotspot == id_hotspot)[0]
    if (marker) {
      marker.openPopup();
      this.centerOnObject({ lat: marker._latlng.lat, lng: marker._latlng.lng })
    }
  }

  //Hotspot - histórico
  openDetectionHistoryPopup(id_hotspot){
    let marker = this.sat.hotspotTalhaoMarkers.filter(m => m.feature.properties.id_hotspot == id_hotspot)[0]
    if (marker) {
      marker.openPopup();
      this.centerOnObject({ lat: marker._latlng.lat, lng: marker._latlng.lng })
    }
  }

  openBrigadePopup(id_brigada){
    let marker = this.layer.brigadeMarkers.filter(m => m.feature.properties.id_brigada == id_brigada)[0];
    if (marker) {
      marker.openPopup();
      this.centerOnObject({ lat: marker._latlng.lat, lng: marker._latlng.lng })
    }
  }

  openPoiPopup(id_poi){
    let marker = this.layer.poiMarkers.filter(m => m.feature.properties.uuid_poi == id_poi)[0]
    if (marker) {
      marker.openPopup();
      this.centerOnObject({ lat: marker._latlng.lat, lng: marker._latlng.lng })
    }
  }

  openReportMarker(id_report) {
    let marker = this.fire_markers.filter(m => m.id_report == id_report)[0]
    if (marker) {
      marker.openPopup();
      this.centerOnObject({ lat: marker._latlng.lat, lng: marker._latlng.lng })
    }
  }

  drawFireReports(reports){
    this.fireLayerGroup = this.createReportLayerGroup(reports);
    try{
      this.map.removeLayer(this.fireLayerGroup.layersList[0].layer);
    } catch(e) {
      this.logging.logERROR(`drawFireReports:removeLayer ${e}`,e);
    }
    this.map.addLayer(this.fireLayerGroup.layersList[0].layer);
    this.removeLayerGroup(3);
    this.addLayerGroup(this.fireLayerGroup);
}

  createReportLayerGroup(reports):LayerGroupObj{
    this.fire_markers.forEach(x => x.remove());
    this.fire_markers = [];
    
    for (let r of reports) {
      let marker = this.createReportMarker(r)
      this.fire_markers.push(marker)
    }

    let reportsLayer = L.layerGroup(this.fire_markers);
  
    return {
      'groupId': 3,
      'groupTitle': 'MAP.REPORTS',
      'icon': 'local_fire_department',
      'layersList': [{
        'legend':'MAP.REPORTS',
        'layer': reportsLayer,
        'active': true
      }]
    }
  }

  createReportMarker(r){
    let icon = L.icon({
      iconUrl: './assets/icons/local_fire_department.svg',
      iconSize: [28,28],
      className: `${r.icon.color}-svg`,
    });
    let marker = L.marker(r.dados_localizacao[0], {
      icon: icon,
    });
    
    marker.bindTooltip(`${r.n_relatorio_planta}`, {
      permanent: false,
      direction: 'bottom',
      className: "marker-label",
      offset: [0, 23],
      opacity: 0.8
    });
    
    marker.bindPopup(gf.formatReportLocationInfo(r, this.translate));
    gf.addActivatedPopup({
      tipo: 'ReportLocationInfo',
      marker: marker,
      r: r
    });
    marker.id_report = r.id_report;

    marker.on('click', (e)=>{
      this.reports.selectReport(r.id_report);
      if (this.user.isUgm()) {
        if (this.reports.selectedR && this.reports.selectedR["id_report"] === r.id_report) {
          this.updateReportImageGroup(r.id_report);
          this.amplitude.sendEvent('Abriu Relatório', {'Origem': 'Mapa', 'Número Relatório': r.n_relatorio_planta, 'ID Relatório': r.id_report});
        }
      }
    })

    return marker
  }

  createCicatrizesPane(){
    this.map.createPane('cicatrizes');
    this.map.getPane('cicatrizes').style.zIndex = 650;
  }

  createTalhaoGeojsonPane(talhaoPane: string){
    this.map.createPane(talhaoPane);
    this.map.getPane(talhaoPane).style.zIndex = 100; // abaixo de camada limite municipios e abaixo visada cameras
  }

  getNomeTalhao(){
    return this.http.centralGet('get_nome_talhao', [`${this.user.getIdPlanta()}`])
  }

  createDeforestationAlertWmsLayer(idPlanta: number, idGeom: number, lat: number, lon: number, zoom: number){
    const deforestationAlertLayer = this.layer.createDeforestationAlertWmsLayer(idPlanta, idGeom, 'ugm', 'deforestation_alert', 0.9);
    this.map.addLayer(deforestationAlertLayer);
    this.map.flyTo([lat, lon], zoom);
  }

  addSearchedGeomWfsLayer(idGeom: number, idPlanta: number, workspaceName: string, layerName: string){
    let wfsUrl = `${geoServerBaseUrl}/geoserver/${workspaceName}/wfs`;
    let wfsParams = {
      service: 'WFS',
      request: 'GetFeature',
      typeName: `${workspaceName}:${layerName}`,
      outputFormat: 'application/json',
      viewparams: `id_planta:${idPlanta};id_geom:${idGeom}`,
    };
    const wfsLayer = L.geoJSON(null, {
      style: {
        color: '#FF0000',
        weight: 2
      },
      onEachFeature: function(feature, layer) {
        gf.formatSearchedGeomPopup(feature, layer)
      },
      pane: 'cicatrizes',
    });

    fetch(wfsUrl + L.Util.getParamString(wfsParams)).then(response => response.json()).then(data => {
      this.searchedGeomLayerGroup.clearLayers();
        wfsLayer.addData(data);
        this.searchedGeomLayerGroup.addLayer(wfsLayer);
        if (wfsLayer.getLayers().length > 0) {
          const firstLayer = wfsLayer.getLayers()[0];
          firstLayer.openPopup();
        }
        try {
          const latLng = wfsLayer.getBounds().getCenter();
          this.map.flyTo([latLng.lat, latLng.lng], this.map.getZoom());
        } catch (e) {
          this.logging.logERROR(`addSearchedGeomWfsLayer:flyTo ${e}`,e);
          console.warn(e);
        };
      });
  }

  async centerOnObject(
    objectCoordsToCenterOn: IObjectCoordsToCenterOn,
    options: ICenterOnObjectOptions = {}
  ) {

    // Set default values for options (in case they come undefined)
    const {
      setMapZoom = false,
      mapZoom = 12,
      xOffsetPctg = -0.25,
    } = options;

    if (setMapZoom && mapZoom != this.map.getZoom()) {
      this.map.setZoom(mapZoom);
      await new Promise(resolve => setTimeout(resolve, 750));
    }

    // Convert the marker's lat/lng to pixel coordinates
    const markerPoint = this.map.latLngToContainerPoint(objectCoordsToCenterOn);
    
    // Calculate the pixel offset based on the map size and desired percentage
    const mapSize = this.map.getSize();
    const offsetX = mapSize.x * xOffsetPctg;
  
    // Calculate the new longitude with the offset
    const newLng = this.map.containerPointToLatLng([markerPoint.x - offsetX, markerPoint.y]).lng;
  
    this.map.setView([objectCoordsToCenterOn.lat, newLng], this.map.getZoom());
  }

  addAcionamentoGeojsonLayer(acionamentoGeojson, idTipoBrigada: number){
    let acionamentoLayer = L.geoJSON(acionamentoGeojson, {
      pointToLayer: (feature, latlng) => {
        return L.marker (latlng, {
          icon: L.icon({
            iconSize: [32, 32],
            iconUrl: brigadeIconMap[idTipoBrigada]
          })
        }).bindTooltip(
          `<div>${feature?.properties?.nome_brigada}</div>
          <div>${new Date(feature?.properties?.last_updated).toLocaleDateString(undefined, {
            year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit'
          })}</div>`, {
            permanent: false,
            direction: 'bottom',
            offset: [0, 23],
            opacity: 0.8
          }
        )
      },
      style: {
        "color": "#ffd700",
        "weight": 3,
        "opacity": 0.8,
    }
    });
    acionamentoLayer.addTo(this.map);
    return acionamentoLayer;
  }

  removeLayer(layer) {
    this.map.removeLayer(layer);
  }

  updateWaitingClickOnMapToSetFireLocationStatus(newStatus: boolean) {
    this.waitingClickOnMapToSetFireLocation.set(newStatus)
  }

  updateFireLocationMarkerValue(marker: L.Marker | null) {
    this.fireLocationMarker.set(marker)
  }

  drawFireLocationMarkerOnMap(lat: number, lon: number, geolocTypeId: number) {
    this.removeFireLocationMarkerFromMap()
    const marker = L.marker([lat, lon], { icon: this.mapPinIcon })
    const dadosLoc = this.getDadosLoc(lat, lon, this.talhao.talhaoLayer)
    if (dadosLoc){
      marker.bindPopup(gf.formatLocationInfo(dadosLoc, this.translate))
    }
    gf.addActivatedPopup({
      tipo: 'LocationInfo',
      marker: marker,
      dadosLoc: dadosLoc,
    });
    this.updateFireLocationMarkerValue(marker)
    this.fireLocationMarker().addTo(this.map)
    if (dadosLoc){
      marker.openPopup()
    }
    this.broadcastService.publishMessage({ event: "FireLocationMarked", location: { lat, lon }, geolocTypeId: geolocTypeId })
  }

  removeFireLocationMarkerFromMap() {
    if (this.fireLocationMarker()) {
      this.map.removeLayer(this.fireLocationMarker())
      this.fireLocationMarker.set(null)
      this.broadcastService.publishMessage({ event: "FireLocationUnmarked" })
    }
  }

  removeSearchedLatLonMarkerFromMap() {
    if (this.searchedLatLonMarker()) {
      this.map.removeLayer(this.searchedLatLonMarker())
      this.searchedLatLonMarker.set(null)
    }
  }

  addSearchedLatLonMarker(lat: number, lon: number){
    this.removeSearchedLatLonMarkerFromMap()
    const marker = L.marker([lat, lon], { icon: this.mapPinIcon })
    this.searchedLatLonMarker.set(marker)
    this.searchedLatLonMarker().addTo(this.map)
  }

  addSearchControl(){
    const searchContainer = document.getElementById('search-container');

    if (searchContainer) {
      const searchControl = new L.Control({
        position: 'topleft',
      });

      searchControl.onAdd = () => {
        return searchContainer;
      };

      this.map.addControl(searchControl);
    }
  }

  addMeasurementLeafletRulerControl(){
    const rulerControl = leafletRuler('topleft', this.translate)
    this.map.addControl(rulerControl);
  }

  getDadosLoc(lat: number, lon: number, talhaoGeojson: any){
    try {
      let fireLocPoint = point([lon, lat]);
  
      let intersect = null;
      talhaoGeojson.eachLayer(layer => {
        if (booleanPointInPolygon(fireLocPoint, layer.toGeoJSON())) {
          intersect = layer.feature;
        }
      });
  
      if (intersect){
        return {
          'intersect': true,
          'properties':intersect.properties,
          'lat': lat,
          'lon': lon,
        }
  
      } else {
  
        let vertices = explode(talhaoGeojson.toGeoJSON());
        let closest = nearestPoint(fireLocPoint, vertices);
  
        return {
          'intersect':false,
          'properties':closest.properties,
          'lat': lat,
          'lon': lon,
        }
      }
    } catch(e){
      console.warn(`Error in getDadosLoc: ${e}`)
      return null
    }
  }

  updateCamerasLogsReplicationState(logs: any) {
    this.camerasLogsReplicationState.set(logs)
  }

  clearAutoGeolocLayers(){
    this.autoGeolocEllipseLayerGroup.clearLayers()
  }

  drawAutoGeolocEllipse(geoloc: any){
    try {
      this.clearAutoGeolocLayers()
      let center = [geoloc.lon, geoloc.lat];
      let options = {
        steps: 100,
        angle: geoloc.pan
      };
      let ellipse = turfEllipse(center, geoloc.x_uncertainty_km, geoloc.y_uncertainty_km, options);
      let ellipseGeojson = L.geoJSON(ellipse);
      ellipseGeojson.setStyle({ stroke: true, color: '#000000', fillColor: "#999999", weight: 2, fillOpacity: 0.4 })
      this.autoGeolocEllipseLayerGroup.addLayer(ellipseGeojson)
    } catch (e){
      const error = `Erro ao desenhar elipse de geolocalização automática: ${e}. Geoloc: ${JSON.stringify(geoloc)}`
      console.warn(error)
      this.logging.logERROR(error)
    }
  }

  updateDetectionIdToSendSearchOnMapAmplitudeEvent(detectionId: any | null) {
    this.detectionIdToSendSearchOnMapAmplitudeEvent.set(detectionId)
  }

  //Simulates effect of pressing F12 (without opening the dev tools)
  triggerPageReflow() {
    if (this.sat.imageSwiperControl) {
      const body = document.body;    
      body.offsetHeight; //approach 1
      window.dispatchEvent(new Event('resize')); //approach 2
    }
  }

}