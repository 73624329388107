<main>

  <mat-toolbar color='primary' class="custom-toolbar">
    @if (sat.selectedCluster.cluster_type == 'temporary') {
      <div>
        <span>{{ 'SAT.CLUSTER' | translate }}
          (#{{sat.selectedCluster.pk_mv}})
        </span>
      </div>
    }
    @if (sat.selectedCluster.cluster_type == 'confirmed') {
      <div>
        <span>{{ 'SAT.CLUSTER' | translate }} ✔️
          (#{{sat.selectedCluster.cluster_id}})
        </span>
      </div>
    }
    <div class="interval-createbtn">
      @if (user.hasReportCreation() && sat.selectedCluster.cluster_type == 'temporary') {
        <div class="reportCreation" >
          <button mat-mini-fab
          matTooltip="{{ 'SAT.BIND_CLUSTER' | translate }}"
          [color]="'warn'" (click)="openReportClusterDialog()">
          <mat-icon id="create-report-btn-icon">local_fire_department</mat-icon>
        </button>
      </div>
    }
  </div>
  
  <div class ="flex-spacer"></div>
    <button mat-mini-fab class='my-fab change-report-fab' [color]="'warn'" (click)="sat.unselectCluster()">
      <mat-icon>reply</mat-icon>
    </button>
  </mat-toolbar>

  <section class="content">

    <div class="cluster-attribute-set" matTooltip="{{ 'SAT.CLUSTER_ACQUISITION_TOOLTIP' | translate }}">
      <span class="cluster-attribute">{{ 'SAT.CLUSTER_ACQUISITION' | translate }}</span>
      <span class="cluster-attribute-value">{{sat.selectedCluster.oldest_hotspot}}</span>
      <div class="cluster-attribute-icon">
        <mat-icon [inline]="true">satellite_alt</mat-icon>
      </div>
    </div>

    <div class="cluster-attribute-set" matTooltip="{{ 'SAT.CLUSTER_DETECTION_TOOLTIP' | translate }}">
      <span class="cluster-attribute">{{ 'SAT.CLUSTER_DETECTION' | translate }}</span>
      <span class="cluster-attribute-value">{{sat.selectedCluster.detection_utc_offset}}</span>
      <div class="cluster-attribute-icon">
        <mat-icon [inline]="true">notifications</mat-icon>
      </div>
    </div>

    <div class="cluster-attribute-set">
      <div class = "cluster-attribute">
        @switch (sat.selectedCluster.symbology_class){
          @case (1) {
            <div class="cluster-newest-hotspot-red">
              {{ 'SAT.CLUSTER_NEWEST_HOTSPOT' | translate }}
            </div>
          }
          @case (2) {
            <div class="cluster-newest-hotspot-orange">
              {{ 'SAT.CLUSTER_NEWEST_HOTSPOT' | translate }}
            </div>
          }
          @case (3) {
            <div class="cluster-newest-hotspot-yellow">
              {{ 'SAT.CLUSTER_NEWEST_HOTSPOT' | translate }}
            </div>
          }
          @case (4) {
            <div class="cluster-newest-hotspot-gray">
              {{ 'SAT.CLUSTER_NEWEST_HOTSPOT' | translate }}
            </div>
          }
        }
      </div>
      <span class="cluster-attribute-value">{{sat.selectedCluster.newest_hotspot}}</span>
    </div>

    <div class="cluster-attribute-set" matTooltip="{{ 'SAT.CLUSTER_LOCATION_TOOLTIP' | translate }}">
      <span class="cluster-attribute">{{ 'SAT.CLUSTER_LOCATION' | translate }}</span>
      <span class="cluster-attribute-value">{{sat.selectedCluster.latitude}}&deg;, {{sat.selectedCluster.longitude}}&deg;</span>
      <div class="cluster-attribute-icon">
        <mat-icon [inline]="true">location_on</mat-icon>
      </div>
    </div>

    <div class="cluster-attribute-set">
      <span class="cluster-attribute">{{ 'SAT.CLUSTER_AGE' | translate }}</span>
      <span class="cluster-attribute-value">{{sat.selectedCluster.cluster_age}}</span>
    </div>

    <div class="cluster-attribute-set" matTooltip="{{ 'SAT.CLUSTER_FRP_TOOLTIP' | translate }}">
      <span class="cluster-attribute">{{ 'SAT.CLUSTER_FRP' | translate }}</span>
      <span class="cluster-attribute-value">{{sat.selectedCluster.cluster_frp_mw_km2}} MW/km²</span>
      <div class="cluster-attribute-icon">
        <mat-icon [inline]="true">whatshot</mat-icon>
      </div>
    </div>

    <div class="cluster-attribute-set">
      <span class="cluster-attribute">{{ 'SAT.CLUSTER_CONFIDENCE' | translate }}</span>
      <span class="cluster-attribute-value">{{sat.selectedCluster.cluster_confidence}}%</span>
    </div>

    <div class="cluster-attribute-set">
      <span class="cluster-attribute">{{ 'SAT.CLUSTER_AMOUNT' | translate }}</span>
      <span>{{sat.selectedCluster.amount_of_hotspots}}</span>
    </div>

    <div class="cluster-attribute-set" matTooltip="{{ 'SAT.CLUSTER_AREA_TOOLTIP' | translate }}">
      <span class="cluster-attribute">{{ 'SAT.CLUSTER_AREA' | translate }}</span>
      <span class="cluster-attribute-value">{{sat.selectedCluster.area_ha}} ha</span>
    </div>

    <div class="cluster-attribute-set">
      <span class="cluster-attribute">{{ 'MAP.PROPERTY' | translate }}</span>
      @if (sat.selectedCluster.distance_to_talhao_m > 0) {
        <div>
          <span class="cluster-attribute-value">{{sat.selectedCluster.talhao_nome}} ({{sat.selectedCluster.distance_to_talhao_m}} m)</span>
        </div>
      }
      @if (sat.selectedCluster.distance_to_talhao_m == 0) {
        <div>
          <span class="cluster-attribute-value">{{sat.selectedCluster.talhao_nome}}</span>
        </div>
      }
    </div>

    <div class="cluster-attribute-set">
      <span class="cluster-attribute">{{ 'SAT.CLUSTER_ALERT' | translate }}?</span>
      @if (sat.selectedCluster.alerted) {
        <span class="cluster-attribute-value-yes">
          {{ 'MAP.YES' | translate }}
        </span>
        <div class="cluster-attribute-icon">
          <mat-icon [inline]="true">thumb_up_alt</mat-icon>
        </div>
      }
      @else {
        <span class="cluster-attribute-value-no">
          {{ 'MAP.NO' | translate }}
        </span>
        <div class="cluster-attribute-icon">
          <mat-icon [inline]="true">thumb_down_alt</mat-icon>
        </div>
      }
    </div>

  </section>

  <mat-accordion>

    <mat-expansion-panel #expansionPanel (opened)="sendEvent($event)"> <!--[expanded]="true"-->
      <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
        <mat-panel-title>
          <span class='panel-title' style="font-size:14px">
            {{ 'SAT.CLUSTER_HOTSPOT_TABLE' | translate }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table class="mat-elevation-z8" mat-table [dataSource]="this.sat.clusterHotspotTableData" matSort #clusterHotspotTableSort="matSort" matSortActive="acquisition_utc_offset" matSortDirection="desc" matSortDisableClear>

        <ng-container matColumnDef="satellite">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SAT.SATELLITE' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{element.satellite}}
          </td>
        </ng-container>

        <ng-container matColumnDef="acquisition_utc_offset">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SAT.HOTSPOT_ACQUISITION' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{element.acquisition_utc_offset | date:'dd/MMM - HH:mm'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="detection_utc_offset">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SAT.HOTSPOT_DETECTION' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{element.detection_utc_offset | date:'dd/MMM - HH:mm'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="confidence">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SAT.HOTSPOT_CONFIDENCE' | translate }}<br>(%)</th>
          <td mat-cell *matCellDef="let element">
            {{element.confidence}}
          </td>
        </ng-container>

        <ng-container matColumnDef="frp_normalized_mw_km2">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SAT.HOTSPOT_FRP' | translate }}<br>(MW/km²)</th>
          <td mat-cell *matCellDef="let element">
            {{element.frp_normalized_mw_km2}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="clusterHotspotTableColumns; sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: clusterHotspotTableColumns;"
          class='table-row'
        (click)="selectHotspot(row.id_hotspot)"></tr>
      </table>

    </mat-expansion-panel>

    @if (sat.selectedCluster.cluster_type == 'temporary' && !expansionPanel.expanded) {
      <br>
    }
    @if (sat.selectedCluster.cluster_type == 'temporary' && expansionPanel.expanded) {
      <p style="margin: 0.5em;"></p>
    }

  </mat-accordion>
</main>