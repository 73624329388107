import {Component, OnInit, ElementRef, Input, OnChanges, SimpleChanges, signal, inject} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';

import { Chart, ChartOptions, ChartType, ChartConfiguration, registerables} from 'chart.js';
Chart.register(...registerables); // TO DO só registrar o que realmente usar pra otimizar bundle

import { ReportsService } from '../../services/reports.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { HttpService } from 'src/app/services/http.service';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '../../services/logging.service';
import { BehaviorSubject } from 'rxjs';
import { FullScreenDashDialog } from 'src/app/shared/dialogs/dashboard-dialog/full-screen-dash-dialog';
import { PDFDocument } from 'pdf-lib';
import html2canvas from 'html2canvas';

export const PALETA_DASH = [
  '#03045e98',
  '#023e8a98',
  '#0077b698',
  '#0096c798',
  '#00b4d898',
  '#48cae498',
  '#ade8f498',
  "#194f27",
  '#34a85398'
];

export const ACIONAMENTOS_LABELS = [
  'MAP.REPORTS',
  'MAP.BRIGADE_DRIVES',
  'MAP.DISPLACEMENT_PLURAL',
  'MAP.FIRE_FIGHT_PLURAL',
  'MAP.AFTERMATH_PLURAL',  
]
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnChanges {
    
  public loaded: boolean = true;

  public chartTypes: ChartType[] =['line', 'bar', 'doughnut']

  public acionamentosChart: Chart;
  public areaQueimadaChart: Chart;
  public causasChart: Chart;
  public origensChart: Chart;
  public operadoresChart: Chart;
  public riscoChart: Chart;
  public jariChart: Chart;
  public deletedChart: Chart;
  public trpEvolutionChart: Chart;

  public dashboardData
  public acionamentosData
  public summaryData
  public countData
  public reportsStartDate:Date;
  public reportsEndDate:Date;
  public deletedObjects
  public deletedObjectsData
  public searchedStart:Date;
  public searchedEnd:Date;

  public errorMsg:string;
  public showMultiPlantasButton = false;

  public trpEvolution
  public dashReady = new BehaviorSubject<{ dataLoaded: boolean, dashTabSelected: boolean}>({ dataLoaded: false, dashTabSelected: false});
  public dateChanged = new BehaviorSubject<boolean>(false);
  public maxDateLimit = signal<boolean |undefined>(undefined)

  public managerOptions = [
    { id: 1, tooltip: `${this.translate.instant("MAP.CURRENT_UNIT")} (${this.user.planta.nome_planta})`, icon: 'show_chart', option: "MAP.CURRENT_UNIT"},
    { id: 2, tooltip: `${this.translate.instant("MAP.ALL_UNITS")}`, icon: 'stacked_line_chart', option: "MAP.ALL_UNITS"},
  ];
  public managerOptionsFormControl = new FormControl(this.managerOptions[0].id);
  private downloadSnackBar = inject(MatSnackBar)

  @Input() dialogStartDate?: Date;
  @Input() dialogEndDate?: Date;
  @Input() public expanded: boolean=false;
  @Input() public dashTabSelected: boolean = false;
  @Input() public fullScreenDash:boolean = false;
 
  constructor(
    private dialog: MatDialog,
    private reports:ReportsService,
    private amplitude: AmplitudeService,
    public translate: TranslateService,
    public logging: LoggingService,
    private elementRef: ElementRef,
    public user: UserDataService,
    private http: HttpService,
    ) { }

  async ngOnInit() {
    this.loaded = false;
    
    //Este dado não muda. Não precisa atualizar toda vez que altera a data.
    this.trpEvolution = await this.reports.getTrpEvolution();

    this.reportsEndDate = this.dialogEndDate || new Date();
    this.reportsStartDate = this.dialogStartDate || new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);

    this.dashReady.subscribe((dash) => {
      if (dash.dataLoaded && dash.dashTabSelected && this.managerOptionsFormControl.value == 1){
        this.createCharts();
   }
  })
   if (this.fullScreenDash){
    const dashReadyValue = this.dashReady.value; 
    this.dashReady.next({ ...dashReadyValue, dashTabSelected: true });
   }

    // tentando nao repedir as requests
    if(!this.dashboardData){
      await this.updateReportsDf()
    }

    // pro dash multiplanta, pra usuarios ugm terem as plantas do cliente e nao plantas ugm
    if (this.user.isUgm()){
      this.user.plantas_list = await this.http.centralGet('get_same_client_plantas', [`${this.user.getIdPlanta()}`]); 
    };
    if ((this.user.isManager2() || this.user.isUgm()) && this.user.plantas_list.length > 1){
      this.showMultiPlantasButton = true;
    };

    this.loaded = true;
    this.checkDateLimitToDownloadExcel()
  }

  checkDateLimitToDownloadExcel() {
    let startDate = new Date(formatDate(this.reportsStartDate, 'dd-MMM-yyyy', 'en-US'));
    let endDate = new Date(formatDate(this.reportsEndDate, 'dd-MMM-yyyy', 'en-US'));

    let max = endDate.getTime() - startDate.getTime();
    
    let days = max / (1000 * 60 * 60 * 24);

    if (days > 6){
      this.maxDateLimit.set(true)
    }else{
      this.maxDateLimit.set(false)
    }
}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.initialStartDate || changes.initialEndDate) {
      
      if (changes.initialStartDate && this.reportsStartDate !== changes.initialStartDate.currentValue) {
        this.reportsStartDate = changes.initialStartDate.currentValue;
      }
      if (changes.initialEndDate && this.reportsEndDate !== changes.initialEndDate.currentValue) {
        this.reportsEndDate = changes.initialEndDate.currentValue;
      }
      await this.updateReportsDf();
      if (this.managerOptionsFormControl.value===1 && this.countData){
        this.createCharts();
      } 
    }
  }

  onToggleOptionChange(selectedSubTab){
    if (this.managerOptionsFormControl.value === 2 && this.countData){
      this.destroyCharts();
    };
    if (this.managerOptionsFormControl.value === 1 && this.countData){
      this.createCharts();
    }
    this.amplitudeSubTabEvent(selectedSubTab);
  }

  async onEndDateChange(endDate: Date) {
    if (endDate) {
      this.checkDateLimitToDownloadExcel()
      await this.updateReportsDf();
      this.amplitude.sendDatePickerEvent('reports', 'Dashboard', `[${formatDate(this.reportsStartDate, 'dd-MMM-yyyy', 'en-US')}, ${formatDate(this.reportsEndDate, 'dd-MMM-yyyy', 'en-US')}]`);
    }
  }

  async updateReportsDf(){
    try {
      
      console.log('updateReportsDf')

      let n_relatorios = await this.reports.checkReports(this.reportsStartDate, this.reportsEndDate);
      console.log('checkReports', n_relatorios);
      
      if (n_relatorios == 0 || !(typeof(n_relatorios) === 'number')){
        const chartTitleKey = 'REP.NO_REPORTS';
        const chartTitle = this.translate.instant(chartTitleKey);
        this.countData = null;
        this.errorMsg = chartTitle;
      } else {
        this.dashboardData = await this.reports.getReportsDashData(this.reportsStartDate,this.reportsEndDate);
        this.acionamentosData = this.dashboardData.acionamentos ?  this.dashboardData.acionamentos : null;
        this.summaryData = this.dashboardData.relatorios ?  this.dashboardData.relatorios : null;
        this.deletedObjects = this.dashboardData.deleted_objects ? this.dashboardData.deleted_objects : null;
        this.deletedObjectsData = Object.keys(this.deletedObjects.deleted_objects);

        const dashReadyValue = this.dashReady.value;
        this.dashReady.next({ ...dashReadyValue, dataLoaded: true });
        
        console.log('statisticsData', this.dashboardData)
        this.searchedStart = this.reportsStartDate;
        this.searchedEnd = this.reportsEndDate;
        
        this.updateViewData()

      }
    
    } catch (e) {
      this.countData = null;
      this.errorMsg = 'Erro interno ao processar os dados';
      console.log(e);
      this.logging.logERROR(`updateReportsDf ${e}`,e);
    }
    if (this.managerOptionsFormControl.value === 2){
      this.dateChanged.next(true);
    }
  }

  // poderia tentar unificar com a func abaixo, ou com a do excel sat
  async downloadAlertsXls(){
    this.downloadSnackBar.open(this.translate.instant("MAP.DOWNLOAD_IN_PROGRESS"), this.translate.instant("MAP.CLOSE"))

    let startDate = formatDate(this.reportsStartDate, 'yyyy-MM-dd', 'en-US')
    let endDate = formatDate(this.reportsEndDate, 'yyyy-MM-dd', 'en-US')

    let alertsXlsBinary = await this.http.centralGetBinary('download_alerts_xls', [`${this.user.getIdPlanta()}`, startDate, endDate]);
    let blob = new Blob([alertsXlsBinary], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let blobUrl = window.URL.createObjectURL(blob)
    let link = document.createElement('a');
    link.href = blobUrl;
    link.download = `pantera_alerts_${startDate}_${endDate}.xlsx`;
    link.click();

    this.downloadSnackBar.dismiss();
    this.downloadSnackBar.open(this.translate.instant("MAP.DOWNLOAD_FINISHED"), this.translate.instant("MAP.CLOSE"),{duration: 5000})
    this.amplitude.sendEvent('Baixou Excel Relatórios')
  }

  async downloadReportsXls(){
    this.downloadSnackBar.open(this.translate.instant("MAP.DOWNLOAD_IN_PROGRESS"), this.translate.instant("MAP.CLOSE"))
    let n_relatorios = await this.reports.checkReports(this.reportsStartDate,this.reportsEndDate);
    let startDate = formatDate(this.reportsStartDate, 'yyyy-MM-dd', 'en-US')
    let endDate = formatDate(this.reportsEndDate, 'yyyy-MM-dd', 'en-US')

    if (!n_relatorios){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("REP.NO_REPORTS")}`}
      });
    } else {
      let response = await this.reports.downloadReportsXls(startDate,endDate);
      let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      let blobUrl = window.URL.createObjectURL(blob)
      let link = document.createElement('a');
      link.href = blobUrl;
      link.download = `pantera_export_${startDate}_${endDate}.xlsx`;
      link.click();
    }
    this.downloadSnackBar.dismiss();
    this.downloadSnackBar.open(this.translate.instant("MAP.DOWNLOAD_FINISHED"), this.translate.instant("MAP.CLOSE"),{duration: 5000})
    this.amplitude.sendEvent('Baixou Excel Relatórios')
  }

  updateViewData(){
    try {
      this.countData = [
        {
          text: this.translate.instant(ACIONAMENTOS_LABELS[0]),
          value:this.acionamentosData.relatorios_count,
          percentage:this.acionamentosData['relatorios%']['ocorrencias'],
          icon:'local_fire_department'
        },
        {
          text: this.translate.instant(ACIONAMENTOS_LABELS[1]),
          value:this.acionamentosData.acionamentos_count,
          percentage:this.acionamentosData['relatorios%']['acionamentos'],
          duration: this.formatDuration(this.acionamentosData.confirmacao_delay_avg),
          icon:'phone_in_talk'
        },
        {
          text: this.translate.instant(ACIONAMENTOS_LABELS[2]),
          value:this.acionamentosData.acionamentos_count,
          percentage:this.acionamentosData['relatorios%']['acionamentos'],
          duration: this.formatDuration(this.acionamentosData.deslocamento_duracao_avg),
          icon:'fire_truck'
        },
        {
          text: this.translate.instant(ACIONAMENTOS_LABELS[3]),
          value:this.acionamentosData.combates_iniciados_count,
          percentage:this.acionamentosData['relatorios%']['combates'],
          duration: this.formatDuration(this.acionamentosData.combate_duracao_avg),
          icon:'fire_extinguisher'
        },
        {
          text: this.translate.instant(ACIONAMENTOS_LABELS[4]),
          value:this.acionamentosData.rescaldos_iniciados_count,
          percentage:this.acionamentosData['relatorios%']['rescaldos'],
          duration: this.formatDuration(this.acionamentosData.rescaldo_duracao_avg),
          icon:'fire_extinguisher'
        }
      ]
    } catch(e) {
      console.log(e);
      this.logging.logERROR(`updateViewData ${e}`,e);
    }

  }

  formatDuration(d){
    try {
      let durationStr = ''
      if(d.negative){
        durationStr += '- '
      }
      if(d.hh > 0){
        durationStr += `${d.hh} h `
      }
      durationStr += `${d.mm} min`
      return durationStr
    } catch (e) {
      this.logging.logERROR(`formatDuration ${e}`,e);
      return ''
    }
  }

  createCharts(){
    // gambizinha
    setTimeout(() => {
      this.createAcionamentosChart();
      this.createaAreaQueimadaChart();
      this.createCausasChart();
      this.createOrigensChart();
      this.createRiscoChart();
      this.createJariAreaChart();
      this.createOperadoresChart();
      this.createDeletedObjectsChart();
      this.createTrpEvolutionChart();
    }, 200);
  }

  createAcionamentosChart(){
    if (this.acionamentosChart) {
      this.acionamentosChart.destroy();
    }
    if(this.countData !== null && this.countData !== undefined){
      let acionamentosData = this.countData.map((i) => i.value)
      let acionamentosLabels = this.countData.map((i) => i.text)

      const chartTitleKey = 'MAP.ACTIVATION_COMBAT';
      const chartTitle = this.translate.instant(chartTitleKey);

      const acionamentosConfig = this.configBarChart(chartTitle, acionamentosData, PALETA_DASH[0], acionamentosLabels)
      const htmlChartRef2 = this.elementRef.nativeElement.querySelector('#acionamentosChart');
      this.acionamentosChart = new Chart(htmlChartRef2, acionamentosConfig);
      return
    }
  }

  destroyCharts(){
    this.acionamentosChart.destroy();
    this.areaQueimadaChart.destroy();
    this.causasChart.destroy();
    this.origensChart.destroy();
    this.riscoChart.destroy();
    if (this.user.hasOcorrenciaRegiao()) this.jariChart.destroy();
    this.operadoresChart.destroy();
    this.deletedChart.destroy();
    this.trpEvolutionChart.destroy();
  }

  configPieChart(title: string, data: number[], backgroundColor: string[], labels: string[]){
    const dataConfig = {
      labels: labels,
      datasets: [{
        data: data,
        backgroundColor: backgroundColor,
      }]
    };
    const plugins = {
      title: {
        display: true,
        text: title,
        font: {
          size: 14
        }
      },
    }
    const options: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: plugins,
    }
    const config: ChartConfiguration = {
      type: 'pie',
      data: dataConfig,
      options: options,
    };
    return config;
  }

  configBarChartWithPercentage(title: string, dataValues: number[], backgroundColor: string[] | string, labels: string[], stacked:boolean = false){
    const data = {
      labels: labels,
      datasets: [{
        data: dataValues,
        backgroundColor: backgroundColor,
        maxBarThickness: 50,
      }]
    };
    const chartType: ChartType = 'bar';
    const config = {
      type: chartType,
      data: data,
      options: {
        maintainAspectRatio: true,
        scales: {
          x: {
            ticks: {
              maxRotation: 45,
              minRotation: 0,
            },
            stacked: stacked,
          },
          y: {
            ticks: {
              callback: function(value: number) {
                return `${value}%`;
              }
            },
            stacked: stacked,
          }
        },
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: title,
            font: {
              size: 14,
            }
          },
          tooltip: {
            callbacks: {
              label: function(tooltip) {
                return `${tooltip.raw}%`
                }
            }
        },
          legend: {
            display: false,
          }
        }
      }
    };
    return config;
  };

  configBarChart(title: string, dataValues: number[], backgroundColor: string[] | string, labels: string[], stacked:boolean = false){
    const data = {
      labels: labels,
      datasets: [{
        data: dataValues,
        backgroundColor: backgroundColor,
        maxBarThickness: 50,
      }]
    };
    const chartType: ChartType = 'bar';
    const config = {
      type: chartType,
      data: data,
      options: {
        scales: {
          x: {
            ticks: {
              maxRotation: 45,
              minRotation: 0,
            },
            stacked: stacked,
          },
          y: {
            stacked: stacked,
          }
        },
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: title,
            font: {
              size: 14,
            }
          },
          legend: {
            display: false,
          }
        }
      }
    };
    return config;
  };

  createaAreaQueimadaChart(){
    if (this.areaQueimadaChart) {
      this.areaQueimadaChart.destroy();
    }
    const chartTitleKey = 'LULC.BURNED_AREA';
    const chartTitle = this.translate.instant(chartTitleKey);
    const config = this.configBarChart(chartTitle, Object.values(this.summaryData.area_queimada_ha), PALETA_DASH[2], Object.keys(this.summaryData.area_queimada_ha))
    const htmlChartRef = this.elementRef.nativeElement.querySelector('#areaQueimadaChart');
    this.areaQueimadaChart = new Chart(htmlChartRef, config);
  }

  createCausasChart() {
    if (this.causasChart) {
      this.causasChart.destroy();
    }
    const chartTitleKey = 'MAP.CAUSES_FIRES';
    const chartTitle = this.translate.instant(chartTitleKey);
    const labels = Object.keys(this.summaryData.causa_incendio).map(key => {
      const translationKey = `CHAR.CAUSA_${key.toUpperCase().replace(/\s+/g, '_')}`;
      const translatedLabel = this.translate.instant(translationKey);
      return translatedLabel;
    });
    const config = this.configBarChart(chartTitle,  Object.values(this.summaryData.causa_incendio), PALETA_DASH[1], labels);
    const htmlChartRef = this.elementRef.nativeElement.querySelector('#causasChart');
    this.causasChart = new Chart(htmlChartRef, config);
  }

  createOrigensChart() {
    if (this.origensChart) {
      this.origensChart.destroy();
    }
    const chartTitleKey = 'MAP.SOURCE_DETECTIONS';
    const chartTitle = this.translate.instant(chartTitleKey);
    const labels = Object.keys(this.summaryData.origem_deteccao).map(key => {
      const translationKey = `CHAR.ORIGEM_${key.toUpperCase().replace(/\s+/g, '_')}`;
      const translatedLabel = this.translate.instant(translationKey);
      return translatedLabel;
    });
    const origemData = this.summaryData.origem_deteccao;  
    const valores = Object.values(origemData).map(value => Number(value) || 0);
    const totalDeteccao = valores.reduce((acc, value) => acc + value, 0);

    const percentuais = valores.map(value => {
      return parseFloat((totalDeteccao > 0 ? (value / totalDeteccao) * 100 : 0).toFixed(1));
    });
    const config = this.configBarChartWithPercentage(chartTitle, percentuais, PALETA_DASH[2], labels);
    const htmlChartRef = this.elementRef.nativeElement.querySelector('#origensChart');
    this.origensChart = new Chart(htmlChartRef, config);
  }

  createOperadoresChart(){
    if (this.operadoresChart) {
      this.operadoresChart.destroy();
    }
    const chartTitleKey = 'MAP.REPORTS_OPERATOR';
    const chartTitle = this.translate.instant(chartTitleKey);
    const config = this.configBarChart(chartTitle, Object.values(this.summaryData.operador), PALETA_DASH[2], Object.keys(this.summaryData.operador))
    const htmlChartRef = this.elementRef.nativeElement.querySelector('#operadoresChart');
    this.operadoresChart = new Chart(htmlChartRef, config);
  };

  createRiscoChart(){
    if (this.riscoChart) {
      this.riscoChart.destroy();
    }
    const chartTitleKey = 'MAP.REPORTS_RISK';
    const chartTitle = this.translate.instant(chartTitleKey);
    const withRisk = 'MAP.WITH_RISK';
    const withoutRisk = 'MAP.WITHOUT_RISK';
    const withRiskTranslated = this.translate.instant(withRisk);
    const withoutRiskTranslated = this.translate.instant(withoutRisk);
    const relatoriosSemRisco = Math.min(this.summaryData.sem_risco, this.acionamentosData.relatorios_count);
    const relatoriosComRisco = this.acionamentosData.relatorios_count - relatoriosSemRisco;
    const config = this.configPieChart(chartTitle, [relatoriosComRisco, relatoriosSemRisco], [PALETA_DASH[0], PALETA_DASH[2]], [withRiskTranslated, withoutRiskTranslated])
    const htmlChartRef = this.elementRef.nativeElement.querySelector('#riscoChart');
    this.riscoChart = new Chart(htmlChartRef, config);
  };
  

  createDeletedObjectsChart() {
    if (this.deletedChart) {
      this.deletedChart.destroy();
    }
    if (this.deletedObjectsData.length > 0) {
      const deletedObjectsData = this.deletedObjects.deleted_objects;
      const ids = Object.keys(deletedObjectsData);
      const deletedObjectsLabels = ids.map(id => {
        return this.translate.instant(`INTERFACE.RECLASSIFICATION.${id}`);
      });

      const totalDeletedObjects = ids.reduce((total, id) => total + (deletedObjectsData[id] || 0), 0);
      const deletedObjectsPercentages = ids.map(id => {
        const value = deletedObjectsData[id];
        const percentage =  value / totalDeletedObjects * 100 ;
        return parseFloat(percentage.toFixed(1));
      });
      
      const sortedData = deletedObjectsPercentages.map((value, index) => ({
        label: deletedObjectsLabels[index],
        value: value,
      })).sort((a, b) => b.value - a.value); 
  
      const sortedLabels = sortedData.map(item => item.label);
      const sortedValues = sortedData.map(item => item.value);
  
      const config = this.configBarChartWithPercentage(this.translate.instant('MAP.DELETED_OBJECT'), sortedValues, PALETA_DASH[5], sortedLabels);
  
      const htmlChartRef = this.elementRef.nativeElement.querySelector('#deletedChart');
      this.deletedChart = new Chart(htmlChartRef, config);
    }
  }
  
  createTrpEvolutionChart() {
    if (this.trpEvolutionChart) {
      this.trpEvolutionChart.destroy();
    }
    if (this.trpEvolution && this.trpEvolution.length > 0) {

      const trpData = this.trpEvolution.sort((a, b) => {
        if (a.year !== b.year) {
          return a.year - b.year; 
        } else {
          return a.month - b.month; 
        }
      });

      const labels = trpData.map(data => `${data.month.toString().padStart(2, '0')}/${data.year}`);
      const dataValues = trpData.map(data => data['TPRP (%)']);
  
      const config = this.configBarChartWithPercentage(this.translate.instant('MAP.TPRP_EVOLUTION'), dataValues, PALETA_DASH[5], labels);
      const htmlChartRef = this.elementRef.nativeElement.querySelector('#tprevolutionChart');
      this.trpEvolutionChart = new Chart(htmlChartRef, config);
    } 
  }
  
  
  createJariAreaChart(){
    if (!this.user.hasOcorrenciaRegiao()) return
    const relatoriosPlantio = this.summaryData.jari_area.plantio;
    const relatoriosNaoOperacional = this.summaryData.jari_area.nao_operacional;
    const relatoriosReservaLegal = this.summaryData.jari_area.reserva;
    const config = this.configPieChart('Relatórios por região', [relatoriosPlantio, relatoriosNaoOperacional, relatoriosReservaLegal], [PALETA_DASH[0], PALETA_DASH[3], PALETA_DASH[5]], ['Plantio', 'Area não operacional', 'Reserva'])
    const htmlChartRef = this.elementRef.nativeElement.querySelector('#jariChart');
    this.jariChart = new Chart(htmlChartRef, config);
  };

  openFullScreen() {

    let dialogConfig: MatDialogConfig = {
        data: {
            startDate: this.reportsStartDate,
            endDate: this.reportsEndDate,
            dashTabSelected: this.dashTabSelected,
        },
        panelClass: 'app-full-bleed-dialog',
        height: '85vw'
    };
    const dialogRef = this.dialog.open(FullScreenDashDialog, dialogConfig);
    this.updateViewData();
    dialogRef.afterClosed().subscribe(() => {this.expanded = false;});
}

  amplitudeSubTabEvent(selectedSubTab){
    this.amplitude.sendSubTabViewEvent('reports', 'Dashboard', this.managerOptions, selectedSubTab, 'option')
  }

  async downloadPdf() {
    const contentWrapper = document.getElementById("content-wrapper");
    const contentWrapperFullScreen = document.getElementById("content-wrapper-fulls");
    const elementToHide = document.getElementById("to-hide");
    const elementToHide2 = document.getElementById("to-hide-2");
  
    let element;
  
    if (contentWrapperFullScreen && contentWrapperFullScreen.style.display !== 'none') {
      element = contentWrapperFullScreen;
    } else {
      element = contentWrapper;
    }
  
    element.style.width = "auto";
    element.style.height = "auto";
    elementToHide.classList.add("hide");
    elementToHide2.classList.add("hide");

    let color;
    if(this.user.darkMode()){
      color = "#424242"
    }else {
      color = '#FFFFFF'
    }
  
    const canvas = await html2canvas(element, {
      scrollY: -window.scrollY,
      scale: 6,
      backgroundColor: color
    });
  
    const imgData = canvas.toDataURL("image/jpeg", 1.0);
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([canvas.width, canvas.height]);
    const pngImage = await pdfDoc.embedJpg(imgData);
    const { width: imgWidth, height: imgHeight } = pngImage.scale(1);
  
    page.drawImage(pngImage, {
      x: 0,
      y: page.getHeight() - imgHeight,
      width: imgWidth,
      height: imgHeight,
    });
    let startDate = formatDate(this.reportsStartDate, 'yyyy-MM-dd', 'en-US')
    let endDate = formatDate(this.reportsEndDate, 'yyyy-MM-dd', 'en-US')
  
    const pdfBytes = await pdfDoc.save();
    const fileName = `dashboard_${startDate}_${endDate}.pdf`;
    this.download(pdfBytes, fileName, "application/pdf");
  
    elementToHide.classList.remove("hide");
    elementToHide2.classList.remove("hide");
  }
  
  private download(data: Uint8Array, fileName: string, mimeType: string) {
    const blob = new Blob([data], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
  

}
