import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { UserDataService } from '../../services/user-data.service';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    
    constructor(
        public user: UserDataService
    ) {
        if (this.user.isOperatorP2() || user.isSupportP2()) {
            this.socket = io(this.user.getSocketIOIp(), { transports: ["websocket"] });
        }
    }
        
    // --------------------------------- Service class attributes ---------------------------------
    private socket: Socket
    

    // --------------------------------- Emitting methods ---------------------------------
    emitManualDetectionEvent(cameraId: number) {
        this.socket.emit("do_manual_detection", cameraId)
    }
    
    emitNewReportEvent() {
        this.socket.emit('new_report',  true);
    }
    

    // --------------------------------- Receiving methods ---------------------------------
    onNewManualDetectionEvent() {
        return new Observable<any>(observer => {
            this.socket.on("unblock_actions_frontend", arg => {
                observer.next(arg)
            })
        })
    }

    onNewReportEvent() {
        return new Observable<any>(observer => {
            this.socket.on("new_report", arg => {
                observer.next(arg)
            })
        })
    }
}