import { Component, OnInit } from '@angular/core';

import { GeoService } from 'src/app/services/geo.service';
import { HttpService } from 'src/app/services/http.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { GeoFactoryService } from 'src/app/services/geoFactory.service'
import { GeoMarkersService } from 'src/app/services/marker.service';

@Component({
  selector: 'app-map-cameras-controls',
  templateUrl: './map-cameras-controls.component.html',
  styleUrls: ['./map-cameras-controls.component.scss']
})
export class MapCamerasControlsComponent implements OnInit {

  protected cameras: any[];
  public camerasControlsOptionsColumns = ['Câmeras', 'Centralizar', 'Direcionar']

  constructor(
    private http: HttpService,
    private user: UserDataService,
    public geoService: GeoService,
    public geoMarkersService: GeoMarkersService,
    public geoFactoryService: GeoFactoryService
  ) { }

  async ngOnInit(){
    await this.getCameras();
  }

  async getCameras(){
    this.cameras = this.user.getDadosPantera('cameras');
  }

  centerOnCam(camera: any) {
    this.geoService.centerOnObject({ lat: camera.lat, lng: camera.lon })
  }
  
  toggleControlCameraDirection(camera: any) {
    const selectedCameraMarker = this.geoMarkersService.getMarkerByCameraId(camera.id);
    if (!selectedCameraMarker) return

    if (this.geoFactoryService.selectedCamerasIdsToControlFromMap().includes(camera.id)) {
      this.geoFactoryService.unselectTowerToControlFromMapRoutine(selectedCameraMarker)
      return
    }

    this.geoFactoryService.selectTowerToControlFromMapRoutine(selectedCameraMarker)
  }
}
