<div class="tab-content-container">
  <ng-template mat-tab-label>
    <mat-icon class="tab-icon">add_location_alt</mat-icon>
    Cadastro
  </ng-template>
  <mat-card appearance="outlined">
    <mat-card-title>{{ 'MAP.NEW_POI' | translate }}</mat-card-title>

    <div class="fx-column-space-around-center">

      <mat-form-field appearance="outline">
        <mat-label>{{ 'MAP.TYPE' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedType" name="type" [formControl]="typeFc">
          @for (type of types; track type.id_tipo_poi) {
            <mat-option [value]="type.legend2">
              {{type.legend2}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'MAP.NAME' | translate }}</mat-label>
        <input type="text" matInput [formControl]="poiNameFc"
          [(ngModel)]="poiName">
          @if (poiNameFc.hasError('pattern')) {
            <mat-error>Digite o nome do ponto de interesse</mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="outline" matTooltip="{{ 'MAP.POINT_OF_INTEREST_COORDS_TOOLTIP' | translate }}">
          <mat-label>Latitude</mat-label>
          <input required matInput [(ngModel)]="lat" type="number" min="-90" max="90" placeholder="Ex: -22.1823"/>
        </mat-form-field>

        <mat-form-field appearance="outline" color='primary' matTooltip="{{ 'MAP.POINT_OF_INTEREST_COORDS_TOOLTIP' | translate }}">
          <mat-label>Longitude</mat-label>
          <input required matInput [(ngModel)]="lon" type="number" min="-180" max=180 placeholder="Ex: -47.8911"/>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'MAP.OBSERVATIONS' | translate }}</mat-label>
          <input type="text" matInput [formControl]="obsFc"
            [(ngModel)]="obs">
          </mat-form-field>
        </div>

        <div class="fx-row-space-around-center" >
          <button mat-raised-button color='primary'
            [disabled]="poiNameFc.hasError('pattern') || poiNameFc.hasError('required') || typeFc.hasError('required') || !lat || !lon || (lat < -90) || (lat > 90) || (lon < -180) || (lon > 180)"
            (click)='postPoi()'>
            {{ 'MAP.CREATE_POI' | translate }}
          </button>
        </div>

      </mat-card>
    </div>
