import { Injectable, signal } from '@angular/core';
import { HttpService } from './http.service'
import { UserDataService } from './user-data.service'
import { toObservable } from '@angular/core/rxjs-interop';
@Injectable({
  providedIn: 'root'
})
export class PresetpickerService {

	constructor(
		private http: HttpService,
		private user: UserDataService,
	) { }

	public presetSignal = signal({ index: null, pan: null, tilt: null, zoom: null, sleep: null, uuid: null });
	public presetSignal$ = toObservable(this.presetSignal);
	
	public timeBetweenPositionsOnPresetsSimulator = signal<number>(3);

	updateTimeBetweenPositionsOnPresetsSimulator(value: number) {
		this.timeBetweenPositionsOnPresetsSimulator.set(value)
	}

	async updatePresetsImgCam(idCam, presetList) {
		return new Promise<void>(async (resolve) => {
			for (var preset of presetList) {
				await this.camPositionsAsync(idCam, preset);
				if(presetList.indexOf(preset)===presetList.length-1) resolve()
			}
		});
	}

	camPositionsAsync(idCam, preset) {
		return new Promise<void>((resolve) => {
			this.presetSignal.set(preset);
			this.requestPTZPosition(preset,idCam)
			.then(() => {
				setTimeout(() => {
					this.requestSnapshotRetry(idCam)
					.then((snapshot) => {
						this.sendSnapshotToBackend(idCam, snapshot, preset)
						.then(() => resolve())
					})
					.catch((error) => {
						console.error('Error no preset:', preset, error);
						resolve();
					});
				}, 5*1000);
			});
		});
	}

	requestPTZPosition(preset, idCam) {
		return new Promise<void>(async (resolve) => {
			this.http.get(this.user.getBackendURL(),['cameras', idCam, 'absolute_ptz',preset.pan,preset.tilt,preset.zoom])
			.then(()=>resolve())
		});
	}

	requestSnapshotRetry(idCam, maxRetries = 3, delayBetweenRetries = 1000) {
		return new Promise(async (resolve, reject) => {
			let retries = 0;

			while (retries < maxRetries) {
				try {
					const imgBytes = await this.http.getPromise(this.user.getBackendURL(),['cameras', idCam, 'get_snap']);
					resolve(imgBytes);
					return; // Saia da função se a solicitação for bem-sucedida
				}
				catch (error) {
			  		console.error('Error:', error);
					retries++;
			  		if (retries < maxRetries) {
						console.log(`Retrying in ${delayBetweenRetries / 1000} seconds...`);
						await new Promise(resolve => setTimeout(resolve, delayBetweenRetries));
			  		}
					else {
						console.log('Max retries reached. Giving up.');
						reject(false);
					}
				}
		  	}
		});
	}


	async sendSnapshotToBackend(idCam, snapshot, preset) {
		return new Promise<boolean>(async (resolve, reject) => {
			const currentDate = new Date();
			const currentDateTimeString = currentDate.toISOString();
			let post_data = {
				'uuid': preset.uuid,
				'id_planta': this.user.getIdPlanta(),
				'id_cam': idCam,
				'datetime': currentDateTimeString,
			};
			let formData: FormData = new FormData();
			formData.append('imgData', JSON.stringify(post_data));
			formData.append('file', await snapshot, `${preset.uuid}`);
			this.http.centralUpload('add_preset_image', await formData)
				.then(() => {
					resolve(true);
				})
				.catch((error) => {
					console.error('Error:', error);
					reject(false);
				});
		});
	}
}