import { Injectable, signal } from '@angular/core';
import { HttpService } from './http.service';
import { IS_OPERATOR_OR_SUPPORT_P2_KEY, UserDataService } from './user-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AmplitudeService } from './amplitude.service';
import { OnboardingService } from './onboarding.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private http: HttpService,
    private user: UserDataService,
    private translate: TranslateService, //rever se fica aqui
    private router: Router,
    private guidedTourService: OnboardingService,
    private amplitude: AmplitudeService,
    private authService: MsalService
  ) {}
  public mfaExpired = signal<boolean | undefined>(undefined)
  public wrongMfa = signal<boolean | undefined>(undefined)

  public isOperatorOrSupportP2 = signal(false);

  setMfaExpired(mfa_expired: boolean){
    this.mfaExpired.set(mfa_expired)
  }

  setWrongMfa(wrong_mfa: boolean){
    this.wrongMfa.set(wrong_mfa)
  }

  setIsOperatorOrSupportP2(value: boolean) {
    this.isOperatorOrSupportP2.set(value)
    localStorage.setItem(IS_OPERATOR_OR_SUPPORT_P2_KEY, JSON.stringify(value))
  }

  // TO-DO: unificar o tipo de retorno de funcoes como essa - nao eh boa pratica retornar tipos diferentes (nesse booleano ou json)
  // essa funcao deveria retornar algo como { success: boolean, isMFA: boolean }: ICredentialsLoginResponse
  async credentialsLogin(username, userpass, rememberMe) {
    let user_info = {
      username: username,
      userpass: userpass,
      extended: rememberMe,
    };
    console.log('-- pweb_login❓', user_info.username);

    let loginResponse = await this.http.maestroUnauthPost('v2/pweb_login', user_info);
    if (loginResponse == null) {
      this.signOut();
      return false;
    }
    if (loginResponse['mfa'] === false) {
      await this.tokenLogin(loginResponse['jwt']);
      return true;
    } else {
      return loginResponse;
    } 
  }

  async mfaLogin(username, userpass, rememberMe, mfaCode) {
    const mfaData = {
      username: username.trim(),
      userpass: userpass.trim(), 
      extended: rememberMe,
      mfa_code: mfaCode.trim(),
    };
  
    const mfaResponse = await this.http.maestroUnauthPost('v2/pweb_login', mfaData);

    if (mfaResponse == null) {
      this.signOut();
      return false;
    }
  
    if (mfaResponse['mfa'] === false && mfaResponse['jwt']) {
      await this.tokenLogin(mfaResponse['jwt']);
      return true;
    } else {
      const errorMessage = mfaResponse.error;
      this.setMfaExpired(errorMessage === 'Código expirado');
      this.setWrongMfa(errorMessage === 'Código inválido');
      return false;
    }
  }
  
  

  async tokenLogin(userToken) {
    this.user.saveToken(userToken);
    this.user.saveLang(this.translate.currentLang);
    let tokens = await this.http.maestroGet('get_maestro_tokens', userToken)
    this.user.saveMaestroToken(tokens['maestro'])
    this.user.saveSocketCentralToken(tokens['socket'])

    const isP2 = JSON.parse(localStorage.getItem("IS_OPERATOR_OR_SUPPORT_P2"))
    if (!isP2) {
      this.router.navigateByUrl('/map');
    }
    else {
      this.router.navigateByUrl('/interface')
    }
    
    console.log('-- ✔️Logged in: user validated');
    //this.amplitude.sendEvent('Fez Login'); // em stand by (ver tabela de eventos)

    this.guidedTourService.loadedSub.subscribe((loaded) => {
      if (loaded) {
        this.guidedTourService.startMapTour();
      }
    });
  }

  signOut() {
    this.user.signOut();

    // logoff Azure AD - Raízen
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.authService.instance.logout();
    }
  }
}
