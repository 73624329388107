import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchReport'
})

export class SearchReportPipe implements PipeTransform {

  transform(reports: any[], searchText?: string, filterHasRisk: boolean = false, filterInProgress: boolean= false, filterHasTriggerings: boolean= false): any[] {
    if (!reports) return [];
    if (!searchText && !filterHasRisk && !filterInProgress && !filterHasTriggerings) return reports;

    searchText = searchText ? searchText.toLowerCase() : '';

    return reports.filter(report => {
      
      const matchesText = searchText ? JSON.stringify(report.n_relatorio_planta).toLowerCase().includes(searchText) : true;

      
      const matchesHasRisk = filterHasRisk ? !report.sem_risco : true;
      const matchesInProgress = filterInProgress ? !report.datetime_finalizado : true;
      const matchesHasTriggerings = filterHasTriggerings ? report.acionamentos.length > 0 : true;
      return matchesText && matchesHasRisk && matchesInProgress && matchesHasTriggerings;
    });
  }

}
@Pipe({
  name: 'searchBrigade'
})
export class SearchBrigadePipe implements PipeTransform {

  transform(brigades: any[], searchText?: string): any[] {
    if (!brigades) return [];
    if (!searchText) return brigades;
    
    searchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase(); //Ignora acentos.

    return brigades.filter(brigade => {

      const matchesText = [brigade.nome_brigada, brigade.placa, brigade.categoria_brigada].filter(item => item != null).map(item => item.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()).join(' '); 
      return matchesText.includes(searchText);
    });
  }

}
@Pipe({
  name: 'searchPoi'
})
export class SearchPoiPipe implements PipeTransform {

  transform(pois: any[], searchText?: string): any[] {
    if (!pois) return [];
    if (!searchText) return pois;

    searchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return pois.filter(poi => {
      const nomePoiNormalized = poi.nome_poi.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      return nomePoiNormalized.includes(searchText);
    });
  }
}