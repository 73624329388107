import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';

import { HttpService } from 'src/app/services/http.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { GeoService } from 'src/app/services/geo.service';
import { SatelliteService } from 'src/app/services/satellite.service';
import { AmplitudeService } from '../../services/amplitude.service';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-satellite',
  templateUrl: './satellite.component.html',
  styleUrls: ['./satellite.component.scss']
})
export class SatelliteComponent implements OnInit, AfterViewInit {
  
  private tabs = {
    0: 'Agrupamentos',
    1: 'Alertas',
    2: 'Histórico'
  }

  constructor(
    private http: HttpService,
    public user: UserDataService,
    public geo: GeoService,
    public sat: SatelliteService,  
    private amplitude: AmplitudeService,
    public logging: LoggingService
  ) {}
  
  @ViewChild('clusterTableSort') clusterTableSort = new MatSort();
  
  public clusterTableColumns: string[] = [
    'detection_utc_offset',
    'cluster_confidence',
    'amount_of_hotspots',
    'cluster_frp_mw_km2',
    'talhao_nome',
    'cluster_id',
    'recent',
  ]

  async ngOnInit(){}

  async ngAfterViewInit(){
    if (this.sat.clusterTableData){
      this.sat.clusterTableData.sortingDataAccessor = (data, sort) => {
        return sort === 'detection_utc_offset' ? new Date(data.detection_utc_offset) : data[sort];
      };
      this.sat.clusterTableData.sort = this.clusterTableSort;
      this.geo.updateSatActiveFireData$.subscribe(() => {this.sat.clusterTableData.sort = this.clusterTableSort});
    }
  }

  selectCluster(id){
    let groupIndex = this.geo.layerGroupList.findIndex(l => l.groupId === 4);
    let layerIndex = this.geo.layerGroupList[groupIndex].layersList.findIndex(l => l.legend === 'SAT.CLUSTER_LAYER');
    if (!this.geo.layerGroupList[groupIndex].layersList[layerIndex].active){
      this.geo.activateLayer(groupIndex, layerIndex);
    }
    this.geo.openClusterPopUp(id);
    this.sat.selectCluster(id);
    this.amplitude.sendEvent("Abriu Agrupamento", {"Origem": "Tabela", "unique_id": id});
  }

  amplitudeTabEvent(selectedTab){
    this.amplitude.sendTabViewEvent('sat', this.tabs, selectedTab);
  }  

}
