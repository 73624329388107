import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UserDataService } from './user-data.service';
import { formatDate } from '@angular/common';
import { LoggingService } from './logging.service';

const ID_MONIT_TOWER = 2

@Injectable({
  providedIn: 'root'
})
export class MeteoService {

constructor(
  public http: HttpService,
  private user: UserDataService,
  public logging: LoggingService
  ) { }
  
  public stations_data:any;

  async getMeteoPlacesCount(){
    const locaisCountPlanta = await this.http.centralGet('get_locais_count_planta', [`${this.user.getIdPlanta()}`])
    let meteoPlacesCount = 0;
    try {
      meteoPlacesCount = locaisCountPlanta.filter(localCount => localCount.id_tipo_local == ID_MONIT_TOWER)[0].count
    } catch(error) {
      this.logging.logERROR(`getMeteoPlacesCount:locaisCountPlanta.filter ${error}`,error);
      console.log('Sem torres de monitoramento');
    }
    const stationsPlanta = await this.http.centralGet('get_stations_planta', [`${this.user.getIdPlanta()}`]);
    if (stationsPlanta){
      meteoPlacesCount = stationsPlanta.length;
    } else {
      console.log('Sem estações meteorológicas')
    }
    console.log('meteoPlacesCount', meteoPlacesCount)
    return meteoPlacesCount
  }

  async getTowersData(search_date = null){
    if (!search_date){
      search_date = formatDate(new Date(), 'yyyy-MM-dd ', 'en-US')
    }
    console.log('get_towers_data', [search_date, `${this.user.getIdPlanta()}`]);
    let meteoData = await this.http.centralGet('get_towers_data', [search_date, `${this.user.getIdPlanta()}`])
    return meteoData;
  }

  async getStationsData(search_date = null){
    if (!search_date){
      search_date = formatDate(new Date(), 'yyyy-MM-dd ', 'en-US')
    }
    console.log('get_stations_data', [search_date, `${this.user.getIdPlanta()}`]);
    let stations_data =  await this.http.centralGet('get_stations_data', [search_date, `${this.user.getIdPlanta()}`])
    return stations_data;
  }

}
