<section class="main-wrapper-reports">

  @if (!reports.selectedR) {
    <section class="map-side-panel">
      <app-reports-table></app-reports-table>
    </section>
  }

  @else {
    <section>
      <div [ngClass]="user.darkMode() ? 'report-data-and-actions-header-dark' : 'report-data-and-actions-header'">
        <div class='left-side-report-header'>
          <mat-icon (click)="unselectReport()">arrow_back</mat-icon>
          {{ 'REP.REPORT' | translate }} {{ reports.selectedR.n_relatorio_planta }} - {{ reports.selectedR.datetime_deteccao | date: "dd/MM HH:mm" }}
        </div>
        <div class='right-side-report-header'>
          <mat-icon (click)="previousReport()">skip_previous</mat-icon>
          <mat-icon (click)="nextReport()">skip_next</mat-icon>
        </div>
      </div>

      <div class="report-image-wrapper">
        @if (reports.imagesLoaded()) {
          <app-report-imgs></app-report-imgs>
        }
      </div>

      <div class="report-data-and-actions">
        <div class="actions-and-selection-boxes">
          <div class="report-buttons-actions">

            <button color="warn" (click)='copyReportLink()' [matMenuTriggerFor]="menuShare" mat-mini-fab matTooltip="{{ 'MAP.SHARE' | translate }}">
              <mat-icon>share</mat-icon>
            </button>
            <mat-menu #menuShare="matMenu">
              <button mat-menu-item (click)='sendReportViaTelegram()'>
                <mat-icon>phonelink_ring</mat-icon>
                <span>{{ 'MAP.SEND_VIA_TELEGRAM' | translate }}</span>
              </button>
              <button mat-menu-item (click)='sendReportViaWhatsapp()'>
                <mat-icon>phonelink_ring</mat-icon>
                <span>{{ 'MAP.SEND_VIA_WHATSAPP' | translate }}</span>
              </button>
              <button mat-menu-item (click)='openDownloadDialog()'>
                <mat-icon>mail</mat-icon>
                <span>{{ 'MAP.SEND_VIA_EMAIL' | translate }}</span>
              </button>
              <button mat-menu-item (click)='openDownloadDialog()'>
                <mat-icon>file_download</mat-icon>
                <span>{{ 'MAP.DOWNLOAD_REPORT' | translate }}</span>
              </button>
            </mat-menu>
  
            <button color="warn" [disabled]="user.isOperatorReadOnly()" [matMenuTriggerFor]="menuActions" mat-mini-fab matTooltip="{{ 'MAP.MORE_OPTIONS' | translate }}">
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menuActions="matMenu">
              @if(user.isOperatorP2() || user.isSupportP2()) {
                <button mat-menu-item  (click)="pointCameraToReportImagePTZ()">
                  <mat-icon>remove_red_eye</mat-icon>
                  <span>{{ 'MAP.POINT_CAMERA' | translate }}</span>
                </button>
              }
              <button mat-menu-item (click)='launchPropagation()' [disabled]="!user.hasPropagation()">
                <mat-icon>whatshot</mat-icon>
                <span>{{ 'MAP.SIMULATE_PROPAGATION' | translate }}</span>
              </button>
              <button mat-menu-item (click)='openAddImagesDialog()'>
                <mat-icon>add_photo_alternate</mat-icon>
                <span>{{ 'MAP.ADD_IMAGE' | translate }}</span>
              </button>
              @if(user.isDev()){
                <button mat-menu-item (click)='deleteImageFromReport()'>
                  <mat-icon>delete</mat-icon>
                  <span>{{ 'MAP.DELETE_SELECTED_IMG' | translate }}</span>
                </button>
              }
              @if(user.isManager()){
                <button mat-menu-item (click)='deleteReport()'>
                  <mat-icon>delete</mat-icon>
                  <span>{{ 'REP.DELETE_REPORT' | translate }}</span>
                </button>
              }
            </mat-menu>
          </div>

          <div class="selection-boxes">
            <mat-form-field  appearance="outline">
              <mat-label>{{ 'MAP.DETECTION_SOURCE' | translate }}</mat-label>
              <mat-select (selectionChange)="updateDetectionOrigin()" [disabled]='reports.selectedR.id_origem_deteccao === 1 || !user.hasFireManagement() || user.isOperatorReadOnly()' [(ngModel)]="reports.selectedR.id_origem_deteccao">
                @for (o of origens; track o.id_origem_deteccao) {
                  <mat-option [value]="o.id_origem_deteccao">{{origensTranslated[o.id_origem_deteccao]}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="outline">
              <mat-label>{{ 'MAP.FIRE_CAUSE' | translate }}</mat-label>
              <mat-select [disabled]="user.isOperatorReadOnly()" (selectionChange)="updateFireCause()" [(ngModel)]="reports.selectedR.id_causa_incendio">
                @for (c of causas; track c.id_causa_incendio) {
                  <mat-option [value]="c.id_causa_incendio">{{causasTranslated[c.id_causa_incendio]}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div [ngClass]="user.darkMode() ? 'report-data-box-dark' : 'report-data-box'">
          <div class="data-box-header">
            <div class="header-title">
              <button [disabled]="user.isOperatorReadOnly()" [matMenuTriggerFor]="menuLoc" mat-mini-fab color="warn" matTooltip="{{ 'MAP.CHANGE_LOCATION' | translate }}">
                <mat-icon>location_on</mat-icon>
              </button>

              <mat-menu #menuLoc="matMenu">
                <button mat-menu-item (click)="changeLocationByCoords()">
                  <mat-icon>edit_location</mat-icon>
                  <span>{{ 'MAP.UPDATE_LOCATION' | translate }}</span>
                </button>

                <button mat-menu-item (click)="changeLocationByClick()">
                  <mat-icon>map</mat-icon>
                  <span>{{ 'MAP.UPDATE_LOCATION_BY_CLICK' | translate }}</span>
                </button>
              </mat-menu>

              <span>{{ 'MAP.LOCATION_INFO' | translate }}</span>
            </div>
          </div>

          <div class="data-box-content" id="location-data-box-content">
            <div class="data-box-column">
                <div class="data-box-attribute-info">
                  <mat-icon>straighten</mat-icon>
                  <span>{{ 'MAP.DISTANCE' | translate }}: {{reports.selectedR.dados_localizacao[0]?.distancia_area}} km</span>
                </div>
              @for (i of reports.selectedR.dados_localizacao[0]?.dados_geo.atributos?.Local; track i.key) {
                <div class="data-box-attribute-info">
                  <mat-icon>map</mat-icon>
                  <span>{{i.key}}: {{i.value}}</span>
                </div>
              }
            </div>
            <div class="data-box-column">
              <div class="data-box-attribute-info">
                <mat-icon>
                  thermostat
                </mat-icon>
                <span>
                  {{ 'MAP.TEMPERATURE' | translate }}: {{reports.selectedR.dados_meteo?.[0]?.['temp'] |number:'.0-0'}}°
                </span>
              </div>
              <div class="data-box-attribute-info">
                <mat-icon>
                  water_drop
                </mat-icon>
                <span>
                  {{ 'MAP.HUMIDITY' | translate }}: {{reports.selectedR.dados_meteo?.[0]?.['humidity']}}%
                </span>
              </div>
              <div class="data-box-attribute-info">
                <mat-icon>
                  air
                </mat-icon>
                <span>
                  {{ 'MAP.WIND_SPEED' | translate }}: {{reports.selectedR.dados_meteo?.[0]?.['wind_speed']*3.6|number:'.0-0'}}km/h
                </span>
              </div>
              <div class="data-box-attribute-info">
                <mat-icon>
                  explore
                </mat-icon>
                <span>
                  {{ 'MAP.WIND_ORIGIN' | translate }}: 
                  {{ reports.getWindOriginIcon(reports.selectedR.dados_meteo?.[0]?.['wind_deg']).origin}}
                  <mat-icon class = "wind-icon">
                    {{ reports.getWindOriginIcon(reports.selectedR.dados_meteo?.[0]?.['wind_deg']).icon }}
                  </mat-icon>
                </span>
              </div>
              <div class="data-box-attribute-info">
                <mat-icon>
                  umbrella
                </mat-icon>
                <span>
                  {{ 'MAP.PRECIPITATION' | translate }}: {{reports.selectedR.dados_meteo?.[0]?.['rain']}}mm
                </span>
              </div>
              @if (reports.selectedR.dados_meteo?.[0]?.['fire_risk']){
                <div class="data-box-attribute-info">
                  <mat-icon>
                    warning
                  </mat-icon>
                  <span>
                    {{ 'MAP.FIRE_RISK' | translate }}: {{reports.selectedR.dados_meteo?.[0]?.['fire_risk']*100 |number:'.0-0'}}%
                  </span>
                </div>
              }
            </div>
          </div>
        </div>

        <div [ngClass]="user.darkMode() ? 'report-data-box-dark' : 'report-data-box'">
          <div class="data-box-header">
            <div class="header-title">
              <button [disabled]="user.isOperatorReadOnly()" color="warn" mat-mini-fab (click)="addAcionamento()" matTooltip="{{ 'MAP.ADD_BRIGADE_DRIVE' | translate }}">
                <mat-icon>fire_truck</mat-icon>
              </button>
              <span>
                {{ 'MAP.TRIGGERINGS' | translate }}
              </span>
            </div>
          </div>

          @if(reports.selectedR.acionamentos.length > 0) {
            <div class="data-box-content">
              <div class="triggering-table-wrapper">
                <table>
                  <tr id="table-headers" [ngClass]="reports.selectedR?.hasAcionamentoLayer ? 'table-headers': 'table-headers-no-acionamentolayer'">
                    @if (reports.selectedR?.hasAcionamentoLayer){
                      <th>{{ 'MAP.LAYER' | translate }}</th>
                    }
                    <th>{{ 'MAP.BRIGADE' | translate }}</th>
                    <th>{{ 'MAP.TRIGGERING' | translate }}</th>
                    <th>{{ 'MAP.FIRE_FIGHT' | translate }}</th>
                    <th>{{ 'MAP.AFTERMATH' | translate }}</th>
                  </tr>
                  @for (acionamento of reports.selectedR.acionamentos; track acionamento.id_acionamento){
                    <tr (click)="addAcionamento(acionamento)">
                      @if (reports.selectedR?.hasAcionamentoLayer){
                        <td (click)="$event.stopPropagation()" class="acionamento-layer">
                          @if (acionamento?.geojson_acionamento?.features[0]?.geometry){
                            <input type="checkbox" (click)='toggleAcionamentoLayer(acionamento.id_acionamento)'/>
                          }
                        </td>
                      }
                      <td>{{acionamento.nome_brigada}}</td>
                      <td>{{acionamento.dt_acionamento | date:'HH:mm'}} - {{acionamento.dt_chegada | date:'HH:mm'}}</td>
                      <td>{{acionamento.dt_inicio_combate | date:'HH:mm'}} - {{acionamento.dt_fim_combate | date:'HH:mm'}}</td>
                      <td>{{acionamento.dt_inicio_rescaldo | date:'HH:mm'}} - {{acionamento.dt_fim_rescaldo | date:'HH:mm'}}</td>
                      @if(this.user.isManager()){
                      <td> <mat-icon class="delete-triggering" (click)="$event.stopPropagation(); removeAcionamento(acionamento)">delete</mat-icon></td>
                    }
                    </tr>
                  }
                </table>
              </div>
            </div>
          }
        </div>

        <div [ngClass]="user.darkMode() ? 'report-data-box-dark' : 'report-data-box'">
          <div class="data-box-header">
            <div class="header-title">
              <button [disabled]="user.isOperatorReadOnly()" color="warn" mat-mini-fab (click)="addPerdas()" matTooltip="{{ 'MAP.REGISTER_DAMAGE' | translate }}">
                <mat-icon>trending_down</mat-icon>
              </button>
              <span>
                {{ 'MAP.DAMAGE' | translate }}
              </span>
            </div>
          </div>
          @if (reports.selectedR.dados_perdas) {
            <div class="data-box-content">
              <div class="damage-info">
                <div class="table-wrapper-damage">
                  <table>
                    <tr id="table-headers-damage">
                      <th>{{ 'MAP.TYPE' | translate }}</th>
                      <th>{{ 'MAP.VALUE' | translate }}</th>
                      <th>{{ 'MAP.UNIT' | translate }}</th>
                    </tr>
                    @for (damage of reports.selectedR.dados_perdas; track damage.tipo) {
                      <tr (click)="removeDamage(damage)">
                        <td>{{damage.tipo | titlecase}}</td>
                        <td>{{damage.valor}}</td>
                        <td>{{damage.unidade}}</td>
                      </tr>
                    }
                  </table>
                </div>
              </div>
            </div>
          }
        </div>

        <div [ngClass]="user.darkMode() ? 'report-data-box-dark' : 'report-data-box'">
          <div class="data-box-header">
            <div class="header-title">
              <button [disabled]="user.isOperatorReadOnly()" color="warn" mat-mini-fab (click)="addComments()" matTooltip="{{ 'MAP.REGISTER_COMMENTS' | translate }}">
                <mat-icon>comment</mat-icon>
              </button>
              <span>
                {{ 'MAP.COMMENTS' | translate }}
              </span>
            </div>
          </div>
          @if (reports.selectedR.comentarios) {
            <div class="data-box-content">
              <div class="comment-wrapper">
                {{reports.selectedR.comentarios}}
              </div>
            </div>
          }
        </div>

        <div class="toggles-wrapper">
          <div class="toggles">
            <mat-slide-toggle [disabled]="user.isOperatorReadOnly()" color="warn" [checked]="reports.finished" (change)="endReportChange($event.checked)">
              <span>{{ 'MAP.FINISHED_REPORT' | translate }}</span>
            </mat-slide-toggle>
            <mat-slide-toggle [disabled]="user.isOperatorReadOnly()" color="warn" [checked]="reports.selectedR.sem_risco" (change)="noRiskChange($event.checked)" style="margin-left: 1.5vw;">
              <span>{{ 'MAP.NO_RISK' | translate }}</span>
            </mat-slide-toggle>
            <mat-slide-toggle [disabled]="user.isOperatorReadOnly()" color="warn" [checked]="reports.selectedR.is_test" (change)="isTestChange($event.checked)" style="margin-left: 1.5vw;">
              <span>{{ 'MAP.TEST_REPORT' | translate }}</span>
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </section>
  }
</section>
