import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';

type CamerasTrap = {
  id_cam_trap: number,
  nome_cam_trap: string,
}

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.scss']
})
export class BioComponent implements OnInit {

  constructor(
    private http: HttpService,
  ) {}

  public camerasTrap: CamerasTrap[];
  public selectedCamId: number;
  public selectedCamImgUrlList: string[];
  public imgIndex: number = 0;
  public imgLoaded: boolean;

  async ngOnInit() {
    this.camerasTrap = await this.http.centralGet('get_cameras_trap', ['36']);
    this.selectedCamId = 1;
    this.selectedCamImgUrlList = await this.http.centralGet('get_cam_trap_imgs', ['36', `${this.selectedCamId}`]);
    this.imgLoaded = true;
  }

  async changeCamera(){
    this.imgLoaded = false;
    this.imgIndex = 0;
    this.selectedCamImgUrlList = await this.http.centralGet('get_cam_trap_imgs', ['36', `${this.selectedCamId}`]);
    this.imgLoaded = true;
  }

  nextImage(){
    this.imgIndex++
    if (this.imgIndex==this.selectedCamImgUrlList.length){
      this.imgIndex=0;
    }
  }

  previousImage(){
    this.imgIndex--
    if (this.imgIndex==-1){
      this.imgIndex=this.selectedCamImgUrlList.length-1;
    }
  }

}
