<mat-toolbar class="header">
  <div class="logo-container">
    <div class="negative-logo" matTooltip="{{version}}"></div>
  </div>

  <!-- nome do cliente quando usuário é ugm -->
  @if (user?.isUgm() && routeGuards.authorized | async) {
    <button mat-button [matMenuTriggerFor]="ugmClientsMenu">
      <div class="user-info">
      @if (![1,2,3,99].includes(user?.getIdCliente())) {
          {{user.planta.nome_cliente}} - 
        }
        {{user.planta.nome_planta}}
      </div>
      <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
    </button>
  }

  <!-- nome do cliente quando usuário não é ugm -->
  <div class="client-container">
    @if (!user.isUgm() && routeGuards.authorized | async) {
        <div class="client-name">
          @if (![1,2,3,5,28,37,99].includes(user?.getIdCliente())) {
            <div class="user-info">
              {{user.nome_cliente()}}
            </div>
          }
          @else {
            <div class="user-info">
              {{user.planta.nome_planta}}
            </div>
          }
        </div>
    }

    <mat-menu #ugmClientsMenu="matMenu">
      @for (client of clientMenu | keyvalue; track client.key){
        <button mat-menu-item [matMenuTriggerFor]="subMenu">{{ client.key }}</button>
        <mat-menu #subMenu="matMenu" class="menu">
          @for (planta of client.value; track planta.id_planta){
            <button mat-menu-item (click)='changePlanta(planta)'>
              <div class="fx-row-space-between-center ">
                <span style="padding-right:10px;">{{planta.nome_planta}}</span>
                <span>{{planta.id_planta}}</span>
              </div>
            </button>
          }
        </mat-menu>
      }
    </mat-menu>


    @if (user?.isManager2()) {
      <button mat-button [matMenuTriggerFor]="clientPlantasMenu">
        <!-- nome_planta  -->
        <span class="user-info">
          {{user.planta.nome_planta}}
        </span>
        <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
      </button>
    }
    <mat-menu #clientPlantasMenu="matMenu">
      @for (p of user.plantas_list; track p.nome_planta) {
        <button mat-menu-item (click)='changePlanta(p)'>
          <span [ngClass]="user.darkMode() ? 'user-info-dark' : ''">
            {{p.nome_planta}}
          </span>
        </button>
      }
    </mat-menu>
  </div>

  <div class="tipo-container-logo-cliente">
    <div class="logos-ratio">
      <img class='client-logo' [src]="user.logoUrlSubject | async" (error)="handleImageError($event)">
    </div>
    <div class="negative-tipo"></div>
  </div>
</mat-toolbar>
