import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { formatDate } from '@angular/common';
import { DatetimeValidators } from './reports-dialog.validator';
import { brigadeIconMap } from '../../services/brigades.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { BrigadesService } from '../../services/brigades.service';

export interface Brigade {
  type: string;
  typeIcon: string;
  name: string;
  category: string;
  filterString: string;
  idBrigada: string;
  plate: string;
  fireDrivingDistanceKm?: number;
  statusValue: number;
  statusIcon: string;
  lastUpdated?: Date;
  statusTooltip: string; 
}

@Component({
  selector: 'select-brigade-dialog',
  templateUrl: 'select-brigade-dialog.html',
})
export class SelectBrigadeDialog {

  public brigadeForm: UntypedFormGroup;
  brigadeCtrl = new UntypedFormControl('', [Validators.required]);
  filteredBrigades: Observable<Brigade[]>;

  public brigades: Brigade[] = [];

  public tipos_brigada:string[];
  public categorias_brigada:string[];
  public nomes_brigada: string[];

  combateChecked: boolean;
  rescaldoChecked: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public fb: UntypedFormBuilder,
    private dtValidators: DatetimeValidators,
    private amplitude: AmplitudeService,
    public brigadesService: BrigadesService,
  ) {
    
    this.combateChecked = false;
    this.rescaldoChecked = false;
    for (let brigade of data.brigadas ){
      const statusInfo = this.brigadesService.getBrigadeStatus(new Date(brigade['last_updated']));
      this.brigades.push({
        type: brigade['tipo_brigada'],
        lastUpdated: brigade['last_updated'] ? new Date(brigade['last_updated']) : null,
        typeIcon: brigadeIconMap[brigade['id_tipo_brigada']],
        name: brigade['nome_brigada'],
        category: brigade['categoria_brigada'],
        filterString: brigade['nome_brigada'] + brigade['placa'] + brigade['categoria_brigada'],
        idBrigada: brigade['id_brigada'],
        plate: brigade['placa'],
        fireDrivingDistanceKm: brigade['fireDrivingDistanceKm'],
        statusValue: statusInfo.statusValue,
        statusIcon: statusInfo.icon,
        statusTooltip: statusInfo.tooltip
      })
    }
      if (this.brigades.every((brigade) => brigade.fireDrivingDistanceKm === undefined && brigade.statusValue === 2)) {
        this.brigades.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.brigades.sort((a, b) => {
          const distA = a.fireDrivingDistanceKm !== undefined ? a.fireDrivingDistanceKm : Number.MAX_SAFE_INTEGER;
          const distB = b.fireDrivingDistanceKm !== undefined ? b.fireDrivingDistanceKm : Number.MAX_SAFE_INTEGER;
          const statusA = a.statusValue !== 2 ? a.statusValue : Number.MAX_SAFE_INTEGER;
          const statusB = b.statusValue !== 2 ? b.statusValue : Number.MAX_SAFE_INTEGER;
          if (statusA !== statusB) {
            return statusA - statusB;
          }
          if (distA !== distB) {
            return distA - distB;
          }
          return a.name.localeCompare(b.name);
        })
      }
    if (data.row === null){
      this.filterBrigades('');
    } else {
      this.filterBrigades(data.row['nome_brigada']);
    }

    let id_acionamento = `${data.id_report}_${data.len_acionamentos}`

    this.initForm(data.row, id_acionamento);
  }

  filterBrigades(nome_brigada) {
    this.filteredBrigades = this.brigadeCtrl.valueChanges.pipe(
      startWith(nome_brigada),
      map(brigade => (brigade ? this._filterBrigades(brigade) : this.brigades.slice())),
      );
    }
    
  private _filterBrigades(value: string): Brigade[] {
    const filterValue = value.toLowerCase();
    return this.brigades.filter(brigade => brigade.filterString.toLowerCase().includes(filterValue)).sort((a, b) => a.name.localeCompare(b.name));
  }
  
  initForm(row, id_acionamento){
    let datetime_now = formatDate(new Date(), 'yyyy-MM-ddTHH:mm', 'en-US');
    let dt_midnight = this.setMidnight();

    let dt_validators = [
      this.dtValidators.dtAcionamento(),
      this.dtValidators.dtCombate(),
      this.dtValidators.dtRescaldo(),
      this.dtValidators.dtChegadaCombate(),
      this.dtValidators.dtChegadaRescaldo(),
      this.dtValidators.dtFuturo(dt_midnight)
    ]
    
    if (row === null){
      this.brigadeForm = this.fb.group({
        nome_brigada: this.brigadeCtrl,
        contagem_equipe:['1',[Validators.required, Validators.min(1)]],
        dt_acionamento:[`${datetime_now}`,[Validators.required]],
        dt_chegada:[''],
        combate: false,
        dt_inicio_combate:[''],
        dt_fim_combate:[''],
        rescaldo: false,
        dt_inicio_rescaldo:[''],
        dt_fim_rescaldo:[''],
        id_acionamento:[id_acionamento],
        id_brigada: ['']
      },{
        validators: dt_validators,
      });
    } else {
      if (row['dt_inicio_combate'] !== null && row['dt_inicio_combate'] !== ''){
        this.combateChecked = true;
      };
      if (row['dt_inicio_rescaldo'] !== null && row['dt_inicio_rescaldo'] !== ''){
        this.rescaldoChecked = true;
      };
      this.brigadeForm = this.fb.group({
        nome_brigada: this.brigadeCtrl,
        contagem_equipe:[row['contagem_equipe'],[Validators.required, Validators.min(1)]],
        dt_acionamento:[row['dt_acionamento'],[Validators.required]],
        dt_chegada:[row['dt_chegada']],
        combate: this.combateChecked,
        dt_inicio_combate:[row['dt_inicio_combate']],
        dt_fim_combate:[row['dt_fim_combate']],
        rescaldo: this.rescaldoChecked,
        dt_inicio_rescaldo: [row['dt_inicio_rescaldo']],
        dt_fim_rescaldo: [row['dt_fim_rescaldo']],
        id_acionamento: [row['id_acionamento']],
        id_brigada: [row['id_brigada']]
      },{
        validators: dt_validators,
      });
      this.brigadeForm.patchValue({nome_brigada: row['nome_brigada']})
    }
  }

  setMidnight(){
    let midnight = new Date();
    midnight.setDate(midnight.getDate() + 1);
    midnight.setHours(0,0,0,0);
    let dt_midnight = formatDate(midnight, 'yyyy-MM-ddTHH:mm', 'en-US')
    return dt_midnight;
  }

  onCombateChange($event) {
    this.combateChecked = $event['checked'];
  }

  onRescaldoChange($event) {
    this.rescaldoChecked = $event['checked'];
  }
  
  onSubmit() {
    this.amplitude.sendEvent('Registrou Acionamento');
  }

}
