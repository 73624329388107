import { Component, OnInit, signal, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog'

import { HttpService } from '../../services/http.service';
import { UserDataService } from '../../services/user-data.service';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '../../services/amplitude.service';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-risk-alerts',
  templateUrl: './risk-alerts.component.html',
  styleUrls: ['./risk-alerts.component.scss']
})
export class RiskAlertsComponent implements OnInit {

  constructor(
    public user: UserDataService,
    private http: HttpService,
    private translate: TranslateService,
    private dialog: MatDialog,
    public amplitude: AmplitudeService,
  ) { }

  public loaded:boolean=false;

  public recipients: string[]
  public recipientsTableColumns: string[] = [
    'email'
  ]
  public newRecipient:string;
  public emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);
  public emailAlreadyRegistered = signal<boolean | undefined>(undefined)
  @ViewChild(MatSort) sort: MatSort;

  async ngOnInit() {
    await this.getRecipients();
    this.emailFormControl.valueChanges.subscribe((value: string) => {
      this.setEmailExists(value);
    });
    this.sortOnInit();
    this.loaded = true;
  }

  setEmailExists(email: string) {
    if (this.recipients.includes(email)) {
      this.emailAlreadyRegistered.set(true);
    } else {
      this.emailAlreadyRegistered.set(false);
    }
  }

  async getRecipients(){
    let response = await this.http.centralGet('get_risk_alert_recipients', [`${this.user.getIdPlanta()}`])
    console.log('risk recipients response', response);
    this.recipients = response?.recipients || []
  }

  rmRecipient(r){
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {text:`${this.translate.instant("MAP.DELETE_EMAIL_DIALOG")}`}
    })
    
    dialogRef.afterClosed().subscribe((confirmation) => {
      if (!confirmation) return
      this.recipients = this.recipients.filter(d => d!=r)
      this.updateRecipients()
      this.amplitude.sendEvent('Removeu Destinatário', {"Grupo": this.amplitude.groupName['risk'], "Tipo": "E-mail", "Destinatário": r});
    })
    this.emailFormControl.reset();
    this.emailAlreadyRegistered.set(false);
  }

  async updateRecipients(){
    if (!this.recipients.includes(this.newRecipient) && !!this.newRecipient){
      this.recipients.push(this.newRecipient);
      this.recipients = [...this.recipients]
      this.amplitude.sendEvent('Cadastrou Destinatário', {"Grupo": this.amplitude.groupName['risk'], "Tipo": "E-mail", "Destinatário": this.newRecipient});
      this.emailFormControl.reset()
    }

    let obj = {
      id_planta : this.user.getIdPlanta(),
      recipients_email : this.recipients
    }
    console.log('update_destinatarios', obj)
    let response = await this.http.maestroPost('update_risk_alert_recipients', obj)

    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.RECIPIENTS_UPDATED_DIALOG")}`}
      });
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR")} : ${response?.msg}`}
      });
    }
  }

  sortData(sort: Sort) {
    const data = this.recipients.slice();
    if (!sort.active || sort.direction === '') {
      this.recipients = data;
    } else {
      this.recipients = data.sort((a, b) => {
        return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }

  sortOnInit() {
    const defaultSort: Sort = { active: 'email', direction: 'asc' }; 
    this.sortData(defaultSort); 
  }

}
