import { Injectable, signal } from '@angular/core';
import PouchDB from 'pouchdb';
import { UserDataService } from '../../services/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class PouchDBService {

  constructor(
    public user: UserDataService
  ) {
    this.db = new PouchDB(this.user.getCouchDbIp());
    if(this.user.isOperatorP2() || this.user.isSupportP2()){this.initDatabase()};
  }

  // --------------------------------- Service class attributes ---------------------------------
  public db: PouchDB.Database;
  public changesFromDB: PouchDB.Core.Changes<{}>
  public oldImageAttachmentURL: string;
  public isBatchDeletingDetections = signal(false)
  

  // --------------------------------- Lifecycle methods ---------------------------------
  public initDatabase() {
    this.changesFromDB = this.db.changes(
      { 
        since: 'now', 
        live: true, 
        include_docs: true, 
        filter: this.onChangesOnDBDocumentsFilter
      })
      .on('error', (err) => {
      console.log('PouchDB error', err)
      setTimeout(() => {
        this.initDatabase();
      }, 1000);
    });

  }

  // --------------------------------- State change and getters methods ---------------------------------

  setIsBatchDeletingDetections(value: boolean){
    this.isBatchDeletingDetections.set(value)
  }


  // --------------------------------- DB calls methods ---------------------------------
  async fetchDocumentsOnDB(startKey: string, endKey: string) {
    return await this.db.allDocs({
      startkey: startKey,
      endkey: endKey,
      include_docs: true
    });
  }

  async fetchDocumentOnDBById(documentId: string) {
    const document = await this.db.get(documentId);
    return document as any;
  }

  async fetchImageAttachmentURLOnDBById(documentId: string) {
    if (this.oldImageAttachmentURL) {
      URL.revokeObjectURL(this.oldImageAttachmentURL);
    }
    const blob = await this.db.getAttachment(documentId, 'img') as Blob;
    const url_1 = URL.createObjectURL(blob);
    this.oldImageAttachmentURL = url_1;
    return url_1;
  }

  // add try catch
  async fetchImageAttachmentAsBlobOnDBById(documentId: string): Promise<Blob> {
    const blob = await this.db.getAttachment(documentId, 'img') as Blob;
    return blob;
  }

  async updateDocument(document: any) {
    return this.db.put(document)
  }

  async removeDocument(document: any) {
    this.db.remove(document)
  }

  async deleteDetectionAndImages(detectionDocumentId: string, imagesDocumentsIds: any[]) {
    this.db.get(detectionDocumentId).then((doc: any) => {
      this.removeDocument(doc)
    }).then(() => {
        imagesDocumentsIds.forEach((imageDocumentId: any) => {
          this.db.get(imageDocumentId).then((image: any) => {
            this.removeDocument(image)
          });
        });
      });
  }
  
  // --------------------------------- Utility methods ---------------------------------
  onChangesOnDBDocumentsFilter(doc: any) {
    return doc._id.startsWith('status_cam') || doc._id.startsWith('det_')  || doc._id.startsWith('user_prefs');
  }
}
