import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service'

@Component({
  selector: 'app-sysstatus',
  templateUrl: './sysstatus.component.html',
  styleUrls: ['./sysstatus.component.scss']
})
export class SysstatusComponent implements OnInit {
  public scriptLog;
  public scriptsLogTableColumns = [
    'script_name',
    'updated_day',
    'updated_time',
    'update_freq',
    'script_version',
    'script_location'
  ]

  public risk_layers
  public riskUpdatedTableColumns = [
    'id_planta',
    'layer',
    's3_updated',
    'geoserver2_updated'
  ]

  constructor(
    private http: HttpService,
  ) { }

  async ngOnInit(){
    this.update();
  }

  async update(){
    this.scriptLog = await this.http.centralGet('get_scripts_log');
    console.log(this.scriptLog, 'scriptLog')

    this.risk_layers = await this.http.centralGet('get_all_risk_layers');
    console.log(this.risk_layers);
    this.risk_layers.sort((l1, l2) => l1.id_planta - l2.id_planta);

  }

  checkDate(date){
    let now = new Date()
    let alert = (now.getTime() - new Date(date).getTime()) / (1000 * 3600 * 24 ) > 1
    if (alert){
      return 'red'
    }
    else {
      return ''
    }
  }

}
