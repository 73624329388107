import { Component, OnInit, OnDestroy } from '@angular/core';

import { ReportsService } from '../../services/reports.service';
import { UserDataService } from '../../services/user-data.service';
import { HttpService } from '../../services/http.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { PropagationService } from '../../services/propagation.service';
import { BrigadesService } from 'src/app/services/brigades.service';

import { addTextDialog, SelectPerdasDialog, LatLonDialog } from 'src/app/reports-components/reports-dialog/reports-dialog';
import { SelectBrigadeDialog } from 'src/app/reports-components/reports-dialog/select-brigade-dialog';
import { DownloadReportDialog } from 'src/app/reports-components/download-report/download-report-dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";

import { AddImagesDialog } from '../../shared/dialogs/add-img-dialog/add-img-dialog'
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GeoService } from 'src/app/services/geo.service';
import {formatDate} from '@angular/common';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '../../services/logging.service';
import { firstValueFrom } from 'rxjs';
import { SocketService } from '../../interface/services/socket.service';
import { CamerasService } from 'src/app/interface/services/cameras.service';
import { FilterService } from 'src/app/services/filter.service';

const MS_TO_KMH = 3.6;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  constructor(
    public reports: ReportsService,
    public user: UserDataService,
    public prop: PropagationService,
    private dialog: MatDialog,
    private http: HttpService,
    private geo: GeoService,
    private amplitude: AmplitudeService,
    public translate: TranslateService,
    private brigades: BrigadesService,
    public logging: LoggingService,
    public socketCIG: SocketService,
    public camerasService: CamerasService,
    public filterService: FilterService,
    ) { }

  public acionamentoColumns = [
    'brigada',
    'acionamento',
    'combate',
    'rescaldo'
  ]

  public causas;
  public origens;
  public causasTranslated = {};
  public origensTranslated = {};

  public acionamentoLayerActive: { [key: string]: { layer: any, active: boolean } } = {};
  public damage;

  async translateCausas() {
    for (let causa of this.causas) {
      const traducao = `FC.${causa.id_causa_incendio}`;
      this.causasTranslated[causa.id_causa_incendio] = await firstValueFrom(this.translate.get(traducao));
    }
  }

  async translateOrigem() {
    for (let origem of this.origens) {
      const traducao = `OD.${origem.id_origem_deteccao}`;
      this.origensTranslated[origem.id_origem_deteccao] = await firstValueFrom(this.translate.get(traducao));
    }
  }
    
  ngOnInit() {
    this.causas = this.user.getDadosPantera('report_params').causas_incendio;
    this.origens = this.user.getDadosPantera('report_params').origens_deteccao;
    this.damage = this.user.getDadosPantera('report_params').danos;
    this.translateCausas();
    this.translateOrigem();  
  }

  nextReport(){
    this.removeAllAcionamentoLayers();
    this.reports.goToReport(-1);
  }

  previousReport(){
    this.removeAllAcionamentoLayers();
    this.reports.goToReport(1);
  }

  unselectReport(){
    try{
      this.geo.closePopup();
    }
    catch(e){
      this.logging.logERROR(`unselectReport:closePopup ${e}`,e);
    }
    this.removeAllAcionamentoLayers();
    this.reports.unselectReport();
  }

  openAddImagesDialog(){
    this.dialog.open(AddImagesDialog)
  }

  async addAcionamento(row=null){
    let brigadas = await this.http.centralGet('get_brigadas', [`${this.user.getIdPlanta()}`])

    if (!brigadas){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.NO_BRIGADES_REGISTERED")}`}
      });
    }

    else {

      if (this.user.getDadosPantera('pantera_features').brigades_position){
        brigadas = await this.brigades.calcBrigadesFireDistance(brigadas, this.reports.selectedR.dados_localizacao[0].lat, this.reports.selectedR.dados_localizacao[0].lon);
      };

      let dialogConfig: MatDialogConfig = {
        data: {
          row: row,
          brigadas : brigadas,
          id_report : this.reports.selectedR.id_report,
          len_acionamentos : this.reports.selectedR.acionamentos.length,
        },
        panelClass: 'dialog-responsive',
        width: '520px',
        maxWidth: '100vw',
        autoFocus: false
      }
      const dialogRef = this.dialog.open(SelectBrigadeDialog , dialogConfig);

    dialogRef.afterClosed().subscribe(async(acionamento) => {
      if (acionamento){
        for (let b of brigadas){
          if (acionamento['nome_brigada'] === b['nome_brigada']){
            acionamento['id_brigada'] = b['id_brigada']
          } 
        }
        acionamento['id_report'] = this.reports.selectedR.id_report;
        
        for (let c of ['combate', 'rescaldo']){
          if (!acionamento[c]){
            acionamento[`dt_inicio_${c}`] = '';
            acionamento[`dt_fim_${c}`] = '';
          }
        }
        
        if (row === null){
          this.reports.selectedR.acionamentos.push(acionamento);
          this.reports.selectedR.acionamentos.sort((a, b) => a.dt_acionamento.localeCompare(b.dt_acionamento));
        } else {
          let rowIndex = this.reports.selectedR.acionamentos.indexOf(row);
          this.reports.selectedR.acionamentos[rowIndex] = acionamento;
        }
        this.reports.selectedR.acionamentos = [...this.reports.selectedR.acionamentos]

        this.amplitude.sendEvent("Registrou Acionamento", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "ID Brigada": acionamento.id_brigada, "Nome Brigada": acionamento.nome_brigada })
        let response = await this.http.maestroPost('upsert_acionamento', acionamento)
        console.log('addAcionamento', response, acionamento);
        this.filterService.updateFilters()
      }
    });
    
  }
}

removeAcionamento(row) {
  const dialogRef = this.dialog.open(ConfirmationDialog, {
    data: { text: this.translate.instant("MAP.DELETE_TRIGGERING") }
  });

  dialogRef.afterClosed().subscribe(async (confirmation) => {
    if (confirmation) {
      const acionamento = this.reports.selectedR.acionamentos.find(p => p === row);

      if (acionamento) {
        this.reports.selectedR.acionamentos = this.reports.selectedR.acionamentos.filter(p => p !== row);
        await this.http.maestroPost('remove_acionamento', { id_acionamento: acionamento.id_acionamento });
        console.log("Acionamento excluído")
      } else {
        console.error("Acionamento não encontrado");
      }
    }
  });
}

addPerdas() {
  const translatedDamageType = this.damage.tipos.map(type => this.translate.instant(`DMG.${type.id_tipo_dano}`)).sort();
      const dialogRef = this.dialog.open(SelectPerdasDialog , {
      data: {
        tiposPerdas: translatedDamageType,
        unidades: [
          {name: this.translate.instant('MAP.HECTARES'), unit: 'ha'},
          {name: this.translate.instant('MAP.TONS'), unit: 't'},
      ]
    }});

  dialogRef.afterClosed().subscribe(async(perdas) => {
    if (perdas){
      this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Danos" })
      this.reports.selectedR.dados_perdas = this.reports.selectedR.dados_perdas || []
      this.reports.selectedR.dados_perdas.push(perdas)
      this.reports.updateMaestro('dados_perdas')
    }
  });
}

  async addComments() {
    let data = {
      text: this.translate.instant("MAP.COMMENTS"),
      comentarios: this.reports.selectedR.comentarios || ""
    }

    const dialogRef = this.dialog.open(addTextDialog , {
      data: data
    });

    dialogRef.afterClosed().subscribe(async(comments) => {
      if ((typeof(comments) == "boolean") && (comments == false)) { } // comments == false -> quando aperta em cancelar
      else if ((typeof(comments) == "object") && (comments == null)) { // comments == null -> quando confirma o texto vazio
        this.reports.selectedR.comentarios = "";
        this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Comentários" })
        await this.reports.updateMaestro('comentarios')
      }
      else if (typeof(comments) == "string") {
        this.reports.selectedR.comentarios = comments;
        this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Comentários" })
        await this.reports.updateMaestro('comentarios')
      }
    });
  }

  removeDamage(row) {
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: { text: this.translate.instant("MAP.DELETE_DAMAGE") }
    });

    dialogRef.afterClosed().subscribe(async (confirmation) => {
      if (confirmation){
        let perdas = this.reports.selectedR.dados_perdas.filter( p => p != row)
        this.reports.selectedR.dados_perdas = perdas
        await this.reports.updateMaestro('dados_perdas')
      }
    });
  }

  async toggleRisk (ob: MatSlideToggleChange) {
    this.reports.selectedR.sem_risco = ob.checked
    await this.reports.updateMaestro('sem_risco')
  }

  async toggleIsTest (ob: MatSlideToggleChange) {
    this.reports.selectedR.is_test = ob.checked
    await this.reports.updateMaestro('is_test')
  }

  async changeLocationByCoords(){
    let bounds = await this.geo.fitClientBounds()

    const dialogRef = this.dialog.open(LatLonDialog , {
      data: { bounds }
    });

    dialogRef.afterClosed().subscribe(async(latlng) => {
      if (latlng){
        this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Localização" })

        let dados_loc = {
          lat: latlng.lat,
          lon: latlng.lng
        }
        let data = {
          'id_report':this.reports.selectedR.id_report,
          'columns':['dados_localizacao', 'id_planta', 'dados_meteo'],
          'values': [[dados_loc], this.reports.selectedR['id_planta'], this.reports.selectedR['dados_meteo']],
        }
        
        let response = await this.http.maestroPost('update_report_loc', data)
        this.reports.selectedR.dados_localizacao[0] = response.values[1][0]
        this.reports.selectedR.dados_meteo[0] = response.values[2][0]

        await this.updateDrawReports();
        this.geo.centerOnObject({ lat: dados_loc.lat, lng: dados_loc.lon })
      }
    });
  }

  changeLocationByClick() {
    this.geo.updateWaitingClickOnMapToSetFireLocationStatus(true)
    this.geo.setUpdatingLocationMarkerOnMap(true);
    this.reports.changeCursor('crosshair');
}

  openDownloadDialog(){
    let origin = undefined;
    let cause = undefined;

    if (this.reports.selectedR.id_origem_deteccao) {
      origin = this.origens.filter(origin => {
        return origin.id_origem_deteccao == this.reports.selectedR.id_origem_deteccao
      })[0]
      if (origin) origin = origin.origem_deteccao
    }

    if (this.reports.selectedR.id_causa_incendio) {
      cause = this.causas.filter(cause => {
        return cause.id_causa_incendio == this.reports.selectedR.id_causa_incendio
      })[0]

      if (cause) cause = cause.causa_incendio
    }

    this.dialog.open(DownloadReportDialog, {
      data: {
        origin: origin,
        cause: cause
      }
    })
  }

  sendReportViaTelegram() {
    this.reports.sendReportViaTelegram()
  }

  sendReportViaWhatsapp() {
    this.reports.sendReportViaWhatsApp()
  }

  deleteReport() {
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: {text:`${this.translate.instant("MAP.DELETE_REPORT")}`}
    });

    dialogRef.afterClosed().subscribe(async(confirmation) => {
      if (confirmation){
        try {
          let response = await this.http.centralGet(`/delete_report_recorded/${this.reports.selectedR.id_planta}/${this.reports.selectedR.id_report}`)
          console.log("...delete_report_recorded", response)
          this.reports.reports_list = this.reports.reports_list.filter(r => r.id_report != this.reports.selectedR.id_report)
          this.reports.unselectReport()
          this.filterService.updateFilters();

          try{
            this.geo.closePopup();
          }
          catch(e){
            this.logging.logERROR(`unselectReport:closePopup ${e}`,e);
          }
        }
        catch (err) {
          console.log("Erro ao deletar o relatorio ", err);
          this.logging.logERROR(`deleteReport ${err}`,err);
        }
      }
    })
  }

  async updateDrawReports(){
    await this.reports.updateReports();
    this.geo.drawFireReports(this.reports.reports_list);
  }

  async endReportChange(checked:boolean){
    this.reports.finished = checked;
    if (checked){
      this.amplitude.sendEvent("Finalizou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta });
      this.reports.selectedR.datetime_finalizado = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US');
    } else {
      this.amplitude.sendEvent("Desmarcou Relatório Finalizado", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta });
      this.reports.selectedR.datetime_finalizado = null;
    }
    try {
      let response = await this.reports.updateMaestro('datetime_finalizado');
      console.log('endReportChange', this.reports.selectedR);
    }
    catch (err) {
      console.log("Erro:", err)
    }
    await this.reports.updateReports();
    this.filterService.updateFilters()
  }

  async noRiskChange(checked:boolean){
    this.reports.selectedR.sem_risco = checked;

    try {
      this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Risco" })
      let response = await this.reports.updateMaestro('sem_risco');
      console.log('noRiskChange', this.reports.selectedR);
    }
    catch (err) {
      console.log("Erro:", err);
      this.logging.logERROR(`noRiskChange ${err}`,err);
    }
    await this.reports.updateReports();
    
    this.filterService.updateFilters()
    
  }

  async isTestChange(checked:boolean){
    this.reports.selectedR.is_test = checked;

    try {
      this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Teste" })
      let response = await this.reports.updateMaestro('is_test');
      console.log('isTestChange', this.reports.selectedR);
    }
    catch (err) {
      console.log("Erro:", err);
      this.logging.logERROR(`isTestChange ${err}`,err);
    }
    await this.reports.updateReports();
    
  }

  toggleAcionamentoLayer(idAcionamento: string){
    let acionamento = this.reports.selectedR.acionamentos.find((acionamento) => acionamento.id_acionamento === idAcionamento);
    this.acionamentoLayerActive[idAcionamento] = this.acionamentoLayerActive[idAcionamento] || { layer: undefined, active: false };
    this.acionamentoLayerActive[idAcionamento].active = !this.acionamentoLayerActive[idAcionamento].active;
    
    if (acionamento?.geojson_acionamento?.features[0]?.geometry){
      const idTipoBrigada = acionamento.id_tipo_brigada ? acionamento.id_tipo_brigada : 3; // default pickup
      if (this.acionamentoLayerActive[idAcionamento].active){
        this.acionamentoLayerActive[idAcionamento].layer = this.geo.addAcionamentoGeojsonLayer(acionamento.geojson_acionamento.features, idTipoBrigada);
      } else {
        this.geo.removeLayer(this.acionamentoLayerActive[idAcionamento].layer);
      };
    };
  }

  removeAllAcionamentoLayers(){
    if (this.reports.selectedR?.acionamentos?.length){
      for (let acionamento of this.reports.selectedR.acionamentos){
        let idAcionamento = acionamento.id_acionamento;
        if (this.acionamentoLayerActive[idAcionamento]?.active){
          this.acionamentoLayerActive[idAcionamento].active = false;
          this.geo.removeLayer(this.acionamentoLayerActive[idAcionamento].layer);
        }
      }
    }
  }


  launchPropagation(){
    this.user.currentPropagationParams.next({
      windSpeedKmh: Number((this.reports.selectedR.dados_meteo[0]['wind_speed']*MS_TO_KMH).toFixed(0)),
      windDeg: this.reports.selectedR.dados_meteo[0]['wind_deg'],
      tSimulationMin: 60,
      lat: Number(Number(this.reports.selectedR.dados_localizacao[0]['lat']).toFixed(4)),
      lon: Number(Number(this.reports.selectedR.dados_localizacao[0]['lon']).toFixed(4)),
    });
    this.prop.simulationFormSubmitted = false;
    this.user.showPropagationWidget = true;
  }

  pointCameraToReportImagePTZ() {
    if (!this.user.isOperatorP2() || !this.user.isSupportP2()) {
      this.dialog.open(InfoDialog, {
        data: { text: this.translate.instant("MAP.ONLY_CENTRAL_OPERATORS") }
      })
      return
    }

    let cameraId = this.reports.imgs[this.reports.selectedReportImageIndex()].img_details.id_cam
    const { pan, tilt, zoom } = this.reports.imgs[this.reports.selectedReportImageIndex()].img_details

    if (!cameraId) {
      const imagePath = this.reports.imgs[this.reports.selectedReportImageIndex()].img_path
      cameraId = this.getCameraIdFromImagePath(imagePath)
    }

    this.camerasService.absolutePTZMovement(cameraId, pan, tilt, zoom).subscribe(response => {
        console.log(response)
    })
  }

  getCameraIdFromImagePath(imagePath: string) {
    const splitted = imagePath.split('/')
    const imageName = splitted[splitted.length - 1]
    const cameraId = imageName.split('_')[0]
    return cameraId
  }

  updateDetectionOrigin() {
    this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Origem" })
    this.reports.updateMaestro('id_origem_deteccao')
  }

  updateFireCause() {
    this.amplitude.sendEvent("Editou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Campo Editado": "Causa" })
    this.reports.updateMaestro('id_causa_incendio')
  }

  ngOnDestroy() {
    this.removeAllAcionamentoLayers();
  }
}