<div class="prop-widget" [class.side-panel-opened]="user.sidePanelOpened" cdkDrag cdkDragBoundary=".main-map">
  <button mat-icon-button [ngClass]="user.darkMode() ? 'close-btn-dark' : 'close-btn'" (click)="closeWidget()">
    <mat-icon class="cancel-button">close</mat-icon>
  </button>
  @if (!prop.simulationFormSubmitted) {
    <mat-card appearance="outlined">
      <mat-card-title [ngClass]="user.darkMode() ? 'simulation-title-dark' : 'simulation-title'">{{ 'MAP.SIMULATE_PROPAGATION' | translate }}</mat-card-title>
      <form [formGroup]="propSimulationForm" (ngSubmit)="onSubmit()">
        <div class="fx-column-space-around-center">
          <mat-form-field appearance="outline" color='primary'>
            <mat-label>{{ 'MAP.WIND_SPEED' | translate }} (km/h)</mat-label>
            <input matInput type="number" formControlName="windSpeedKmh" placeholder="Ex: 8"/>
          </mat-form-field>
          <mat-form-field appearance="outline" color='primary'>
            <mat-label>{{ 'MAP.WIND_DIRECTION_DEGREES' | translate }}</mat-label>
            <input matInput type="number" formControlName="windDeg" placeholder="Ex: 138"/>
          </mat-form-field>
          <mat-form-field appearance="outline" color='primary'>
            <mat-label>{{ 'MAP.SIMULATION_TIME' | translate }}</mat-label>
            <input matInput type="number" formControlName="tSimulationMin" placeholder="Ex: 30"/>
          </mat-form-field>
          <mat-form-field appearance="outline" color='primary'>
            <mat-label>{{ 'MAP.LATITUDE' | translate }}</mat-label>
            <input matInput type="number" formControlName="lat"/>
          </mat-form-field>
          <mat-form-field appearance="outline" color='primary'>
            <mat-label>{{ 'MAP.LONGITUDE' | translate }}</mat-label>
            <input matInput type="number" formControlName="lon"/>
          </mat-form-field>
        </div>
        <div class="fx-row-space-around-center">
          <button mat-raised-button color='primary' type="submit" [disabled]="!propSimulationForm.valid">
            {{ 'MAP.SIMULATE' | translate }}
          </button>
        </div>
      </form>
    </mat-card>
  }
  @if (prop.simulationFormSubmitted && prop.simulating) {
    <mat-card [ngClass]="user.darkMode() ? 'simulating-card-dark' : 'simulating-card'"class="simulating-card" appearance="outlined">
      <mat-card-title>{{ 'MAP.SIMULATION_PROGRESS' | translate }}</mat-card-title>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
  }
  @if (prop.simulationFormSubmitted && !prop.simulating) {
    <mat-card appearance="outlined">
      <mat-card-title [ngClass]="user.darkMode() ? 'legend-card-title-dark' : 'legend-card-title'">{{ 'MAP.PROPAGATION_TIME' | translate }}</mat-card-title>
      @for (propLayer of propLayersLegend; track propLayer.legend) {
        <div [ngClass]="user.darkMode() ? 'prop-legend-dark' : 'prop-legend'">
          <div class="fx-row-center">
            <div class="legend-square" [style.backgroundColor]="propLayer.color + '1A'" [style.borderColor]="propLayer.color"></div>
            <div>{{propLayer.legend}}</div>
          </div>
        </div>
      }
      <button mat-raised-button color="primary" (click)="newSimulation()" class="new-sim-btn">
        {{ 'MAP.NEW_SIMULATION' | translate }}
      </button>
    </mat-card>
  }
</div>