import { Injectable, signal, computed } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from '../services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { UserTermsDialog } from '../shared/dialogs/user-terms-dialog/user-terms-dialog';
import { PropagationParams } from './propagation.service';
import { BehaviorSubject,lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


const TOKEN_KEY = 'CRYPT_USER_DATA';
export const IS_OPERATOR_OR_SUPPORT_P2_KEY = 'IS_OPERATOR_OR_SUPPORT_P2'
const MAESTRO_TOKEN_KEY = 'MAESTRO'; // cuidado getter no http service
const SOCKET_C_TOKEN_KEY = 'SOCKET_CENTRAL';
const LANGUAGE_KEY = 'LANGUAGE';
const MAPBIOMAS_TOKEN_KEY = 'MAPBIOMAS_TOKEN';
const AUDIO_STATE_KEY = 'AUDIO_STATE';
const DRAW_AUTO_GEOLOC_ELLIPSE_KEY = 'AUTO_GEOLOC_ELLIPSE';

const ADMIN_ID_ROLE = 0;
const INTERNAL_ID_ROLE = 1;
const EXTERNAL_ID_ROLE = 2;
const DEV_ID_ROLE = 3;

const READ_ONLY_ID_ROLE = 20;    // operador read-only
const OPERATOR_2_ID_ROLE = 21;    // operador read-write

const MANAGER_1_ID_ROLE = 22;     // gestor planta especifica
const MANAGER_2_ID_ROLE = 23;     // gestor de todas as plantas cluster

export const OPERADOR_PANTERA_2 = 11;    // operador read-write que também tem acesso a interface das câmeras
export const ASSISTENCIA_UGM_PANTERA_2 = 12;

const ADMIN_ID_ROLE_LIST = [ADMIN_ID_ROLE, DEV_ID_ROLE]
const UGM_ID_ROLE_LIST = [ADMIN_ID_ROLE, INTERNAL_ID_ROLE, DEV_ID_ROLE, ASSISTENCIA_UGM_PANTERA_2];
const MANAGER_ID_ROLE_LIST = [MANAGER_1_ID_ROLE, MANAGER_2_ID_ROLE, DEV_ID_ROLE];

const DEFAULT_MIN_NUMBER_OF_REDETECTIONS_TO_ALERT = 1;

export interface planta {
  id_planta: number;
  nome_planta: string;
  nome_cliente: string;
}

interface UserPrefs {
  _id: string,
  _rev: string,
  data: {
    telegramStatus: boolean,
    minNumberRedetections: number,
  }
}

const defaultUserPrefs: UserPrefs = {
  _id: 'default_id',
  _rev: 'default_rev',
  data: {
    telegramStatus: false,
    minNumberRedetections: DEFAULT_MIN_NUMBER_OF_REDETECTIONS_TO_ALERT,
  },
};

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private http: HttpService,
    public HttpClient: HttpClient,
  ) {}

  private user_id = signal<undefined | string>(undefined);
  private id_planta = signal<undefined | number>(undefined);
  private id_role = signal<undefined | number>(undefined); 
  public username = signal<undefined | string>(undefined);
  public email = signal<undefined | string>(undefined);
  public nome_cliente = signal<undefined | string>(undefined);
  public nome: string;
  public sobrenome: string;
  public logo: string;
  public last_access: Date;
  private terms_accepted: boolean;
  public darkMode = signal<undefined | boolean>(undefined);
  public drawAutoGeolocEllipse = signal<boolean>(true);

  public showPropagationWidget = false;
  public sidePanelOpened = false;
  public currentPropagationParams= new BehaviorSubject<PropagationParams>({windSpeedKmh: 0, windDeg: 0, tSimulationMin: 0, lat: 0, lon: 0});
  
  // private pantera_features: any;
  private pantera_data: any;

  public plantas;
  public plantas_list: any[];
  
  public planta:planta;
  private otherPlanta:planta;
  public logoUrlSubject = new BehaviorSubject<string>('');

  public userPrefsStatus = signal<UserPrefs>(defaultUserPrefs);
  public audioState = signal<boolean>(true);

  // IS
  public isAdmin = computed(() => ADMIN_ID_ROLE_LIST.includes(this.id_role()));
  public isUgm = computed(() => UGM_ID_ROLE_LIST.includes(this.id_role()));
  public isManager = computed(() => MANAGER_ID_ROLE_LIST.includes(this.id_role()));
  public isDev = computed(() => this.id_role() == DEV_ID_ROLE);
  public isOperator = computed(() => [OPERATOR_2_ID_ROLE].includes(this.id_role()));
  public isOperatorP2 = computed(() => this.id_role() == OPERADOR_PANTERA_2);
  public isOperatorReadOnly = computed(() => [READ_ONLY_ID_ROLE].includes(this.id_role()));
  public isManager2 = computed(() => this.id_role() == MANAGER_2_ID_ROLE);
  public isExternal = computed(() => this.id_role() == EXTERNAL_ID_ROLE);
  public isSupportP2 = computed(() => this.id_role() == ASSISTENCIA_UGM_PANTERA_2);

  // GET
  public getIdPlanta = computed(() => this.id_planta());
  public getUserId = computed(() => this.user_id());
  public getUsername = computed(() => this.username());
  public getEmail = computed(() => this.email());

  // HAS
  public hasReportCreation = computed(() => {
    const rolesList = [
        DEV_ID_ROLE,
        OPERATOR_2_ID_ROLE,
        MANAGER_1_ID_ROLE,
        MANAGER_2_ID_ROLE,
        OPERADOR_PANTERA_2,
        ASSISTENCIA_UGM_PANTERA_2,
      ]
    return this.hasFireManagement() && rolesList.includes(this.id_role())
  })

  public hasFireManagement = computed(()=>{
    return this.getDadosPantera('pantera_features').fire_mngmt
  })

  //SET
  setIdRole(id_role: number){
    this.id_role.set(id_role)  
  }
  setDarkMode(value: boolean){
    this.darkMode.set(value)
  }

  setIdPlanta(id_planta: number){
    this.id_planta.set(id_planta)
  }

  setUserId(id: string){
    this.user_id.set(id)
  }

  setUserName(name: string){
    this.username.set(name)
  }

  setUserEmail(email: string){
    this.email.set(email)
  }

  setNomeCliente(nome_cliente: string){
    this.nome_cliente.set(nome_cliente)
  }

  // SAVE AND GET TOKEN FROM LOCALSTORAGE
  saveToken(token){
    localStorage.setItem(TOKEN_KEY, token);
  }

  getToken(){
    return localStorage.getItem(TOKEN_KEY);
  }
  
  saveMaestroToken(token){
    localStorage.setItem(MAESTRO_TOKEN_KEY, token);
  }

  saveSocketCentralToken(token){
    localStorage.setItem(SOCKET_C_TOKEN_KEY, token);
  }
  
  getSocketCentralToken(){
    return localStorage.getItem(SOCKET_C_TOKEN_KEY);
  }

  saveLang(lang){
    localStorage.setItem(LANGUAGE_KEY, lang);
  }

  getLang(){
    if (this.isIndia()){
      return 'en'
    }
    return localStorage.getItem(LANGUAGE_KEY) || 'pt-br'
  }

  signOut() {
    const keys = [TOKEN_KEY, MAESTRO_TOKEN_KEY, SOCKET_C_TOKEN_KEY, IS_OPERATOR_OR_SUPPORT_P2_KEY];
    for (let key of keys) {
      localStorage.removeItem(key);
    }
  }

  saveMapbiomasToken(token: string){
    localStorage.setItem(MAPBIOMAS_TOKEN_KEY, token)
  }

  getMapbiomasToken() {
    const token = localStorage.getItem(MAPBIOMAS_TOKEN_KEY);
    return token ? token : "token";
  }

  async setData(authUserData){
    
    this.setUserId(authUserData.id);
    this.setIdRole(authUserData.id_role);
    this.setUserName(authUserData.username);
    this.setUserEmail(authUserData.email);
    this.setNomeCliente(authUserData.nome_cliente);
    this.nome = authUserData.nome;
    this.sobrenome = authUserData.sobrenome;
    this.last_access = new Date(authUserData.last_access);
    this.terms_accepted = ((authUserData.dt_terms_accepted === null) ? false : true);

    console.log('👤 last_access', this.last_access);
    
    await this.setPlanta(authUserData);

    this.plantas_list = JSON.parse(authUserData.plantas_list);

    this.translate.use(this.getLang())
  }

  async setPlanta(user_data){
    if (this.otherPlanta){
      this.planta = this.otherPlanta
      this.setIdPlanta(this.otherPlanta.id_planta);
    } else {
      this.planta = {
        id_planta : user_data.id_planta,
        nome_planta : user_data.nome_planta,
        nome_cliente : user_data.nome_cliente
      };
      this.setIdPlanta(user_data.id_planta);
    }
    this.logoUrlSubject.next(`${this.http.getServerUrl()}/get_logo/${this.id_planta()}`)

    await this.updatePanteraData()
  }

  async updatePanteraData(){
    this.pantera_data = await this.http.centralGet('dados_pantera_planta', [`${this.getIdPlanta()}`]);
  }

  getTelegramGroups() {
    return this.pantera_data.telegram_groups;
  }

  getTalhaoLegend() {
    return this.pantera_data.talhao_legenda;
  }

  getTalhaoZonaLegend() {
    return this.pantera_data.talhao_zona_legenda;
  }

  hasTalhaoRoi() {
    return this.pantera_data.talhao_roi;
  }

  hasTalhaoLinhaEstrada() {
    return this.pantera_data.talhao_linha_estrada;
  }

  hasTalhaoLinhaHidrografia() {
    return this.pantera_data.talhao_linha_hidrografia;
  }

  hasTalhaoLinhaEnergia() {
    return this.pantera_data.talhao_linha_energia;
  }  

  hasHotspotRoi() {
    return this.pantera_data.hotspot_roi;
  }

  hasUnidadeConservacao() {
    return this.pantera_data.unidade_conservacao;
  }

  hasTerraIndigena() {
    return this.pantera_data.terra_indigena;
  }


  overwritePlanta(planta){
    this.otherPlanta = planta;
  }

  async setManagerPlanta(authUserData, idPlanta){
    
    let id_role = authUserData.id_role
    let plantas_list = JSON.parse(authUserData.plantas_list);

    if (ADMIN_ID_ROLE_LIST.includes(id_role) || id_role==MANAGER_2_ID_ROLE){
      let plantas = await this.http.centralGet('get_plantas');
      let newPlanta = plantas.find(p => p.id_planta==idPlanta);
      
      if(newPlanta){
        if(ADMIN_ID_ROLE_LIST.includes(id_role)){
          console.log('Admin changed planta')
          this.overwritePlanta(newPlanta);
        }
        else
        {
          let plantas_list_id = plantas_list.map(p=>p.id_planta)
          if (plantas_list_id.includes(parseInt(idPlanta))){
            console.log('Manager2 changed planta')
            this.overwritePlanta(newPlanta);
          } else {
            console.log('Manager2 has not acess to planta', idPlanta)
          }
        }
        
      }
    }
    else {
      console.log('User cannot change planta')
    }

  }

  checkTerms(){
    if (!this.terms_accepted){
      this.dialog.open(UserTermsDialog, {
        data: { lang: this.getLang() }
      })
      this.http.maestroGet('accept_user_terms', this.getToken())
    }
  }




  getIdCliente(){
    return this.pantera_data['id_cliente']
  }

  getIdRole(){
    return this.id_role()
  }


  getLogoUrl(){
    return `${this.http.getServerUrl()}/get_logo/${this.getIdPlanta()}`;
  }

  async getB64Logo(){
    let base_64 = await this.http.centralGet(`get_b64_logo/${this.getIdPlanta()}`);
    return `data:image/png;base64,${base_64}`;
  }

  // cuidado se o dado não for uma lista
  getDadosPantera(key){
    return this.pantera_data[key] || []
  }

  getUserRole() {
    const roles = {
      [ADMIN_ID_ROLE]: 'Admin UGM',
      [INTERNAL_ID_ROLE]: 'Usuário UGM',
      [EXTERNAL_ID_ROLE]: 'Terceirizado UGM',
      [DEV_ID_ROLE]: 'Desenvolvedor UGM',
      [READ_ONLY_ID_ROLE]: 'Operador nível 1',
      [OPERATOR_2_ID_ROLE]: 'Operador nível 2',
      [OPERADOR_PANTERA_2]: 'Operador Pantera 2',
      [MANAGER_1_ID_ROLE]: 'Gestor nível 1',
      [MANAGER_2_ID_ROLE]: 'Gestor nível 2'
    };
    return roles[this.id_role()] || 'Desconhecido';
  }

  
  isUgmAmplitude(){
    const ugmAndExternalRoles = UGM_ID_ROLE_LIST.concat(EXTERNAL_ID_ROLE)
    return ugmAndExternalRoles.includes(this.id_role()) || this.email().includes('umgrauemeio')
  }

  is999(){
    return this.id_planta() == 999
  }

  // HAS

  hasSatellite(){
    return this.getDadosPantera('pantera_features').satellite && !this.isExternal()
  }

  hasCamDetection(){
    return this.getDadosPantera('pantera_features').cam_detection
  }

  isIndia(){
    return ([64, 65].includes(this.id_planta()))
  }

  getDeployParams(){
    return this.getDadosPantera('deploy_params')[0]
  } 

  isHTTPS(){
    const currentUrl = window.location.href;
    const isSecure = currentUrl.startsWith('https://');
    // return this.getDeployParams().deploy_type == 'wan';
    return isSecure;
  }

  getStreamingIp(cloudDeploy: boolean){
    const deployParams = this.getDeployParams()
    if (environment.localhostMode) {
      return environment.setStreamingIpMode || 'localhost'	
    }else{
    return cloudDeploy ? deployParams.cloud_deploy.streaming_DNS : deployParams.streaming_ip
    }
  }

  getCouchDbIp(){
    const deployParams = this.getDeployParams()
    const isHTTPS = this.isHTTPS()
    if (environment.localhostMode) {
      return `http://admin:pantera@localhost:5984/id_planta_${this.getIdPlanta()}`
    }
    if (isHTTPS) {
      return `https://admin:pantera@${deployParams.cloud_deploy.couchDB_DNS}/id_planta_${this.getIdPlanta()}`
    } else {
      return `http://admin:pantera@${deployParams.couchdb_ip}:${deployParams.couchdb_port}/id_planta_${this.getIdPlanta()}`
    }
  }

  getBackendURL(){
    const deployParams = this.getDeployParams()
    const isHTTPS = this.isHTTPS()
    if (environment.localhostMode) {
      return `http://localhost:5002`
    }
    if (isHTTPS && deployParams.cloud_deploy?.backend_DNS) {
      return `https://${deployParams.cloud_deploy.backend_DNS}`
    } else {
      return `http://${deployParams.backend_api}:${deployParams.backend_port}`
    }
  }

  getSocketIOIp(){
    const deployParams = this.getDeployParams()
    const isHTTPS = this.isHTTPS()
    if (environment.localhostMode) {
      return `http://localhost:3002`
    }
    if (isHTTPS && deployParams.cloud_deploy?.socket_DNS) {
      return `https://${deployParams.cloud_deploy.socket_DNS}`
    } else {
      return `http://${deployParams.socketio_ip}:${deployParams.socketio_port}`
    }
  }

  hasRiskMap(){
    return this.getDadosPantera('pantera_features').risk_map
  }

  hasHistoricalData(){
    return this.getDadosPantera('pantera_features').scars
  }

  // planta pode ter só hotspots históricos e não cicatrizes (TO DO melhorar)
  hasScars(){
    return this.hasHistoricalData() && this.id_planta() != 138;
  }

  hasPropagation(){
    return this.getDadosPantera('pantera_features').propagation
  }

  hasDeforestation(){
    return this.getDadosPantera('pantera_features').deforestation
  }

  hasDeforestationGfw(){
    return this.id_planta() === 119;
  }

  hasBio(){
    // IHP
    return this.id_planta() == 36
  }

  hasLocais(){
    const locais = this.getDadosPantera('locais');
    return locais.features ? true : false;
  }

  hasHistoricalMunData(){
    const PLANTAS_MUN_DATA = [109]
    return PLANTAS_MUN_DATA.includes(this.id_planta());
  }  

  hasMeteoStations() {
    return this.pantera_data.has_meteo_station;
  }

  hasCameraTrap() {
    return [36].includes(this.id_planta());
  }

  hasOcorrenciaRegiao(){
    return [122].includes(this.id_planta());
  }
  
  reloadInterface() {
    window.location.reload(); 
  }

  async atualizarDadosCliente() {
    const backendURL = this.getBackendURL(); 
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    try {
      const response = await lastValueFrom(this.HttpClient.get<any>(`${backendURL}/maestro/atualizar_dados_cliente`, {headers: headers}));
      return { status: true, msg: response.status }; 
    } catch (error) {
      console.error('Erro ao atualizar dados do cliente:', error);
    }
  }

  // ---------------- USER PREFS -----------------

  getP2UserPrefsFromLs(){
    this.getAudioStateFromLs()
    this.getDrawAutoGeolocEllipseFromLs()
  }

  setUserPrefsStatus(prefs: UserPrefs){
    this.userPrefsStatus.set(prefs)
  }

  updateUserPrefsStatus(userPrefKey: string, newValue: any) {
    this.userPrefsStatus.update((userPrefs: UserPrefs) => {
      if (userPrefs?.data && typeof userPrefs.data[userPrefKey] !== 'undefined') {
        userPrefs.data[userPrefKey] = newValue;
      } else {
        console.warn('Invalid user preferences structure:', userPrefs);
      }
      return userPrefs;
    });
  }  

  setAudioState(value: boolean) {
    this.audioState.set(value);
    localStorage.setItem(AUDIO_STATE_KEY, JSON.stringify(value));
  }
  
  getAudioStateFromLs() {
    const audioStateOnLocalStorageExists = localStorage.getItem(AUDIO_STATE_KEY);
    const audioStateValue = audioStateOnLocalStorageExists ? JSON.parse(audioStateOnLocalStorageExists) : true;
    this.setAudioState(audioStateValue);
  }

  setDrawAutoGeolocEllipse(value: boolean){
    this.drawAutoGeolocEllipse.set(value)
    localStorage.setItem(DRAW_AUTO_GEOLOC_ELLIPSE_KEY, JSON.stringify(value))
  }

  getDrawAutoGeolocEllipseFromLs() {
    const drawAutoGeolocOnLs = localStorage.getItem(DRAW_AUTO_GEOLOC_ELLIPSE_KEY);
    const drawAutoGeolocValue = drawAutoGeolocOnLs ? JSON.parse(drawAutoGeolocOnLs) : true;
    this.setDrawAutoGeolocEllipse(drawAutoGeolocValue);
  }

}
