import { Component, ViewChild, ElementRef, OnInit, Inject } from '@angular/core';

import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';

import { ReportsService } from '../../services/reports.service';
import { UserDataService } from '../../services/user-data.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'download-report-dialog',
  templateUrl: 'download-report-dialog.html',
  styleUrls: ['download-report-dialog.scss']
})
export class DownloadReportDialog implements OnInit{
  
  @ViewChild('content', {static:false}) el:ElementRef;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reports: ReportsService,
    public user: UserDataService,
    private amplitude: AmplitudeService,
    private http: HttpService,
  ) {}

  public b64_logo:string;

  public loaded = false;

  async ngOnInit(){
    await this.reports.set64imgs();
    this.b64_logo = await this.user.getB64Logo();
  }

  downloadReportPNG() {
    const contentWrapper = document.getElementById("content-wrapper");
    const elementToHide = document.getElementById("to-hide");
    
    contentWrapper.style.height = "auto";
    elementToHide.className = "hide";

    html2canvas(contentWrapper, {
      scrollY: -window.scrollY,
      scale: 5,
      backgroundColor: '#262626'
    }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");      
      const link = document.createElement('a');
      link.download = `relatorio_${this.reports.selectedR.n_relatorio_planta}.png`;
      link.href = imgData;
      link.click();      
      elementToHide.className = "actions-row";
    });
    
    this.amplitude.sendEvent("Baixou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Formato": "PNG" })
  }

  private async generatePDF(): Promise<Uint8Array> {
    const contentWrapper = document.getElementById("content-wrapper");
    const elementToHide = document.getElementById("to-hide");
  
    contentWrapper.style.height = "auto";
    elementToHide.className = "hide";
  
    const canvas = await html2canvas(contentWrapper, {
      scrollY: -window.scrollY,
      scale: 5,
      backgroundColor: '#262626',
    });
  
    const imgData = canvas.toDataURL("image/png");
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([canvas.width, canvas.height]);
    const pngImage = await pdfDoc.embedPng(imgData);
    const { width: imgWidth, height: imgHeight } = pngImage.scale(1);
  
    page.drawImage(pngImage, {
      x: 0,
      y: page.getHeight() - imgHeight,
      width: imgWidth,
      height: imgHeight,
    });
  
    const pdfBytes = await pdfDoc.save();
    elementToHide.className = "actions-row";
  
    return pdfBytes;
  }
  
  async downloadReportPDF() {
    const pdfBytes = await this.generatePDF();
    this.download(pdfBytes, `relatorio_${this.reports.selectedR.n_relatorio_planta}.pdf`, "application/pdf");
    this.amplitude.sendEvent("Baixou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Formato": "PDF" })

  }

  download(data: Uint8Array, fileName: string, mimeType: string) {
    const blob = new Blob([data], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async sendReportViaEmail(){
    try {
      const pdfBytes = await this.generatePDF();

      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

      let formData = new FormData();
      formData.append('file', pdfBlob, `relatorio_${this.reports.selectedR.n_relatorio_planta}.pdf`);
      let data = {
        'id_planta': this.user.getIdPlanta(),
        'n_relatorio_planta': this.reports.selectedR.n_relatorio_planta
      };
  
      formData.append('reportData', JSON.stringify(data));

      const response = await this.http.maestroPost('send_pdf_email', formData, true);
      console.log('PDF sent successfully:', response);
    } catch (error) {
      console.error('Error generating or sending PDF:', error);
    }
  }

}