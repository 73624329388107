import { Component, Injector } from '@angular/core';
import { formatDate } from '@angular/common';

import { MatDialog,MatDialogRef  } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";

import { HttpService } from '../../services/http.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { GeoService } from 'src/app/services/geo.service';
import { SatelliteService } from 'src/app/services/satellite.service';
import { ReportsService } from '../../services/reports.service';
import { LoggingService } from 'src/app/services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '../../services/amplitude.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'create-report-cluster-dialog',
  templateUrl: 'create-report-cluster.html',
})
export class createReportClusterDialog {
  constructor(
    private http: HttpService,
    public user: UserDataService,
    public geo: GeoService,
    public sat: SatelliteService,
    public rep: ReportsService,
    public logging: LoggingService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private injector: Injector,
    private dialogRef: MatDialogRef<createReportClusterDialog>,
    private amplitude: AmplitudeService,
    public filterService: FilterService,
  ) {}

  public clusterHotspotTableColumns: string[] = [
    'satellite',
    'acquisition_utc_offset',
    'detection_utc_offset',
    'confidence',
    'frp_normalized_mw_km2'
  ]

  public selectedRepId: string = null;
  public creatingReport: boolean = false;

  public rData = {
    'origem_deteccao': 3, //satélite
    'causa_incendio': null,
    'comentarios': null,
    'geoloc_type_id': 7 //click no cluster de satélite
    }

  async createSatReport(lat_centroid: number, lon_centroid: number, datetime_deteccao: Date, rData: any, cluster_pk_mv: number) {
    this.creatingReport = true;
    try {
      let reportID = await this.rep.createReport(lat_centroid, lon_centroid, datetime_deteccao, rData);
      await this.confirmCluster(cluster_pk_mv, reportID);
      this.dialogRef.close();
    } catch (error) {
      this.logging.logERROR(`createSatReport ${error}`, error);
      console.error('Error creating report from temporary cluster:', error);
    } finally {
      this.creatingReport = false;
    }
  }

  //Torna o agrupamento permanente (registra em sat.cluster) e vincula ao relatório criado (ou selecionado)
  async confirmCluster(cluster_pk_mv, report_id){
    try {
      let response = await this.http.centralGet('confirm_cluster_bind_to_report', [cluster_pk_mv, report_id]);
      let unique_id = `c${response[2]}`
      if (response[0] === true){
        console.log(`Agrupamento confirmado: #${response[2]}.`, response);
        if (report_id !== this.selectedRepId) {
          this.dialog.open(InfoDialog, {
            data: {text:`${this.translate.instant("REP.CREATE_REPORT_DIALOG")}`}
          });
          this.dialogRef.close();
          await this.geo.updateDrawReports();
          this.filterService.updateFilters();
        }
        else {
          this.dialog.open(InfoDialog, {
            data: {text:`${this.translate.instant("SAT.BIND_CLUSTER_DIALOG")}`}
          });
          this.dialogRef.close();
          //Amplitude
          let n_relatorio_planta = this.rep.reports_list.find(item => item.id_report === report_id)?.n_relatorio_planta;
          this.amplitude.sendEvent('Anexou Agrupamento Em Relatório', {"ID Agrupamento": response[2], "Número Relatório": n_relatorio_planta});
        }
        await this.geo.updateSatActiveFireGroup();
        this.injector.get(SatelliteService).selectCluster(unique_id);

      }
      else{
        if (report_id !== this.selectedRepId) {
          //Se não der certo criar o cluster; então deleta o relatório
          await this.http.centralGet('delete_report_recorded', [`${this.user.getIdPlanta()}`, report_id]);
          await this.geo.updateDrawReports();
        }
        this.dialog.open(InfoDialog, {
          data: {text:`${this.translate.instant("SAT.ERROR_BINDING_CLUSTER")}`}
        });
      }
    } catch (error) {
      this.logging.logERROR(`confirmCluster ${error}`, error);
      console.error('Error creating permanent cluster:', error);
    }
  }
  
}

