import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { HttpService } from '../../services/http.service'
import { UserDataService } from '../../services/user-data.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { DeployComponent } from 'src/app/components/deploy/deploy.component';
import {environment} from 'src/environments/environment'
import { LoggingService } from '../../services/logging.service';
import { SocketService } from 'src/app/services/socket.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  providers:[DeployComponent ],
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit{
  @ViewChild(DeployComponent) componentDeploy: DeployComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpService,
    public user: UserDataService,
    private dialog: MatDialog,
    public logging: LoggingService,
    public socket: SocketService,
    private router: Router,
    private translateService: TranslateService,
  ) { }

  @ViewChild(MatSort) sort: MatSort;

  public envDev = !environment.production

  public loaded: boolean = false;
  public loadingNewUser: boolean = false;
  public roles: any[];
  public plantas: any[];
  private stringify_pantera_features = {}
  public users_planta: any[];

  public plantasDataSource;
  public pantera_plantas;

  public leadsStartDate: Date;
  public leadsEndDate: Date;

  public versions: { id_version: number, version: string }[] = [];
  public models = [];

  public cameras: any[];
  public camTableColumns: string[] = [
    'id_instalacao',
    'nome_local',
    'ip_lan',
    'ip_wan',
    'use_ip_wan',
    'use_cam_rtsp',
    'rtsp_url',
    'model_host',
    'model_port',
  ];

  public panteraTableColumns: string[] = [
    'nome_cliente',
    'planta',
    'pantera_version',
    'wanted_pantera_version',
    'installed_model',
    'id_modelo',
    'frontend_version',
    'backend_api',
    'threshold',
    'threshold_zoom',
    'save_img_fp_percent',
    'updated_dt',
    'update_maestro',
    'streaming_type',
  ];

  public plantasTableColumns: string[] = [
    'nome_cliente',
    'planta',
    'cam_detection',
    'fire_mngmt',
    'satellite',
    'risk_map',
    'brigades_position',
    'scars',
    'propagation',
    'deforestation'
  ];

  public rolesTableColumns: string[] = [
    'id_role',
    'user_role',
    'role_description',
  ];


  newUserForm = this.fb.group({
    id_planta: ['',[Validators.required]],
    id_role: ['',[Validators.required]],
    username: ['',[Validators.required]],
    email: ['',[Validators.required, Validators.email]],
    nome: [''],
    sobrenome: [''],
    newpass: ['']
    });


  async ngOnInit() {
    this.loaded = false;

    await this.updatePlantas();

    let admin_data = await this.http.centralGet('get_admin_data');
    console.log('admin_data', admin_data);

    this.versions = admin_data['versions'] || [];
    this.models = admin_data['models'] || [];

    this.roles = admin_data['user_roles'] || [];
    this.roles.sort((r1, r2) => r1.id_role - r2.id_role);

    this.cameras = await this.http.centralGet('get_cameras_planta', [`${this.user.getIdPlanta()}`]) || [];
    this.cameras.sort((c1, c2) => c1.id - c2.id)
    console.log('%c🗼 CamList', 'background: #222; color: #bada55', this.cameras);

    this.users_planta = await this.http.centralGet('get_all_users', [`${this.user.getIdPlanta()}`]);

    this.loaded = true;

    this.socket.joinIdPlantRoom()

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          this.socket.disconnectFromSockCent();
        }
    });
  }

  onTabChanged(evento){
    console.log(evento, evento.index );
    this.componentDeploy.changeKeepCalling(evento.index)
    if (evento.index === 3) {
      this.componentDeploy.deployService.saveInfos(this.pantera_plantas);
    }

  }

  async updatePlantas(){
    this.plantas = await this.http.centralGet('get_plantas');
    this.plantas.sort((p1, p2) => p1.nome_cliente - p2.nome_cliente);

    this.plantasDataSource = new MatTableDataSource(this.plantas)
    this.plantasDataSource.sort = this.sort;

    this.pantera_plantas = new MatTableDataSource(this.plantas.filter(p=>p.wanted_pantera_version))
    this.pantera_plantas.sort = this.sort;
    console.log(this.pantera_plantas);


    this.plantas.forEach(p=>{
      this.stringify_pantera_features[p.id_planta] = JSON.stringify(p.pantera_features)
    })
  }

  async newUserSubmit(){

    this.loadingNewUser = true;

    let newUser = {
      username : this.newUserForm.value.username,
      id_planta : this.newUserForm.value.id_planta,
      id_role : this.newUserForm.value.id_role,
      email : this.newUserForm.value.email,
      nome : this.newUserForm.value.nome,
      sobrenome : this.newUserForm.value.sobrenome,
      newpass : this.newUserForm.value.newpass,
    }

    console.log('newUser', newUser)

    let response = await this.http.maestroPost('new_user', newUser)
    console.log('new_user_response', response);

    this.loadingNewUser = false;

    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("ADM.USER_CREATED")}`}
      });
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("MAP.ERROR")} : ${response?.msg}`}
      });
    }

    this.newUserForm.reset()

  }


  async updateConfigCameras(){
    console.log('updateConfigCameras', this.cameras)
    let response = await this.http.maestroPost('update_config_cameras_pweb', this.cameras)
    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("ADM.CAMERAS_UPDATED")}`}
      });
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("MAP.ERROR")} : ${response?.msg}`}
      });
    }
  }

  changedFeatures(element){
    return JSON.stringify(element.pantera_features) != this.stringify_pantera_features[element.id_planta]
  }

  async sendChanges(element){
    
    let obj = {
      'id_planta': element.id_planta,
      'pantera_features': element.pantera_features
    }

    console.log('update_pantera_features', obj)
    let response = await this.http.maestroPost('update_pantera_features', obj)

    this.updatePlantas();

    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("ADM.PLANT_FEATURES")} ${element.id_planta}`}
      });
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("MAP.ERROR")}: ${response?.msg}`}
      });
    }

  }

  changedElement(event, element){
    element.changed = true;
  }

  async updateConfigs(planta){
    console.log(planta)
    let obj = {
      'id_planta': planta.id_planta,
      'wanted_pantera_version': planta.wanted_pantera_version,
      'id_modelo': planta.id_modelo,
      'd_model_tsh': planta.model_tsh,
      'smoke_tsh_zoom': planta.smoke_tsh_zoom,
    }

    console.log('update_pantera_configs', obj);
    let response = await this.http.maestroPost('update_pantera_configs', obj);

    this.updatePlantas();

    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("ADM.SETTINGS_UPDATED")}`}
      });
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translateService.instant("MAP.ERROR")} : ${response?.msg}`}
      });
    }

  }

  async installWantedVersion(data){

    let id_planta = data.id_planta
    let response = await this.http.centralGet('delete_containers', [id_planta])
    console.log(response);


    response = await this.http.centralGet('deploy_pantera', [id_planta])
    console.log(response);

    data.pantera_version = data.wanted_pantera_version


    return true
  }

  getStreamingIcon(tipo){
    let dict = {
      'docker-ws':'🐋',
      'go-webrtc':'go',
    }
    return dict[tipo]
  }

  formatInvertedDate(inputDate: Date) {
    let day = inputDate.getDate()
    let month = inputDate.getMonth() + 1

    let stringDay = ""
    let stringMonth = ""

    if (day < 10) {
      stringDay = "0" + day.toString()
    }
    else {
      stringDay = day.toString()
    }

    if (month < 10) {
      stringMonth = "0" + month.toString()
    }
    else {
      stringMonth = month.toString()
    }

    return inputDate.getFullYear().toString() + "-" + stringMonth + "-" + stringDay
  }

  // DEPRECATED DELETAR
  async getLeads() {

    if ((!this.leadsStartDate) || (!this.leadsEndDate)) {
      alert("Preencha as datas de início e fim do intervalo para consulta!")
      return
    }

    let startDate = this.formatInvertedDate(this.leadsStartDate)
    let endDate = this.formatInvertedDate(this.leadsEndDate)

    window.open(`${this.http.getServerUrl()}/download_leads/${startDate}/${endDate}`, "_blank")
  }
}
