import { destination, point } from '@turf/turf';
import { polygon } from 'leaflet';

const DEFAULT_MIN_FOCAL_DISTANCE_MM = 4.3
const DEFAULT_SENSOR_WIDTH_MM = 5.3

export function getCamAngularViewDeg(zoom:number, minFocalDistance: number = DEFAULT_MIN_FOCAL_DISTANCE_MM, sensorWidth: number = DEFAULT_SENSOR_WIDTH_MM) {
  if (!minFocalDistance || !sensorWidth || !zoom) {
    return undefined
  }
  
  let f = zoom * minFocalDistance;
  return 2*Math.atan(sensorWidth/(2*f)) * (180/Math.PI)
}

export function zoom2DistanceFov(zoom: number, minFocalDistance: number = DEFAULT_MIN_FOCAL_DISTANCE_MM, sensorWidth: number = DEFAULT_SENSOR_WIDTH_MM) {
  const ratio = minFocalDistance / sensorWidth;

  // Cluke magic numbers
  const estimateDistCoefficient = 0.3;
  const estimateDistConstant = 5.75;

  const start = estimateDistCoefficient * ratio * (zoom - 1);
  const end = estimateDistConstant + estimateDistCoefficient * ratio * zoom;

  return {
    start: start,
    end: end
  };
}

export function createPresetPolygon(cam, preset){

  let zoom = Math.max(1, preset.zoom)
  let camAngularView = getCamAngularViewDeg(zoom, cam.min_focal_distance_mm, cam.sensor_width_mm);   
  let color = "#3388ff"
  let zdistance = zoom2DistanceFov(zoom, cam.min_focal_distance_mm, cam.sensor_width_mm)

  return createPolygon(cam.lat, cam.lon, preset.pan, camAngularView, color, zdistance.end, zdistance.start);
}

function createPolygon(origin_lat, origin_lon, bearing, angle, color:string='white', distance_end:number=15, distance_start:number=0, unit:string='kilometers', weight=5, opacity=0.6){
  let d_unit: any = {units: unit};
  
  let origin = point([origin_lon, origin_lat]);

  let destination1 = destination(origin, distance_end, bearing-angle/2, d_unit);
  let destination2 = destination(origin, distance_end, bearing+angle/2, d_unit);

  let pts;

  if (distance_start==0){
    pts = [origin.geometry.coordinates.reverse()]
  } else {
    let origin1 = destination(origin, distance_start, bearing-angle/2, d_unit);
    let origin2 = destination(origin, distance_start, bearing+angle/2, d_unit);
    pts = [
      origin2.geometry.coordinates.reverse(),
      origin1.geometry.coordinates.reverse(),
    ]
  }
  
  pts.push(
    destination1.geometry.coordinates.reverse(),
    destination2.geometry.coordinates.reverse()
  )

  return polygon(pts, {color: color, weight: weight, opacity: opacity})
}

