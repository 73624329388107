import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpService } from './http.service';
import { UserDataService } from './user-data.service';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardsService {

  constructor(
    private router: Router,
    private http: HttpService,
    private user: UserDataService,
  ) {}

  public authorized = new BehaviorSubject<boolean>(false);
  
  async canActivate(route: ActivatedRouteSnapshot) {

    let auth_user_data = await this.http.maestroGet('pweb_auth', this.user.getToken());
    if(auth_user_data && this.http.maestroBearer){
      if (!auth_user_data.status_planta && auth_user_data.id_cliente ===2){
        this.router.navigateByUrl('/login');
        console.log("Plant Deactivated 📵")
      };
      
      let idPlanta: number;
      idPlanta = route.queryParams.idPlanta
      if(idPlanta){
        await this.user.setManagerPlanta(auth_user_data, idPlanta);
      }
    
      await this.user.setData(auth_user_data);

      if(route.data.roles){
        let can_login = route.data.roles.includes(this.user.getIdRole())
        console.log("🔒role_check", can_login)
        return can_login;
      }

      if(this.user.isOperatorP2() == false && this.user.isSupportP2() == false && route.routeConfig.path == 'interface'){
        this.router.navigateByUrl('/map');
        console.log("🔒Operator is not P2 user!")
      }

      this.authorized.next(true);
      return true
    
    } else {
      this.router.navigateByUrl('/login');
      console.log('wrong crypt token');
      return false
    }

  }
}
