<main>
  <div class="chart-rows">
    <div class="area-projeto">
      @if (user.hasHistoricalMunData()) {
        <h2>Áreas monitoradas</h2>
      }
      <div class="chart-row">
        <div>
          <canvas id="yearlyScarsChart"></canvas>
        </div>
        <div>
          <canvas id="totalScarsByLulcChart"></canvas>
        </div>
      </div>
      <div class="chart-row">
        <div>
          <canvas id="yearlyEmissionsChart"></canvas>
        </div>
        <div>
          <canvas id="totalEmissionsByLulcChart"></canvas>
        </div>
      </div>
      <div class="chart-row">
        <div>
          <canvas id="yearlyHotspotsChart"></canvas>
        </div>
        <div>
          <canvas></canvas>
        </div>
      </div>
    </div>
    @if (user.hasHistoricalMunData()) {
      <div class="area-municipios">
        <h2 class="municipios-heading">Áreas dos Municípios</h2>
        <div class="chart-row">
          <div>
            <canvas id="totalMunScarsChart"></canvas>
          </div>
          <div>
            <canvas id="munScarsByLulcChart"></canvas>
          </div>
        </div>
        <div class="chart-row">
          <div>
            <canvas id="totalMunEmissionsChart"></canvas>
          </div>
          <div>
            <canvas id="munEmissionsByLulcChart"></canvas>
          </div>
        </div>
      </div>
    }
  </div>
</main>