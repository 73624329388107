import * as L from 'leaflet'

export interface LayerObj {
  legend: string;
  layer: any;
  active: boolean;
}

export interface LayerGroupObj {
  groupId: number;
  groupTitle: string;
  layersList: LayerObj[];
  icon: string;
  infoHTML?: string;
  showInfo?: boolean;
  info?: boolean;
}

interface RawTileLayerObj {
  name: string;
  url: string;
  options?: object;
  active?: boolean;
}

// TODO pode fazer mais parcido com pantera, criando as layers aqui mesmo
const BASE_LAYERS_LIST: RawTileLayerObj[] = [
  {
    name: 'MAP.BASEMAP_SAT_RGB',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    options: {
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    },
  },
  {
    name: 'MAP.TOPOGRAPHY',
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    options: {
      attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    }
  },
  // {
  //   name: 'Streets',
  //   url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
  //   options: {
  //     attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012',
  //   }
  // },
  {
    name: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    options: {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }
  }
]

export function createBaseLayersList(isUgm: boolean){
  let layerList:LayerObj[] = []
  let baseLayersList = [...BASE_LAYERS_LIST]

  if (isUgm){
    baseLayersList.push({
      name: 'Planet Brasil',
      url: 'https://tiles.planet.com/basemaps/v1/planet-tiles/global_monthly_2024_06_mosaic/gmap/{z}/{x}/{y}.png?api_key=8e928c25f946486d9e41a279370b9739'
    })
  }
  
  for (const obj of baseLayersList) {
    let baseLayer = L.tileLayer(obj.url, {
      ...obj.options,
      pane:'mapPane'
    });
    
    layerList.push({
      'legend': obj.name,
      'layer': baseLayer,
      'active': !!obj.active
    })
  }

  return layerList
}


const openweathermapKey = '099e2820b6f74f6fdb5e112c54a65ef3'

const currentDate = new Date()
const inTwentyFourHoursDate = new Date(currentDate.getTime() + (24 * 60 * 60 * 1000))
const inTwentyFourHoursTimestamp = Math.round(inTwentyFourHoursDate.getTime() / 1000)

const MeteoOverlaysList: RawTileLayerObj[] = [
  {
    name: 'MAP.WIND_CURRENT',
    url:  `http://maps.openweathermap.org/maps/2.0/weather/WND/{z}/{x}/{y}?appid=${openweathermapKey}`
  },
  {
    name: 'MAP.WIND_TOMORROW',
    url:  `http://maps.openweathermap.org/maps/2.0/weather/WND/{z}/{x}/{y}?date=${inTwentyFourHoursTimestamp}&appid=${openweathermapKey}`
  },
  {
    name: 'MAP.PRECIPITATION_NOW',
    url: `http://maps.openweathermap.org/maps/2.0/weather/PR0/{z}/{x}/{y}?appid=${openweathermapKey}`
  },
  {
    name: 'MAP.PRECIPITATION_TOMORROW',
    url: `http://maps.openweathermap.org/maps/2.0/weather/PR0/{z}/{x}/{y}?date=${inTwentyFourHoursTimestamp}&appid=${openweathermapKey}`
  },

  // {
  //   name: 'Temperatura',
  //   url: `http://maps.openweathermap.org/maps/2.0/weather/TA2/{z}/{x}/{y}?appid=${openweathermapKey}`
  // },
  // {
  //   name: 'Nuvens',
  //   url:  `http://maps.openweathermap.org/maps/2.0/weather/CL/{z}/{x}/{y}?appid=${openweathermapKey}`
  // },
]

export function createMeteoLayerGroup():LayerGroupObj{
  let meteoLayers:LayerObj[] = [];
  for (const obj of MeteoOverlaysList) {
    meteoLayers.push(
      {
        'legend':obj.name,
        'layer': L.tileLayer(obj.url),
        'active':false
      }
    )
  }
  return {
    'groupId': 80,
    'groupTitle': "MAP.CLIMATE",
    'icon': 'thermostat',
    'layersList': meteoLayers 
  }  
}