<style>

main{
    width: 350px;
}

.outlineForm{
  width: 100%;
}

</style>

<main class="report-dialog-wrapper">

  <mat-dialog-content >

    @if (error_msg) {
      <h3>{{error_msg}}</h3>
    }

    @else {
      <div>
        <h3>Tem certeza que deseja finalizar o relatório de ocorrência?</h3>
        <ul>
          @for (m of info_msgs; track m) {
            <li>{{m}}</li>
          }
        </ul>
      </div>
    }

  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]='false'>{{ 'MAP.CANCEL' | translate }}</button>
    <button mat-raised-button color='primary'
      [disabled]='!!error_msg'
    [mat-dialog-close]="true">{{ 'MAP.CONFIRM' | translate }}</button>
  </mat-dialog-actions>

</main>
