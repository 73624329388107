import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { HttpService } from 'src/app/services/http.service';
import { Chart, ChartType, ChartConfiguration, ChartOptions } from 'chart.js';
import { LoggingService } from '../../services/logging.service';
import { TranslateService } from '@ngx-translate/core';




const DEFAULT_START_YEAR = 2015;
const DEFAULT_END_YEAR = 2023;
const PALETA_SCARS = [
  'rgba(253, 98, 94, 1)',
  'rgb(242, 200, 15, 1)',
  'rgb(138, 212, 235, 1)',
  'rgb(95, 107, 109, 1)',
  'rgb(40, 56, 60, 1)',
  'rgba(1, 184, 170, 1)',
  // add more colors as needed
]

const PALETA_EMISSIONS = PALETA_SCARS.slice().reverse();

const ID_LULC_OUTROS = 999;

interface Lulc {
  id_lulc: number,
  nome_lulc: string,
  id_lulc_group: number,
}

interface ScarsEmissionsData {
  ano: number,
  area_ha: number,
  emissoes_tCO2: number,
  id_lulc: number,
}

interface MunData extends ScarsEmissionsData {
  id_mun: number,
  nome_mun: string,
}

interface ChartDataset {
  label: any,
  backgroundColor: string,
  borderColor: string,
  borderWidth: number,
  data: number[]
}

interface HotspotsData {
  ano: number,
  num_hotspots: number,
}

interface LabelsValues {
  labels: number[],
  values: number[],
}

function getTooltipWithUnit(tooltipItem, unit: string) {
  const parsedValue = typeof tooltipItem.parsed === 'number' ? tooltipItem.parsed : tooltipItem.parsed.y;
  let value = Number(parsedValue).toFixed(0);
  return `${value} ${unit}`;
}

@Component({
  selector: 'app-carbon',
  templateUrl: './carbon.component.html',
  styleUrls: ['./carbon.component.scss']
})
export class CarbonComponent implements OnInit {

  constructor(
    public user: UserDataService,
    private http: HttpService,
    public logging: LoggingService,
    private translate: TranslateService
  ) { }

  public yearlyScarsChart: Chart;
  public yearlyEmissionsChart: Chart;
  public totalScarsByLulcChart: Chart;
  public totalEmissionsByLulcChart: Chart;
  public yearlyHotspotsChart: Chart;
  public munAreaHeatmap: Chart;
  public data: ScarsEmissionsData[];
  public municipiosData: MunData[];
  public lulc: Lulc[];
  private lulcIds: number[];
  private hotspotsData: HotspotsData[];
  // private munByYearArea;
  // private munByYearEmissions;

  // TO DO botar types direitinho em tudo, aqui e nas funçoes


  async ngOnInit() {
    await this.getScarsEmissionsData();
    await this.getLulc();
    this.createTotalScarsChart();
    this.createTotalEmissionsChart();
    this.createTotalScarsByLulcChart();
    this.createTotalEmissionsByLulcChart();
    await this.getHotspotsData();
    this.createTotalHotspotsChart();

    if (this.user.hasHistoricalMunData()){
      await this.getMunicipiosData(); // depois tirar await e fazer async
      this.createTotalMunScarsChart();
      this.createTotalMunEmissionsChart();
      this.createMunScarsByLulcChart();
      this.createMunEmissionsByLulcChart();
    }
    // this.munByYearArea = this.getMunByYearArea();
    // this.munByYearEmissions = this.getMunByYearEmissions();
    // this.createMunAreaHeatmap();
    // this.createMunEmissionsHeatmap();
  }

  async getScarsEmissionsData(){
    this.data = await this.http.centralGet('get_cicatrizes_emissoes_planta', [`${this.user.getIdPlanta()}`, `${DEFAULT_START_YEAR}`, `${DEFAULT_END_YEAR}`]);
  }

  async getMunicipiosData(){
    this.municipiosData = await this.http.centralGet('get_cicatrizes_stats_municipios', [`${this.user.getIdPlanta()}`, `${DEFAULT_START_YEAR}`, `${DEFAULT_END_YEAR}`]);
    console.log('MUNDATA', this.municipiosData);
  }

  async getLulc(){
    this.lulc = await this.http.centralGet('get_lulc');
  }

  async getHotspotsData(){
    this.hotspotsData = await this.http.centralGet('get_hotspots_historicos_stats_planta', [`${this.user.getIdPlanta()}`]);
    try {
      this.hotspotsData.sort((a, b) => a.ano - b.ano);
    } catch(e){
      this.logging.logERROR(`getHotspotsData ${e}`,e);
    }
  }

  getNomesLulcsByIds(ids: number[], lulcs: Lulc[]): string[] {
    return ids.map(id => {
      const item = lulcs.find(item => item.id_lulc === id);
      return item ? item.nome_lulc : 'Outros';
    });
  }

  getChartDataIdLulc(field: string, dataArray: ScarsEmissionsData[] | MunData[], idLulc:number): LabelsValues {
    let dataMap = new Map();
    for (let year = 2015; year <= 2022; year++){
      dataMap.set(year, 0);
    };

    const ids = this.lulcIds.filter((value) => value !== ID_LULC_OUTROS);

    dataArray.forEach(record => {
      const year = record.ano;
      const value = record[field];
      const recordIdLulc = record.id_lulc;
    
      const shouldProcessRecord = ids.includes(idLulc) ? recordIdLulc === idLulc : !ids.includes(recordIdLulc);
    
      if (shouldProcessRecord) {
        const existingData = dataMap.get(year) || 0;
        dataMap.set(year, existingData + value);
      }
    });

    let labels: number[] = [];
    let values: number[] = [];
    dataMap.forEach((value, key) => {
      labels.push(key);
      values.push(value);
    });
    return {
      labels: labels,
      values: values
    }
  }

  getSimplifiedDataByLulc(data: Record<number, number> | any){
    const dataByLulc = this.simplifyLulcs(data);
    const dataValues = Object.values(dataByLulc)
    this.lulcIds = Object.keys(dataByLulc).map((strId) => Number(strId));
    const dataLabels = this.getNomesLulcsByIds(this.lulcIds, this.lulc);
    return {
      dataValues: dataValues,
      dataLabels: dataLabels
    }
  }

  getYearLabels(){
    let years = [];
    for (let year = DEFAULT_START_YEAR; year <= DEFAULT_END_YEAR; year++){
      years.push(year)
    }
    return years;
  }

  createTotalScarsChart() {
    let areaByLulc = this.getAreaByLulc(this.data);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(areaByLulc);
    const dataLabels2 = this.lulcIds
    let chartLabels = this.getYearLabels();

    let datasets: ChartDataset[] = []
    for (let i = 0; i < dataValues.length; i++){
      let translatedLabel = this.translate.instant(`LULC.${dataLabels2[i]}`);

      datasets.push({
        label: translatedLabel,
        backgroundColor: PALETA_SCARS[i],
        borderColor: PALETA_SCARS[i],
        borderWidth: 1,
        data: this.getChartDataIdLulc('area_ha', this.data, this.lulcIds[i]).values,
      });
    }
    console.log(this.lulcIds)
    this.yearlyScarsChart = new Chart('yearlyScarsChart', this.getBarChartConfig(chartLabels, datasets, 'ha', true, this.translate));
  }

  createTotalMunScarsChart() {
    let areaByLulc = this.getAreaByLulc(this.municipiosData);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(areaByLulc);

    let chartLabels = this.getYearLabels();

    let datasets: ChartDataset[] = []
    for (let i = 0; i < dataValues.length; i++){
      datasets.push({
        label: dataLabels[i],
        backgroundColor: PALETA_SCARS[i],
        borderColor: PALETA_SCARS[i],
        borderWidth: 1,
        data: this.getChartDataIdLulc('area_ha', this.municipiosData, this.lulcIds[i]).values
      });
    }

    this.yearlyScarsChart = new Chart('totalMunScarsChart', this.getBarChartConfig(chartLabels, datasets, 'ha', true, this.translate));
  }

  createTotalEmissionsChart() {

    let emissionsByLulc = this.getAreaByLulc(this.data);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(emissionsByLulc);
    const dataLabels2 = this.lulcIds

    let chartLabels = this.getYearLabels();

    let datasets: ChartDataset[] = []
    for (let i = 0; i < dataValues.length; i++){
      let translatedLabel = this.translate.instant(`LULC.${dataLabels2[i]}`);
      datasets.push({
        label: translatedLabel,
        backgroundColor: PALETA_EMISSIONS[i],
        borderColor: PALETA_EMISSIONS[i],
        borderWidth: 1,
        data: this.getChartDataIdLulc('emissoes_tCO2', this.data, this.lulcIds[i]).values
      });
    }
    this.yearlyEmissionsChart = new Chart('yearlyEmissionsChart', this.getBarChartConfig(chartLabels, datasets, 'tCO2', true, this.translate));
  }

  getBarChartConfig(labels, datasets: ChartDataset[], chartUnit: string, stacked: boolean,translate:TranslateService, yTickCallbackFunc?){
    
    const chartTitleKey = chartUnit == 'ha' ? 'LULC.BURNED_AREA' : 'LULC.EMISSIONS';
    const chartTitle = this.translate.instant(chartTitleKey);
    const chartType: ChartType = 'bar';
    let chartOptions = {
      type: chartType,
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            stacked: stacked,
          },
          x: {
            stacked: stacked,
          }
        },
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: chartTitle,
            font: {
              size: 14,
            }
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                return getTooltipWithUnit(tooltipItem, chartUnit);
              }
            }
          }
        }
      }
    };
    return chartOptions;
  }

  createTotalMunEmissionsChart() {
    
    let emissionsByLulc = this.getAreaByLulc(this.municipiosData);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(emissionsByLulc);

    let chartLabels = this.getYearLabels();

    let datasets: ChartDataset[] = []
    for (let i = 0; i < dataValues.length; i++){
      datasets.push({
        label: dataLabels[i],
        backgroundColor: PALETA_EMISSIONS[i],
        borderColor: PALETA_EMISSIONS[i],
        borderWidth: 1,
        data: this.getChartDataIdLulc('emissoes_tCO2', this.municipiosData, this.lulcIds[i]).values
      });
    }

    this.yearlyEmissionsChart = new Chart('totalMunEmissionsChart', this.getBarChartConfig(chartLabels, datasets, 'tCO2', true, this.translate));
  }

  getAreaByLulc(data){
    const areaByLulc = data.reduce((accum, record) => {
      const idLulc = record.id_lulc;
      if (accum[idLulc]) {
        accum[idLulc] += record.area_ha;
      } else {
        accum[idLulc] = record.area_ha;
      }
      return accum;
    }, {});
    return areaByLulc;
  }

  getPieChartConfig(data, backgroundColor: string[], labels, chartUnit: string, translate: TranslateService){
    const chartTitleKey = chartUnit == 'ha' ? 'LULC.BURNED_AREA_COVER' : 'LULC.EMISSIONS_COVER';
    const chartTitle = this.translate.instant(chartTitleKey);
    const translatedLabel = this.lulcIds.map(id  => translate.instant(`LULC.${id }`))
    const dataConfig = {
      labels: translatedLabel,
      datasets: [{
        data: data,
        backgroundColor: backgroundColor,
      }]
    };
    const plugins = {
      title: {
        display: true,
        text: chartTitle,
        font: {
          size: 14
        }
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return getTooltipWithUnit(tooltipItem, chartUnit);
          }
        }
      }
    }
    const options: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: plugins,
    }
    const config: ChartConfiguration = {
      type: 'pie',
      data: dataConfig,
      options: options,
    };
    return config;
  }

  createTotalScarsByLulcChart(){
    let areaByLulc = this.getAreaByLulc(this.data);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(areaByLulc);
    this.totalScarsByLulcChart = new Chart('totalScarsByLulcChart', this.getPieChartConfig(dataValues, PALETA_SCARS, dataLabels, 'ha', this.translate));
    }

  simplifyLulcs(dataByLulc: Record<number, number>): Record<number,number> {
    const sortedData = Object.entries(dataByLulc).sort((a:any, b:any) => b[1] - a[1]);
    const newData = {}; // manter só top 3 e somar o resto numa categoria "outros" 999

    let sum = 0;
    for (let i = 0; i < sortedData.length; i++) {
      if (i < 3) {
        newData[sortedData[i][0]] = sortedData[i][1];
      } else {
        sum += sortedData[i][1];
      }
    }
    if (sum > 0) newData[ID_LULC_OUTROS] = sum;
    return newData;
  }

  createMunScarsByLulcChart(){
    let areaByLulc = this.getAreaByLulc(this.municipiosData);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(areaByLulc);

    this.totalScarsByLulcChart = new Chart('munScarsByLulcChart', this.getPieChartConfig(dataValues, PALETA_SCARS, dataLabels, 'ha', this.translate));
  }

  getEmissionsByLulc(data: ScarsEmissionsData[]){
    const emissionsByLulc = data.reduce((accum, record) => {
      const idLulc = record.id_lulc;
      if (accum[idLulc]) {
        accum[idLulc] += record.emissoes_tCO2;
      } else {
        accum[idLulc] = record.emissoes_tCO2;
      }
      return accum;
    }, {});
    return emissionsByLulc;
  }

  createTotalEmissionsByLulcChart(){
    let emissionsByLulc = this.getEmissionsByLulc(this.data);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(emissionsByLulc);
    this.totalEmissionsByLulcChart = new Chart('totalEmissionsByLulcChart', this.getPieChartConfig(dataValues, PALETA_EMISSIONS, dataLabels, 'tCO2', this.translate));

  }

  createMunEmissionsByLulcChart(){
    let emissionsByLulc = this.getEmissionsByLulc(this.municipiosData);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(emissionsByLulc);
    this.totalEmissionsByLulcChart = new Chart('munEmissionsByLulcChart', this.getPieChartConfig(dataValues, PALETA_EMISSIONS, dataLabels, 'tCO2', this.translate));
  }

createTotalHotspotsChart() {
  const chartTitleKey = 'SAT.HOTSPOT';
  const chartTitle = this.translate.instant(chartTitleKey);
  const chartLabel = 'LULC.SATELLITE_AQUA';
  const chartLabelTranslate = this.translate.instant(chartLabel);
    if (!this.hotspotsData) return;
    let chartLabels = [];
    let datasets = [];
    let data = [];
    this.hotspotsData.forEach((element) => {
      chartLabels.push(element.ano);
      data.push(element.num_hotspots)
    });
    datasets.push({
      label: chartLabelTranslate,
      backgroundColor: PALETA_SCARS[0].replace('1)', '0.7)'),
      borderColor: PALETA_SCARS[0],
      borderWidth: 1,
      data: data,
    });

    this.yearlyHotspotsChart = new Chart('yearlyHotspotsChart', {
      type: 'bar',
      data: {
        labels: chartLabels,
        datasets: datasets,
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: chartTitle,
            font: {
              size: 14,
            },
          }
        }
      }
    });
  }















//-------------------------------------------------------------------------
//
// DAQUI PRA BAIXO SAO OS HEATMAPS DE MUNICIPIOS - DEPRECATED PROVAVELMENTE -> QUARANTINED
//
// --------------------------------------------------------------------------

//   createMunAreaHeatmap(){
//     const anos = [2015, 2016, 2017, 2018, 2019, 2020]

//     let valuesObj = []
//     for (let ano of anos){
//       valuesObj.push(
//         this.getTotalAreaAndEmissionsByYear(ano)
//       )
//     }

//     const datasets = []
//     for (let i=0; i < this.municipiosData.length; i++){
//       datasets.push(
//         {
//           data: new Array(6).fill(1),
//           backgroundColor: this.generateDatasetColors(this.municipiosData[i].id_mun, valuesObj, 100000),
//           barPercentage: 1,
//           categoryPercentage: 1,
//           label: this.municipiosData[i].nome_mun,
//         }
//       )
//     }

//     const data = {
//       labels: ['2015', '2016', '2017', '2018', '2019', '2020'],
//       datasets: datasets
//     };
    
//     const config = {
//       type: 'bar',
//       data: data,
//       options: {
//         scales: {
//           xAxes: [{
//             stacked: true,
//           }],
//           yAxes: [{
//             stacked: true,
//             suggestedMax: 1,
//             ticks: {
//               callback: (val, index) => {
//                 try {
//                   return this.municipiosData[this.municipiosData.length - index].nome_mun // preenche de baixo pra cima sendo que dados de cima pra baixo, por isso nao usa [index]
//                 }
//                 catch(e) {
//                   return ''
//                 }
//               },
//             }
//           }]
//         },
//         tooltips: {
//           callbacks: {
//             title: (tooltipItems, data) => {
//               return this.municipiosData[tooltipItems[0].datasetIndex].nome_mun + ", " + tooltipItems[0].xLabel;
//             },
//             label: (tooltipItem, data) => {
//               let municipioIndex = 8 - tooltipItem.datasetIndex; // 8 hardcoded, botar length
//               if (municipioIndex > 8 - 1) { municipioIndex = 0 }
//               try {
//                 return String(Math.round(Number(this.munByYearArea[municipioIndex][tooltipItem.index])));
//               } catch(error){
//                 console.warn(error)
//                 return "ERRO"
//               }
//             }
//           }
//         },
//         title: {
//           display: true,
//           text: 'Área queimada total nos municípios',
//           fontSize: 14,
//         },
//       }     
//     };
    
//     const myChart = new Chart('munAreaHeatmap', config);
//   }

//   getMunByYearArea() {
//     let munByYearArea = []
//     this.municipiosData.forEach((mun) => {
//       const yearArea = mun.data.reduce((accum, record) => {
//         const ano = record.ano;
//         if (accum[ano]) {
//           accum[ano] += record.area_ha;
//         } else {
//           accum[ano] = record.area_ha;
//         }
//         return accum;
//       }, {});
//       munByYearArea.push(Object.values(yearArea));
//     });
//   return munByYearArea;
//   }

//   getMunByYearEmissions() {
//     let munByYearEmissions = []
//     this.municipiosData.forEach((mun) => {
//       const yearEmissions = mun.data.reduce((accum, record) => {
//         const ano = record.ano;
//         if (accum[ano]) {
//           accum[ano] += record.emissoes_tCO2;
//         } else {
//           accum[ano] = record.emissoes_tCO2;
//         }
//         return accum;
//       }, {});
//       munByYearEmissions.push(Object.values(yearEmissions));
//     });
//   return munByYearEmissions;
//   }

//   createMunEmissionsHeatmap(){
//     const anos = [2015, 2016, 2017, 2018, 2019, 2020]

//     let valuesObj = []
//     for (let ano of anos){
//       valuesObj.push(
//         this.getTotalAreaAndEmissionsByYear(ano)
//       )
//     }

//     const datasets = []
//     for (let i=0; i < this.municipiosData.length; i++){
//       datasets.push(
//         {
//           data: new Array(6).fill(1),
//           backgroundColor: this.generateDatasetColorsEmissions(this.municipiosData[i].id_mun, valuesObj, 2000000),
//           barPercentage: 1,
//           categoryPercentage: 1,
//           label: this.municipiosData[i].nome_mun,
//         }
//       )
//     }

//     const data = {
//       labels: ['2015', '2016', '2017', '2018', '2019', '2020'],
//       datasets: datasets
//     };
    
//     const config = {
//       type: 'bar',
//       data: data,
//       options: {
//         scales: {
//           xAxes: [{
//             stacked: true,
//           }],
//           yAxes: [{
//             stacked: true,
//             suggestedMax: 1,
//             ticks: {
//               callback: (val, index) => {
//                 try {
//                   return this.municipiosData[this.municipiosData.length - index].nome_mun // preenche de baixo pra cima sendo que dados de cima pra baixo, por isso nao usa [index]
//                 }
//                 catch(e) {
//                   return ''
//                 }
//               },
//             }
//           }]
//         },
//         tooltips: {
//           callbacks: {
//             title: (tooltipItems, data) => {
//               return this.municipiosData[tooltipItems[0].datasetIndex].nome_mun + ", " + tooltipItems[0].xLabel;
//             },
//             label: (tooltipItem, data) => {
//               let municipioIndex = this.municipiosData.length - tooltipItem.datasetIndex; // this.municipiosData.length hardcoded, botar length
//               if (municipioIndex > this.municipiosData.length - 1) { municipioIndex = 0 }
//               try {
//                 return String(Math.round(Number(this.munByYearEmissions[municipioIndex][tooltipItem.index])));
//               } catch(error){
//                 console.warn(error)
//                 return "ERRO"
//               }
//             }
//           }
//         },
//         title: {
//           display: true,
//           text: 'Emissões totais nos municípios (tCO2)',
//           fontSize: 14,
//         },
//       }     
//     };
    
//     const myChart = new Chart('munEmissionsHeatmap', config);
//   }


//   getTotalAreaAndEmissionsByYear(ano) {
//     const results = [];

//     for (const obj of this.municipiosData) {
//       const { id_mun, nome_mun, data: dataList } = obj;
//       let area_ha = 0;
//       let emissoes_tCO2 = 0;
  
//       for (const dataObj of dataList) {
//         if (dataObj.ano === ano) {
//           area_ha += dataObj.area_ha;
//           emissoes_tCO2 += dataObj.emissoes_tCO2;
//         }
//       }
  
//       results.push({
//         id_mun,
//         nome_mun,
//         results: { area_ha, emissoes_tCO2 },
//       });
//     }
  
//     return results;
//   }

//   generateDatasetColors(idMun:number, valuesObj, scale: number): string[] {

//       let colors: string[] = [];
//       const rgb = [221, 125, 0]; // tirar daqui
    
//       for (let i = 0; i < valuesObj.length; i++) {
//         for (let j = 0; j < valuesObj[i].length; j++){
//           if (valuesObj[i][j].id_mun == idMun){
//             const value = valuesObj[i][j].results.area_ha;
//             let opacity = value / scale;
//             if (opacity > 1) {
//               opacity = 1;
//             }
//             colors.push(`rgba(${rgb.join()}, ${opacity})`);
//           }
//         }
//       }
    
//       return colors;
//   }

//   generateDatasetColorsEmissions(idMun:number, valuesObj, scale: number): string[] {

//     let colors: string[] = [];
//     const rgb = [0, 175, 221]; // tirar daqui
  
//     for (let i = 0; i < valuesObj.length; i++) {
//       for (let j = 0; j < valuesObj[i].length; j++){
//         if (valuesObj[i][j].id_mun == idMun){
//           const value = valuesObj[i][j].results.emissoes_tCO2;
//           let opacity = value / scale;
//           if (opacity > 1) {
//             opacity = 1;
//           }
//           colors.push(`rgba(${rgb.join()}, ${opacity})`);
//         }
//       }
//     }
  
//     return colors;
// }
  

}
