import { Component, OnInit, OnDestroy, signal, computed } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { UserDataService } from '../../services/user-data.service';
import { PresetpickerService } from '../../services/presetpicker.service';
import { GeoService } from '../../services/geo.service';
import { getCamAngularViewDeg } from 'src/app/services/cam.store';
import { MatDialog } from '@angular/material/dialog';
import { AutoPresetGeneratorDialog } from 'src/app/shared/dialogs/auto-preset-generator/auto-preset-generator-dialog';
import { InfoDialog } from 'src/app/shared/dialogs/info-dialog/info-dialog';
import { IAutoPresetGeneratorDTO } from 'src/app/shared/interfaces/IAutoPresetGeneratorDTO';
import { DEFAULT_MIN_FOCAL_DISTANCE_MM, DEFAULT_SENSOR_WIDTH_MM } from 'src/app/interface/services/cameras.service';
import { PresetStatusOptions } from 'src/app/shared/enums/PresetStatusOptions';
import deepCopy from 'src/app/shared/utils/deepCopy';
import { PresetConfigInputTypeOptions } from 'src/app/shared/enums/PresetConfigInputTypeOptions';
import { SimulatePresetsDialog } from 'src/app/shared/dialogs/simulate-presets-dialog/simulate-presets-dialog';

const DEFAULT_NEW_PRESET_OBJECT = {
  index: 1,
  pan: 0,
  tilt: 85,
  zoom: 1,
  status: PresetStatusOptions.NEW
}

@Component({
  selector: 'app-presets',
  templateUrl: './presets.component.html',
  styleUrls: ['./presets.component.scss']
})
export class PresetsComponent implements OnInit, OnDestroy {
  constructor(
    private http:HttpService,
    public user:UserDataService,
    private geo:GeoService,
    private dialog: MatDialog,
    private pp:PresetpickerService
  ) {
    this.pp.presetSignal$.subscribe((signal) => {
      this.selectRow(signal);
    });
  }
  
  public cameras:any[]
  public displayedColumns:string[] = [
    'index',
    'pan',
    'tilt',
    'zoom',
    'fov',
    'masks',
    'actions'
  ];
  public selectedPresetSource:string = 'presets_p2'
  public selectedCam: any = null;
  public presetActiveIndex:number;
  public canvas_el: any;
  public canvas_context: any;
  public imagesUrl:string[]
  public hasChangesPresetsConfigs = signal<boolean>(false);
  public userUpdatesOnPresetsConfigs = signal<any[]>(null);
  public presetsList: any;
  public deletedPresets = signal<any[]>([]);
  public isUpdatingPresetsConfigsState = signal<boolean>(false);
  public isSavingPresetsConfigsChangesState = signal<boolean>(false);
  public shouldPointCameraOnPresetSelection = signal<boolean>(false);
  public isSimulatingPresets = signal<boolean>(false);

  async ngOnInit(){
    this.getCams();
  }

  ngOnDestroy() {
    this.clearPresets();
  }

  async getCams(){
    this.cameras = this.user.getDadosPantera('cameras');
    this.cameras.sort((c1, c2) => c1.id - c2.id)
    this.selectedCam = this.cameras[0];
    this.createIndexForCamerasData();
    this.undoCurrentChangesPresetsList();
    await this.getPresetImages();
  }

  setIsSavingPresetsConfigsChangesState(value: boolean) {
    this.isSavingPresetsConfigsChangesState.set(value)
  }

  undoCurrentChangesPresetsList() {
    this.resetPresetsDeleteList();
    this.presetsList = deepCopy(this.selectedCam[this.selectedPresetSource]);
  }
  
  filterDeletedPresetsFromList() {
    this.presetsList = this.presetsList.filter((elm) => elm.status != 'deleted')
  }

  async getPresetImages(){
    let presetsData = await this.http.centralGet('v3/get_presets_images_url', [`${this.selectedCam.id}`]);
    this.imagesUrl = presetsData.map((p)=>p.img_url);
  }

  savePresetImages(){
    if (this.hasChangesPresetsConfigs()){
      return this.openInfoDialog("Salve as alterações na tabela antes de tirar fotos dos presets")
    }
    this.pp.updatePresetsImgCam(this.selectedCam.id, this.selectedCam[this.selectedPresetSource])
    .then(()=>{
      this.requestSyncDataWithMaestro();
      this.getPresetImages()
      return this.openInfoDialog("Salvamento de imagens de presets finalizado!")
    });
  }

  addOrUpdatePresetImage(ev: MouseEvent, preset: any){
    if (preset.status) {
      return this.openInfoDialog("Salve as alterações na tabela antes de tirar fotos dos presets")
    }

    ev.stopPropagation();
    this.pp.updatePresetsImgCam(this.selectedCam.id, [this.selectedCam[this.selectedPresetSource][preset.index - 1]])
    .then(()=>{
      this.requestSyncDataWithMaestro();
      this.getPresetImages()
    });
  }

  async changeCam(){
    this.undoCurrentChangesPresetsList();

    if (this.hasChangesPresetsConfigs()) {
      this.setHasChangesPresetsConfigsState(false);
    }
    
    this.geo.centerOnObject({ lat: this.selectedCam.lat, lng: this.selectedCam.lon })
    await this.getPresetImages();
  }

  drawAllPresets(){
    let presetList = this.getSelectedPreset();
    this.geo.drawAllPresets(this.selectedCam, presetList);
  }

  clearPresets(){
    this.geo.clearDrawings();
  }

  selectRow(preset) {
    if (this.presetActiveIndex !== preset.index) {
      this.presetActiveIndex = preset.index;
      if (this.shouldPointCameraOnPresetSelection()) {
        this.pp.requestPTZPosition(preset, this.selectedCam.id)
      }
      this.geo.drawPreset(this.selectedCam, preset);
      return 
    } 
    this.presetActiveIndex = null;
    this.clearPresets();
  }

  checkInitActiveIndex(){
    if (!this.presetActiveIndex){
      this.presetActiveIndex = 0;
    }
  }

  getMaxZoom(){
    // getMaxZoom for color gradient
    let zoomList = this.getSelectedPreset().map(p => p.zoom)
    return Math.max(...zoomList) || 30
  }

  nextPosition(){
    this.checkInitActiveIndex();
    this.presetActiveIndex++;
    if (this.presetActiveIndex > this.getSelectedPreset().length){
      this.presetActiveIndex = 1;
    }
    let preset_reference = this.getSelectedPreset()[this.presetActiveIndex -1];

    this.geo.drawPreset(this.selectedCam, preset_reference)
  }

  previousPosition(){
    this.checkInitActiveIndex();
    this.presetActiveIndex--;
    if (this.presetActiveIndex == 0){
      this.presetActiveIndex = this.getSelectedPreset().length;
    }
    let preset_reference = this.getSelectedPreset()[this.presetActiveIndex -1];
    this.geo.drawPreset(this.selectedCam, preset_reference)
  }

  getFov(zoom){
    if (zoom == 0) zoom = 1;
    return getCamAngularViewDeg(zoom, this.selectedCam.min_focal_distance_mm, this.selectedCam.sensor_width_mm)
  }

  getCountMasks(labels){
    if (labels == null) return 0;
    else return labels.filter(label => label.class === 'preset_mask').length;
  }

  drawRect() {
    try {
      this.canvas_el = document.getElementById('presetCanvas');
      this.canvas_el.width = this.canvas_el.getBoundingClientRect().width;
      this.canvas_el.height = this.canvas_el.getBoundingClientRect().height;
      this.canvas_context = this.canvas_el.getContext('2d');
  
      this.canvas_context.clearRect(0, 0, this.canvas_el.width, this.canvas_el.height);
      this.canvas_context.beginPath();
  
      this.canvas_context.lineWidth = '1';
      this.canvas_context.strokeStyle = 'black';
  
      var preset = this.selectedCam[this.selectedPresetSource][this.presetActiveIndex - 1];
      if (preset && preset.labels) {
        var masks = preset.labels.filter(label => label.class === 'preset_mask');
        masks.forEach(mask => {
          let bbox = mask.bbox;
          const x1 = bbox[0] * this.canvas_el.width;
          const y1 = bbox[1] * this.canvas_el.height;
          const rect_width = bbox[2] * this.canvas_el.width - x1;
          const rect_height = bbox[3] * this.canvas_el.height - y1;
  
          this.canvas_context.rect(x1, y1, rect_width, rect_height);
        });
        this.canvas_context.stroke();
        this.canvas_context.closePath();
      }
    } catch (error) {
      console.log(`drawRect ${error}`, error);
    }
  }

  getSelectedPreset(){
    return this.selectedCam[this.selectedPresetSource]
  }

  // not used yet
  unselectRow(){
    this.presetActiveIndex = null;
  }

  rowSelected(){
    return this.presetActiveIndex != null;
  }

  setHasChangesPresetsConfigsState(value: boolean) {
    this.hasChangesPresetsConfigs.set(value)
  }

  isArrayLastElement(element: any, arrayToVerify: any[]) {
    let isLast = false;
    if (element == arrayToVerify[arrayToVerify.length - 1]) {
      isLast = true
    }
    return isLast
  }

  deletePreset(ev: MouseEvent, presetToDelete: any) {
    ev.stopPropagation();
    if (presetToDelete.index == this.presetActiveIndex) this.unselectRow();
    if (!this.hasChangesPresetsConfigs()) this.setHasChangesPresetsConfigsState(true);
    this.addPresetToDeleteList(presetToDelete);

    this.presetsList.forEach(preset => {
      if (preset.index == presetToDelete.index) {
        let auxDeepCopyPresetsList = deepCopy(this.presetsList)
        preset.status = PresetStatusOptions.DELETED

        if (!this.isArrayLastElement(presetToDelete, auxDeepCopyPresetsList)) {
          // it's a new copy - the first one is only used to check the last element
          // this one above is used to remove an item placed in the middle of the list
          auxDeepCopyPresetsList = deepCopy(this.presetsList);
          auxDeepCopyPresetsList.splice(preset.index - 1, 1)
          this.presetsList = auxDeepCopyPresetsList;
          this.createIndexForCurrentPresetsList();
        }
        else {
          this.filterDeletedPresetsFromList();
        }
      }
    })
  }

  createIndexForCamerasData(){
    this.cameras.forEach(cam => {
      cam[this.selectedPresetSource].forEach((preset, index) => {
        preset.index = index + 1;
      });
    });
  }

  createIndexForCurrentPresetsList(){
    this.presetsList.forEach((preset, index) => {
      preset.index = index + 1;
    });
  }

  addPreset() {
    let newPresetIndex = this.presetsList.length + 1;
    let newPresetObject = { ...DEFAULT_NEW_PRESET_OBJECT, index: newPresetIndex}

    if (this.presetActiveIndex) {
      newPresetObject.index = this.presetActiveIndex + 1;
      let copy = deepCopy(this.presetsList)
      copy.splice(this.presetActiveIndex, 0, newPresetObject)
      this.presetsList = copy;
      this.unselectRow();
      this.createIndexForCurrentPresetsList()
    }
    else {
      this.presetsList = [...this.presetsList, newPresetObject]
    }

    this.setHasChangesPresetsConfigsState(true);
  }

  sortPresetsListByPanValue(presetsList: any[]) {
    return presetsList.sort((a, b) => a.pan - b.pan);
  }

  toggleUpdatePresetConfigs() {
    this.isUpdatingPresetsConfigsState.update(state => !state);
  }

  toggleShouldPointCameraOnPresetSelection() {
    this.shouldPointCameraOnPresetSelection.update(state => !state);
  }

  runAutoPresetGenerator() {
    const dialogRef = this.dialog.open(AutoPresetGeneratorDialog)
    dialogRef.afterClosed().subscribe((confirmation: IAutoPresetGeneratorDTO) => {
      if (confirmation) {
        const minFocalDistance = this.selectedCam.min_focal_distance_mm || DEFAULT_MIN_FOCAL_DISTANCE_MM;
        const sensorWidth = this.selectedCam.sensor_width_mm || DEFAULT_SENSOR_WIDTH_MM;
        const fov = getCamAngularViewDeg(confirmation.zoomLevel, minFocalDistance, sensorWidth)
        
        const numberOfPresets = Math.ceil(360/fov);
        const deltaPresets = Math.round(360/numberOfPresets);
        let rawPan = [...Array(numberOfPresets).keys()];

        let autoPresets = rawPan.map( n => {
          return{
            index:n+1,
            pan:(confirmation.initialAngle + n*deltaPresets) % 360,
            tilt: confirmation.tiltAngle,
            zoom: confirmation.zoomLevel,
            status: PresetStatusOptions.NEW
          }
        })    

        this.presetsList = deepCopy(this.sortPresetsListByPanValue([...this.presetsList, ...autoPresets]));
        this.setHasChangesPresetsConfigsState(true);
        this.createIndexForCurrentPresetsList();
      }
    })
  }

  stopSimulatingPresets() {
    this.updateIsSimulatingPresets(false);
  }

  async simulatePresets() {
    if (this.presetsList.length == 0) {
      return this.openInfoDialog("Não há presets salvos")
    }

    const dialogRef = this.dialog.open(SimulatePresetsDialog);
    dialogRef.afterClosed().subscribe(async (response) => {
      if (response) {
        if (this.pp.timeBetweenPositionsOnPresetsSimulator() < 3 || this.pp.timeBetweenPositionsOnPresetsSimulator() > 10) {
          this.openInfoDialog("Escolha valores entre 3 e 10");
          return
        }

        this.updateIsSimulatingPresets(true);

        let auxPresetsList = deepCopy(this.presetsList);
        if (this.presetActiveIndex) {
          let elementsBeforeSelectedPreset = this.presetsList.slice(0, this.presetActiveIndex - 1)
          let elementsAfterSelectedPreset = this.presetsList.slice(this.presetActiveIndex - 1, this.presetsList.length)
          auxPresetsList = [...elementsAfterSelectedPreset, ...elementsBeforeSelectedPreset]
        }
        
        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        for (const preset of auxPresetsList) {
          if (this.isSimulatingPresets()) {
            this.selectRow(preset);
            await this.pp.requestPTZPosition(preset, this.selectedCam.id);
            await delay(1000 * this.pp.timeBetweenPositionsOnPresetsSimulator());
          }
        }
        this.updateIsSimulatingPresets(false);
      }
    })
  }
  
  async saveChangesOnPresets() {
    this.setIsSavingPresetsConfigsChangesState(true);
    let hasActualChanges = false;

    // verifying if any preset was deleted
    if (this.deletedPresets().length > 0) {
      hasActualChanges = true;
    }

    // verifying new and changed presets
    const initialPresetsListWithChanges = this.presetsList.filter((preset) => preset.status);

    initialPresetsListWithChanges.forEach(preset => {
      if (preset.status == PresetStatusOptions.NEW){
        hasActualChanges = true;
      }

      else if(preset.status == PresetStatusOptions.CHANGED) {
        const originalPreset = this.selectedCam[this.selectedPresetSource].filter(presetFromOriginalArray => presetFromOriginalArray.uuid == preset.uuid)[0]
        if (!this.areThesePresetsEquals(preset, originalPreset)) {
          hasActualChanges = true;
        }
        else {
          delete this.presetsList[preset.index - 1].status
        }
      }
    });

    if (hasActualChanges) {
      let finalPresetsListWithChanges = this.presetsList.filter((preset) => preset.status);
      finalPresetsListWithChanges = [...finalPresetsListWithChanges, ...this.deletedPresets()]
      const backendResponse = await this.http.post(this.user.getBackendURL(), ["maestro", "update_cam_presets"], { id: this.selectedCam.id, presets: finalPresetsListWithChanges });   
      if (backendResponse.status) {
        this.presetsList.forEach(preset => delete preset.status);
        this.presetsList = this.sortPresetsListByPanValue(this.presetsList);
        this.selectedCam[this.selectedPresetSource] = deepCopy(this.presetsList);
        this.setIsSavingPresetsConfigsChangesState(false);
        if (this.hasChangesPresetsConfigs) this.setHasChangesPresetsConfigsState(false);
        this.resetPresetsDeleteList();
        return this.openInfoDialog("Sucesso ao salvar alterações no banco de dados")
      }

      if (this.hasChangesPresetsConfigs) this.setHasChangesPresetsConfigsState(false);
      this.setIsSavingPresetsConfigsChangesState(false);
      this.resetPresetsDeleteList();
      return this.openInfoDialog("Erro ao salvar alterações no banco de dados")
    }
  }

  areThesePresetsEquals(preset1, preset2) {
    let equals = true;
    if (preset1.pan != preset2.pan) equals = false;
    if (preset1.tilt != preset2.tilt) equals = false;
    if (preset1.zoom != preset2.zoom) equals = false;
    return equals
  }

  requestSyncDataWithMaestro() {
    return this.http.get(this.user.getBackendURL(), ['maestro','atualizar_dados_cliente'])
  }

  openInfoDialog(content: string) {
    return this.dialog.open(InfoDialog, {
        data: { text: content }
    })
  }

  addPresetToDeleteList(preset: any) {
    this.deletedPresets.update(state => [...state, preset])
  }

  resetPresetsDeleteList() {
    this.deletedPresets.set([])
  }

  updateInput(index: number, inputType: string){
    if (!this.presetInputGenericValidation(index, inputType)) {
      this.presetsList[index-1][inputType] = this.selectedCam[this.selectedPresetSource][index-1][inputType];
      return
    }
    const selectedPresetStatus = this.presetsList[index-1].status
    const isNew = (selectedPresetStatus == PresetStatusOptions.NEW);
    this.presetsList[index-1].status = isNew ?  PresetStatusOptions.NEW : PresetStatusOptions.CHANGED;
    this.setHasChangesPresetsConfigsState(true);
  }

  presetInputPanValidator(value: number) {
    let isValid = true;
    if (value < 0) {
      isValid = false;
      this.openInfoDialog("Digite apenas valores positivos")
    }
    else if (value >= 360) {
      isValid = false;
      this.openInfoDialog("Digite apenas valores entre 0 e 359,99")
    }
    if (Number.isNaN(value)) {
      isValid = false;
      this.openInfoDialog("Não deixe o campo em branco")
    }
    return isValid;
  }

  presetInputTiltValidator(value: number) {
    let isValid = true;
    if (value < 0) {
      isValid = false;
      this.openInfoDialog("Digite apenas valores positivos")
    }
    else if (value > 120) {
      isValid = false;
      this.openInfoDialog("Digite apenas valores entre 0 e 120")
    }
    if (Number.isNaN(value)) {
      isValid = false;
      this.openInfoDialog("Não deixe o campo em branco")
    }
    return isValid;
  }

  presetInputZoomValidator(value: number) {
    let isValid = true;
    if (value < 1) {
      isValid = false;
      this.openInfoDialog("Digite apenas valores positivos a partir de 1")
    }
    else if (value > 45) {
      isValid = false;
      this.openInfoDialog("Digite apenas valores entre 1 e 45")
    }
    if (Number.isNaN(value)) {
      isValid = false;
      this.openInfoDialog("Não deixe o campo em branco")
    }
    return isValid;
  }

  presetInputGenericValidation(index: number, inputType: string) {
    let isValid = true;

    if (inputType == PresetConfigInputTypeOptions.PAN) {
      isValid = this.presetInputPanValidator(this.presetsList[index - 1].pan)
      return isValid
    }
    
    if (inputType == PresetConfigInputTypeOptions.TILT) {
      isValid = this.presetInputPanValidator(this.presetsList[index - 1].tilt)
      return isValid
    }
    if (inputType == PresetConfigInputTypeOptions.ZOOM) {
      isValid = this.presetInputPanValidator(this.presetsList[index - 1].zoom)
      return isValid
    }
  }

  updateIsSimulatingPresets(value: boolean) {
    this.isSimulatingPresets.set(value)
  }
}