import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '../../services/amplitude.service';

const REGEX_STRING_GENERICA = /^(?!\s)(?!.*\s{2,})(?!.*\s$).{1,50}$/ // sem caractares vazios no começo e no fim, nem 2 seguidos no meio, max 50 caracteres
interface ViewOption {
  id: number;
  option: string;
  icon: string;
}
interface Contato {
  id_contato: string;
  nome: string;
  telefone: string;
  complemento: string;
}

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  

  public contatos: Contato[] = [];
  public contatosTableColumns: string[] = [
    'nome',
    'telefone',
    'complemento',
    'acao',
  ];
  public contactViewFormControl = new UntypedFormControl('');
  public contactViewOptions: ViewOption[] = [
    { id: 1, option: "MAP.LIST_CONTACTS", icon: 'list' },
    { id: 2, option: "MAP.REGISTER_CONTACT", icon: 'add_call' }
  ];
  public selectedContactViewOption: number = this.contactViewOptions[0].id;
  public nameFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(REGEX_STRING_GENERICA)
  ]);
  public telefoneFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(/^(?:\D*\d){8}.*$/) // pelo menos 8 numeros mas sem engessar formato
  ]);
  public complementoFormControl = new UntypedFormControl('', [
    Validators.pattern(REGEX_STRING_GENERICA)
  ]);
  public newContactName: string;
  public newContactTelefone: string;
  public newContactComplemento: string | null = null;

  constructor(
    private http: HttpService,
    public user: UserDataService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private amplitude: AmplitudeService,
  ) { }

  ngOnInit(): void {
    this.getContatos();
  }

  async getContatos(){
    let response = await this.http.centralGet(`get_contatos/${this.user.getIdPlanta()}`);
    if (response?.length) {
      this.contatos = response;
    }
  }

  removeContato(contato){
      const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {text:`${this.translate.instant("MAP.DELETE_CONTACT_DIALOG")}`}
    })
    
    dialogRef.afterClosed().subscribe(async (confirmation) => {
      if (!confirmation) return
      this.contatos = this.contatos.filter(d => d != contato)
      await this.postRemoveContato(contato)
    })
  }

  async postRemoveContato(contato: Contato){
    if (contato.id_contato){
      let response = await this.http.maestroPost('remove_contato', contato)
      this.openResponseDialog(response, contato)
    }
  }

  async postContato(){
    let contatoObj = {
      id_planta: this.user.getIdPlanta(),
      nome: this.newContactName,
      telefone: this.newContactTelefone,
      complemento: this.newContactComplemento
    }
    let response = await this.http.maestroPost('insert_contato', contatoObj)
    this.openResponseDialog(response, contatoObj)
    for (let formControl of [this.nameFormControl, this.telefoneFormControl, this.complementoFormControl]) formControl.reset();
    await this.getContatos()
  }

  openResponseDialog(response, contato){
    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.CONTACTS_UPDATED_DIALOG")}`}
      });
      if (contato['id_contato']) {
        this.amplitude.sendEvent('Removeu Recurso', {"Recurso": this.amplitude.resourceName['contact'], "Nome": contato['nome'], "ID": contato['id_contato']});
      } else {
        this.amplitude.sendEvent('Cadastrou Recurso', {"Recurso": this.amplitude.resourceName['contact'], "Nome": contato['nome']});
      }
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR_ON_UPDATING_CONTACTS_DIALOG")}`}
      });
      console.warn(`${this.translate.instant("MAP.ERROR")}: ${response?.msg}`)
    }
  }

  sortData(sort: Sort) {
    const data = this.contatos.slice()

    if (!sort.active || sort.direction === '') {
      this.contatos = data;
    } else {
      this.contatos = data.sort((a, b) => {
        const aValue = (a as any)[sort.active].toLowerCase();
        const bValue = (b as any)[sort.active].toLowerCase();
        return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }

  amplitudeSubTabEvent(selectedSubTab){
    this.amplitude.sendSubTabViewEvent('brigades', 'MAP.CONTACTS', this.contactViewOptions, selectedSubTab, 'option')
  }

}
