import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { UserDataService } from './user-data.service';


@Injectable({
  providedIn: 'root'
})
export class Httpv2Service {

  private authHeaders: HttpHeaders;
  private backendP2Url: string;

  constructor(
    private httpClient: HttpClient,
    private userDataService: UserDataService
  ) { 
    if (this.userDataService.isOperatorP2() || this.userDataService.isSupportP2()) {
      this.getBackendP2Url();
      this.initAuthHeaders();
    }
  }

  /**
   * ### Métodos GET
   */
  
  getFromBackendP2<T>(route: string, params?: HttpParams): Observable<T> {
    return this.httpClient.get<T>(this.backendP2Url + route, { headers: this.authHeaders, params });
  }

  async getFromBackendP2Promise<T>(route: string, params?: HttpParams): Promise<T> {
    return lastValueFrom(this.getFromBackendP2<T>(route, params));
  }

  /**
   * ### Métodos POST
   */

  postToBackendP2<T>(route: string, body: any, params?: HttpParams): Observable<T> {
    return this.httpClient.post<T>(this.backendP2Url + route, body, { headers: this.authHeaders, params });
  }

  async postToBackendP2Promise<T>(route: string, body: any, params?: HttpParams): Promise<T> {
    return lastValueFrom(this.postToBackendP2<T>(route, body, params));
  }

  /**
   * ### Métodos auxiliares
   */

  private initAuthHeaders(): void {
    const token = this.userDataService.getBackendP2Token();
    this.authHeaders = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });
  }

  private getBackendP2Url(): void {
    this.backendP2Url = this.userDataService.BackendP2Url;
  }
}
