<main>

  <mat-tab-group id="group" (selectedTabChange)="amplitudeTabEvent($event['index'])">

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">fire_truck</mat-icon>
        {{ 'MAP.BRIGADES' | translate }}
      </ng-template>

      @if (loaded) {
        <div class="tab-content-container">
          <div class="option-select">
            <mat-button-toggle-group [(ngModel)]="selectedBrigadeViewOption" name="option" [formControl]="brigadeViewFormControl" (change)="amplitudeSubTabEvent($event.value)">
              @for (option of brigadeViewOptions; track option.id){
                <mat-button-toggle [value]="option.id" [disabled]="option.id == 2 && !user.isManager()">
                  <mat-icon>{{option.icon}}</mat-icon> {{ option.option | translate }}
                </mat-button-toggle>
              }
            </mat-button-toggle-group>
            @if(brigades.brigades_data?.length && selectedBrigadeViewOption == 1){
              <div class = "filter">
                <button mat-icon-button [matMenuTriggerFor]="filtersMenu" [matBadge]="searchField ? '!' : ''"
                matBadgePosition="above after"
                matBadgeColor="warn"
                [matBadgeHidden]="!searchField"
                matTooltip="{{ 'MAP.FILTERS' | translate }}"
                matBadgeSize="small">
                  <mat-icon>tune</mat-icon>
                </button>
          
                <mat-menu #filtersMenu="matMenu">
                  <div class="search" (click)="$event.stopPropagation()">
                    <mat-form-field class="brigade-search" appearance="fill">
                      <input matInput type="text" placeholder="{{  'MAP.SEARCH' | translate }}" [(ngModel)]="searchField">
                    </mat-form-field>
                  </div>
                </mat-menu>
              </div>
            }
          </div>

          @if (!brigades.brigades_data?.length && selectedBrigadeViewOption == 1) {
            <div class="sem-dados">
              {{ 'MAP.NO_TEAMS_REGISTERED' | translate }}
            </div>
          }
          @if (brigades.brigades_data?.length && selectedBrigadeViewOption == 1) {
            <div class="table-container">
              <table mat-table [dataSource]="brigades.brigades_data | searchBrigade: searchField" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8 brigade-table" matSortActive="status_brigada" matSortDirection="asc" matSortDisableClear="true" multiTemplateDataRows>
                <ng-container matColumnDef="status_brigada">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status_brigada" class="status-column-header"> Status </th>
                  <td mat-cell *matCellDef="let element" class="status-column-cell">
                    @if(element.statusIcon){
                      <img class='brigade-status' [src]="element.statusIcon" [matTooltip]="element.statusTooltip | translate">
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="categoria_brigada">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="categoria_brigada"> {{ 'MAP.CATEGORY' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    @if (!editState[element.id_brigada]){
                      <div>
                        {{element.categoria_brigada}}
                      </div>
                    } @else {
                      <input type="text" matInput [(ngModel)]="element.categoria_brigada" [readonly]="!editState[element.id_brigada]" required (click)="$event.stopPropagation()"/>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="tipo_brigada">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="tipo_brigada"> {{ 'MAP.VEHICLE_TYPE' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    @if (!editState[element.id_brigada]){
                      <div>
                        <img [src]="element.icon_tipo_brigada" alt="Veiculo">
                      </div>
                    } @else {
                      <mat-form-field appearance="outline" id="edit-tipo-brigada-form">
                        <mat-select [(ngModel)]="element.id_tipo_brigada" (selectionChange)="onBrigadeTypeChange(element, $event.value)" [formControl]="editTypeFormControl" (click)="$event.stopPropagation()">
                          <mat-select-trigger>
                            <img [src]="brigadeIconMap[editTypeFormControl.value]" alt="Veiculo">
                          </mat-select-trigger>
                          @for (type of types; track type.id){
                            <mat-option [value]="type.id">
                              <div class="fx-row-center">
                                <div class="register-resource-icon-wrapper">
                                  <img [src]="brigadeIconMap[type.id]" alt="Veiculo">
                                </div>
                              </div>
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="nome_brigada">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="nome_brigada"> {{ 'MAP.NAME' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    @if (!editState[element.id_brigada]){
                      <div>
                        {{element.nome_brigada}}
                      </div>
                    } @else {
                      <input type="text" matInput [(ngModel)]="element.nome_brigada" [readonly]="!editState[element.id_brigada]" required (click)="$event.stopPropagation()"/>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="placa">
                  <th mat-header-cell *matHeaderCellDef> {{ 'MAP.LICENSE_PLATE' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    @if (!editState[element.id_brigada]){
                      <div>
                        {{element.placa}}
                      </div>
                    } @else {
                      <input type="text" matInput [(ngModel)]="element.placa" [readonly]="!editState[element.id_brigada]" (click)="$event.stopPropagation()"/>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td class="arrows" mat-cell *matCellDef="let element">
                    <button [matTooltip]="expandedElement === element ? ' ' : 'REP.ADDITIONAL_DATA'| translate   " mat-icon-button (click)="expandedElement = expandedElement === element ? null : element; selectBrigade(element.id_brigada); $event.stopPropagation()">
                      @if (expandedElement === element ) {
                        <mat-icon>keyboard_arrow_up</mat-icon>
                      } @else {
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      }
                    </button>
                  </td>
                </ng-container>
          
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="brigadesTableColumns.length + 1">
                    <div class="detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <div class="description">
                        <div class="obs-brigada-container">
                          @if (element.obs_brigada && brigadeObsKeys[element.id_brigada].length > 0) {
                              @for (key of brigadeObsKeys[element.id_brigada]; track key) {
                                  <div class="obs-brigada">
                                      @if (!editState[element.id_brigada]){
                                          <strong class="obs-brigada-key">{{ key }}:</strong>
                                          <span class="obs-brigada-value">{{ element.obs_brigada[key] }}</span>
                                      } @else {
                                          <div class="input-delete-container">
                                              <input class="input-obs-brigade" type="text" matInput [(ngModel)]="editKeys[element.id_brigada][key]" />
                                              <input class="input-obs-brigade" type="text" matInput [(ngModel)]="element.obs_brigada[key]" />
                                              <button mat-icon-button (click)="removeObsBrigada(element.id_brigada, key)">
                                                  <mat-icon class="delete-button">delete</mat-icon>
                                              </button>
                                          </div>
                                      }
                                  </div>
                              }
                          }@else{
                            @if(!editState[element.id_brigada] && user.isManager()){
                              <span class="obs-brigada">{{ 'MAP.BRIGADE_NO_DATA_MNG' | translate}}</span>
                            }
                            @if(!user.isManager()){
                              <span class="obs-brigada">{{ 'MAP.BRIGADE_NO_DATA_OPERATOR' | translate}}</span>
                            }
                          }
                          @if (editState[element.id_brigada]){
                              <div class="obs-brigada add-obs-brigade-container" [formGroup]="form">
                                  <div formArrayName="obs_brigade">
                                    @for (item of obs_brigade.controls; track item) {
                                      <div class="input-cancel-container" [formGroupName]="$index">
                                          <input matInput class="input-obs-brigade" placeholder="Ex: Empresa" formControlName="Chave" type="text"/>
                                          <input matInput class="input-obs-brigade" placeholder="Ex: umgrauemeio" formControlName="Valor" type="text"/>
                                          <button color="primary" mat-icon-button (click)="deleteNewObsBrigada(obs_brigade.controls.indexOf(item))">
                                              <mat-icon class="cancel-button">close</mat-icon>
                                          </button>
                                      </div>
                                  }
                                  </div>
                                  <div class="fixed-add-button-container">
                                    <button matTooltip="{{ 'MAP.ADD_DATA' | translate}}" mat-icon-button (click)="addObsBrigada()">
                                        <mat-icon>add</mat-icon> 
                                    </button>
                                </div>
                              </div>
                              @if(checkDuplicate()){
                                <span class ="duplicate-warn">{{ 'MAP.KEYS_CANNOT_BE_DUPLICATED' | translate}}</span>
                              }
                          }
                      </div>
                      
                        <div class="edit-actions-container">
                          @if ((user.isManager()) && element.id_brigada){
                            <div>
                              <div class="fx-row-center">
                                <button [disabled]="disableSave(element) || checkDuplicate()" mat-icon-button (click)="toggleEdit(element)" [matTooltip]="editState[element.id_brigada] ? ('MAP.SAVE' | translate) : ('MAP.EDIT' | translate)">
                                  <mat-icon class="edit-resource">{{ editState[element.id_brigada] ? 'save' : 'edit' }}</mat-icon>
                                </button>
                                <button color="primary" mat-icon-button (click)="removeBrigadeOrCancelEdit(element)" [matTooltip]="editState[element.id_brigada] ? ('MAP.CANCEL' | translate) : ('MAP.DELETE' | translate)">
                                  <mat-icon class="delete-button">{{ editState[element.id_brigada] ? 'block' : 'delete' }}</mat-icon>
                                </button>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="brigadesTableColumnsWithExpand"></tr>
                <tr mat-row *matRowDef="let row; columns: brigadesTableColumnsWithExpand" class="element-row table-row" [ngClass]="{'table-row-color': row.id_brigada == layer.selectedBrigadeId}" [class.expanded-row]="expandedElement === row" (click)="selectBrigade(row.id_brigada)">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
              </table>
            </div>
          }
          
          @if (selectedBrigadeViewOption == 2) {
            <mat-card appearance="outlined" class="cadastro">
              <mat-card-title>{{ 'MAP.REGISTER_NEW_BRIGADE' | translate }}</mat-card-title>
              <div class="fx-column-space-around-center" style="margin-top:1rem">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'MAP.CATEGORY' | translate }}</mat-label>
                  <input type="text" matInput [formControl]="categoryFormControl"
                    [(ngModel)]="newBrigadeCategory">
                    @if (categoryFormControl.hasError('pattern')) {
                      <mat-error>{{ 'MAP.ENTER_TEAM_CATEGORY' | translate }}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'MAP.VEHICLE_TYPE' | translate }}</mat-label>
                  <mat-select [(ngModel)]="selectedType" name="type" [formControl]="typeFormControl">
                    @for (type of types; track type.id){
                      <mat-option [value]="type.id">
                        <div class="fx-row-center">
                          <div class="register-resource-icon-wrapper">
                            <img [src]="brigadeIconMap[type.id]" alt="Veiculo">
                          </div>
                          <div>
                            {{'BG.' + type.id | translate}}
                          </div>
                        </div>
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'MAP.NAME' | translate }}</mat-label>
                  <input type="text" matInput [formControl]="nameFormControl"
                    [(ngModel)]="newBrigadeName">
                    @if (nameFormControl.hasError('pattern')) {
                      <mat-error>{{ 'MAP.ENTER_TEAM_NAME' | translate }}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'MAP.LICENSE_PLATE' | translate }}</mat-label>
                  <input type="text" matInput [formControl]="placaFormControl"
                    [(ngModel)]="placa">
                </mat-form-field>
                @if(plateAlreadyRegistered() || brigadeNameAlreadyRegistered()){
                  <p class="brigade-registered">{{ 'MAP.BRIGADE_ALREADY_REGISTERED' | translate }}</p>
                }
                <div class="button-wrapper">
                  <button mat-raised-button color='primary' (click)="postBrigade()"
                    [disabled]="nameFormControl.hasError('pattern') || nameFormControl.hasError('required') || typeFormControl.hasError('required') ||
                    categoryFormControl.hasError('pattern') || categoryFormControl.hasError('required') || plateAlreadyRegistered() || brigadeNameAlreadyRegistered()">
                    {{ 'MAP.REGISTER_BRIGADE' | translate }}
                  </button>
                </div>
              </div>
            </mat-card>
          }
        </div>
      }
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">fmd_good</mat-icon>
        {{ 'MAP.POINTS_OF_INTEREST' | translate }}
      </ng-template>
      <app-poi></app-poi>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">call</mat-icon>
        {{ 'MAP.CONTACTS' | translate }}
      </ng-template>
      <app-contacts></app-contacts>
    </mat-tab>

  </mat-tab-group>

</main>

